/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

const INDIVIDUAL_USER_ORG_PREFIX = "_ebx_individual";
const INDIVIDUAL_ORG_TYPE_NAME = 'Individual';

// Organisation id for dev team
export const DEV_ORG = "_ebx_dev_team";

// Organisations for managing individual users
export const INDIVIDUAL_USER_ORG_FULL = `${INDIVIDUAL_USER_ORG_PREFIX}_full`;

export const ORG_EXPORT_COMPLETED_GRACE_PERIOD = 'imageExportCompletedGracePeriod';
export const ORG_WORKFLOW_COMPLETED_GRACE_PERIOD = 'workflowCompletedGracePeriod';
export const ORG_WORKFLOW_STALLED_PERIOD = 'workflowStalledPeriod';

// Map sector keys to display values - don't change the key without migrating values in Firestore
export const ORG_SECTORS = {
    "Environment": "Environment", 
    "Education": "Education",
    "Forestry": "Natural Resource Management",
    "Insurance": "Insurance"
};

// the name of the org type that is used for 'Individual' self registered orgs
export const INDIVIDUAL_ORG_TYPE = INDIVIDUAL_ORG_TYPE_NAME;

// Map org types to display values - don't change the key without migrating values in Firestore
export const ORG_TYPES = {
    "Private Sector - [SME, Startup, Enterprise]": "Private Sector - [SME, Startup, Enterprise]",
    "Federal Government": "Federal Government",
    "Local Government": "Local Government",
    "Charity": "Charity",
    "NGO": "NGO",
    "Individual": INDIVIDUAL_ORG_TYPE
};

export const GEOTIFF_DOWNLOAD_QUOTA = 100;
export const VECTOR_DOWNLOAD_QUOTA = 100;
export const DEFAULT_MAX_EXPLORER_USERS = 10;
export const DEFAULT_MAX_USERS = 1000;

// Acceptable roles for LTI integration
export const LTI_ROLES = [
    "Learner",
    "Instructor",
    "TeachingAssistant"
];

// configuration for password stregth validation
export const OWASP_CONFIG = {
    // this configuration need to match with that used in the google function
    allowPassphrases       : true,
    maxLength              : 128,
    minLength              : 10,
    minPhraseLength        : 20,
    minOptionalTestsToPass : 4,
};

export const STRIPE_PRICE_API =  "https://api.stripe.com/v1/prices";
export const STRIPE_PRODUCT_API = "https://api.stripe.com/v1/products";
export const STRIPE_SESSION_API = "https://api.stripe.com/v1/checkout/sessions";
export const STRIPE_CUSTOMERS_API = "https://api.stripe.com/v1/customers/";
export const STRIPE_SUBSCRIPTIONS_API = "https://api.stripe.com/v1/subscriptions";
export const STRIPE_SUBSCRIPTION_SUB_COLLECTION = 'stripeSubscriptions';
export const STRIPE_ACTIVE_SUB_STATUS = ["active", "trialing"];
export const DEFAULT_ACTIVITY_VALUES = ['Commercial', 'Non-commercial'];
export const STRIPE_PORTAL_KEY = "test_9AQ2bd11vcB14p2144";
export const STRIPE_PORTAL_PREFIX = "https://billing.stripe.com/p/login/";
export const GOOGLE_MARKETPLACE_ACTIVE_ENTITLEMENT_STATES = [
    'ENTITLEMENT_ACTIVE', 
    'ENTITLEMENT_PENDING_CANCELLATION', 
    'ENTITLEMENT_PENDING_PLAN_CHANGE', 
    'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL'];
export const DEFAULT_REGISTRATION_CODES = ['G4G2022', 'GENE2022']; // override by adding to firestore, these are here to aid development only
export const DEFAULT_RECAPTCHA_SITE_KEY = '6LffLvohAAAAAD7E67mjouKGMdfMmyBmosSN8s-0'; // for dev, this is *not* a secret key, override for Prod if desired
// stripe key below is for test mode, this should *never* be a secret key. Override in Firestore for prod with live publishable key
export const DEFAULT_STRIPE_PUBLISHABLE_KEY = 'rk_test_51KDnnZIBPLpRV34fp4hwE4QC9wSoy1RNaUUgBQoWpx6EHAVY4e6mklroPXU7IGOSxiGRjrJiiLIQ7mPyNakckx1d003fuOo4PZ'; 
export const INGEST_IMAGE_OP_TYPE = "INGEST_IMAGE";
export const INGEST_TABLE_OP_TYPE = "INGEST_TABLE";
export const EXPORT_IMAGE_OP_TYPE = "EXPORT_IMAGE";
export const EXPORT_FEATURES_OP_TYPE = "EXPORT_FEATURES";

export const USER_CREATOR_ROLE = "creator";
export const USER_EXPLORER_ROLE = "explorer";
export const EBX_CORE_CONFIG = {
    scale: 100, 
    bestEffort: true, 
    maxPixels: 10000000000
};
export const CONFIG_MAX_PIXELS = 10000000000;
export const APP_VERSION_STRING = 'v0.0.0 Nightly (0eb58d2)';
    
