<template>
    <md-dialog v-model:md-active="showDialog" :class="modalClass">
        <md-dialog-title>
            <div class="top-actions--container">
                Save Project
                <div class="close-button">
                    <md-button class="md-primary" @click.prevent="showDialog = false"><md-icon>close</md-icon></md-button>
                </div>
            </div>
        </md-dialog-title>
        <md-dialog-content>
            <template v-if="!isUpdatingPermissions">
                <md-field>
                    <label for="project-name">Project Name *</label>
                    <md-input name="project-name" id="project-name" v-model="form.projectName" maxlength="100" />
                </md-field>
                <div class="nameErrorMessage" >
                    <p class="ebx-secondary text-negative">{{errorMessage}}</p>
                </div>
                <md-field>
                    <label for="project-description">Description</label>
                    <md-textarea name="project-description" id="project-description" v-model="form.projectDescription" />
                </md-field>
                <template v-if="canShareGlobalWorkflows">
                    <md-field>
                        <label for="project-thumbnail">Thumbnail Image URL</label>
                        <md-input name="project-thumbnail" id="project-thumbnail" v-model="form.projectThumbnail" />
                    </md-field>
                </template>
            </template>
            <div v-if="((projectModeChoiceEnabled || canShareGlobalWorkflows) && !isUpdatingDetails)" class="md-radio-container--condensed" :class="{'md-radio-container--indented--xl': this.isUpdatingPermissions === false}">
                <div>
                    <md-radio v-model="form.projectMode" value="private">Private</md-radio>
                </div>
                <template v-if="projectModeChoiceEnabled">
                    <div>
                        <md-radio v-model="form.projectMode" value="readonly">Others in my organisation can view</md-radio>
                    </div>
                    <div>
                        <md-radio v-model="form.projectMode" value="edit">Others in my organisation can edit</md-radio>
                    </div>
                </template>
                <!-- <template v-if="userVariablesEnabled && projectModeChoiceEnabled">
                    <div>
                        <md-radio v-model="form.projectMode" value="team_published">Published as a template</md-radio>
                    </div>
                </template>
                <template v-if="canShareGlobalWorkflows">
                    <div>
                        <md-radio v-model="form.projectMode" value="template">With everyone as a template</md-radio>
                    </div>
                </template>-->
                <div v-if="organisationGroups.length > 0 && projectIsSharedInTeams">
                    <md-subheader>Share with groups</md-subheader>
                    <md-checkbox v-for="group in organisationGroups" :key="group.id" v-model="form.projectGroups" :value="group.id">
                        {{ group.name }}
                    </md-checkbox>
                </div>
            </div>
            <MoveAssetAlert v-if="projectIsSharedInTeams && assets.length > 0" :assets="assets" :mode="form.projectMode" />

        </md-dialog-content>
        <md-dialog-actions>
            <button :disabled="saving" class="ebx-button ebx-button--secondary" @click.prevent="showDialog = false">
               Close
            </button>
            <button class="ebx-button ebx-button--primary"  :disabled="cannotSave" @click.prevent="handleSaving(form)">
                Save 
                <md-progress-spinner v-if="saving" md-mode="indeterminate" class="static-loading-spinner md-accent" :md-diameter="18" :md-stroke="2" />
            </button>
        </md-dialog-actions>
    </md-dialog>
</template>

<script>

import { FORM_STATE, PROJECT_MODE, PROJECT_STATE, saveWorkflowMixin } from '../mixins/projectMixin.js'
import organisationsMixin from '../mixins/organisationsMixin';
import authMixin from '../mixins/authMixin';
import valueMixin from '../mixins/valueMixin';
import MoveAssetAlert from './MoveAssetAlert.vue';

export default {
    components: {
        MoveAssetAlert
    },
    mixins: [authMixin, organisationsMixin, saveWorkflowMixin, valueMixin],
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        formState: {
            type: String,
            required: true
        },
        projectState: {
            type: String,
            required: true
        },
        defaults: {
            type: Object,
            required: true
        },
        canShareWorkflows: {
            type: Boolean,
            required: true
        },
        canShareGlobalWorkflows: {
            type: Boolean,
            required: true
        },
        assets: { // assets that will be moved to the project
            type: Array,
            required: false,
            default: () => []
        },
    },
    emits: [
        'update:modelValue',
        'save'
    ],
    data() {
        return {
            form: {
                projectName: '',
                projectDescription: '',
                projectMode: PROJECT_MODE.PRIVATE,
                projectThumbnail: null,
                projectGroups: ['all']
            }, 
            errorMessage:""
        }
    },
    computed: {
        modalClass() {
            return {
                'ebx-dialog': true,
                'modal-xl': !this.isUpdatingPermissions,
                'modal-sm': this.isUpdatingPermissions
            }
        },
        isUpdatingDetails() {
            return this.projectState === PROJECT_STATE.DETAIL_UPDATE 
        },
        isUpdatingPermissions() {
            return this.projectState === PROJECT_STATE.PERMISSION_UPDATE
        },
        saving() {
            return this.formState === FORM_STATE.SAVING
        },
        projectModeChoiceEnabled() {
            if (!this.canShareWorkflows) {
                return false
            }
            if(this.isUpdatingPermissions) {
                return true
            }
            return this.isUpdatingDetails === false
        },
        cannotSave() {
            return !this.canSave || this.saving
        },
        canSave() {
            return this.form.projectName && this.form.projectName.length > 0
        },
        showDialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        projectIsSharedInTeams() {
            return [PROJECT_MODE.EDIT, PROJECT_MODE.READONLY].includes(this.form.projectMode)
        }
    },
    methods: {
        async handleSaving() {
            if(this.canSave === false) {
                return false
            }
            let nameUnique = await this.isProjectNameUnique(this.form.projectName, this.defaults.id)
            if(nameUnique === false) {
                this.errorMessage = "A project with this name already exists. Please choose a different name."
            } else {
                this.$emit('save', Object.assign({}, this.form), this.projectState, false, this.saveProjectClearLoadedWorkflowOnSave)
            }
        },
        reset() {
            this.form = {
                projectName: '',
                projectDescription: '',
                projectMode: PROJECT_MODE.PRIVATE,
                projectThumbnail: null,
                projectGroups: []
            }
            this.errorMessage = ""
        }
    },
    watch: {
        showDialog(shown) {
            if(shown) {
                this.reset()
                this.loadOrgGroups()
                if(this.defaults.name) {
                    this.form.projectName = this.defaults.name
                }
                if(this.defaults.description) {
                    this.form.projectDescription = this.defaults.description
                }
                if(this.defaults.mode) {
                    this.form.projectMode = this.defaults.mode
                }
                if(this.defaults.thumbnail) {
                    this.form.projectThumbnail = this.defaults.thumbnail
                }
                if(this.defaults.groups) {
                    this.form.projectGroups = this.defaults.groups
                }
                
            } else {
                if(this.saveProjectClearLoadedWorkflowOnSave) {
                    this.$store.commit('project/clearSavedWorkflow')
                }
            }
            
        }, 
        'form.projectName': function() {
            if (this.form.projectName.length === 0) {
                this.errorMessage = ""
            }
        }
    }
}
</script>
    
