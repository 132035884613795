import SignIn from '../components/SignIn.vue';
import Register from '../components/Register.vue';
import StaticSignIn from '../components/StaticSignIn.vue';
import StyleGuide from '../views/StyleGuide/StyleGuide.vue';
import AccessDenied from '../views/AccessDenied.vue';
import Dash from '../components/OrgDashboard.vue';
import Workflow from '../views/Workflow.vue';
import MyAccount from '../views/MyAccount.vue';
import Projects from '../views/projects/Projects.vue';
import Assets from '../views/projects/Assets.vue';
import Admin from '../views/Admin.vue';
import ProjectDataset from '../views/projects/Dataset.vue';
import ProjectDatasets from '../views/projects/Datasets.vue';
import ProjectAreas from '../views/projects/Areas.vue';
import ProjectExports from '../views/projects/Exports.vue';
import ProjectExplore from '../views/projects/Explore.vue';
import ProjectPackage from '../views/projects/Package.vue';
import ProjectWorkflows from '../views/projects/Workflows.vue';
import ProjectTemplateWorkflows from '../views/projects/TemplateWorkflows.vue';
import ProjectTemplateWorkflow from '../views/projects/TemplateWorkflow.vue';
import UserList from '../components/UserList.vue';
import OrganisationList from '../components/OrganisationList.vue';
import OrganisationFeatures from '../components/OrganisationFeatures.vue';
import OrganisationGroups from '../components/OrganisationGroups.vue';
import ResetPassword from '../components/ResetPassword.vue';
import WorkflowDebugger from '../components/WorkflowDebugger.vue';

// Default Earth Blox Builder Routes.
export const routes = [
    {
        path: '/styleguide',
        name: 'StyleGuide',
        component: StyleGuide,
        meta: {
            requiresAuth: true,
            requiresRoles: ['superadmin'],
            title: 'Earth Blox | Style Guide',
        }
    },
    {
        path: '/accessdenied',
        name: 'AccessDenied',
        component: AccessDenied,
        meta: {
            title: 'Earth Blox | Access Denied',
        }
    },
    {
        path: '/staticsignin',
        name: 'StaticSignIn',
        component: StaticSignIn,
        meta: {
            title: 'Earth Blox | Static Sign In',
        }
    },
    {
        path: '/signin/:welcome?',
        name: 'SignIn',
        component: SignIn,
        props: true,
        meta: {
            title: 'Earth Blox | Sign In',
        }
    },
    {
        path: '/',
        name: 'Home',
        component: SignIn,
        props: true,
        meta: {
            title: 'Earth Blox | Sign In',
        }
    },
    {
        path: '/register/:priceId?',
        name: 'Register',
        component: Register,
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Earth Blox | Register',
        }
    },
    {
        path: '/register/gmp/:accountId/:ebxToken',
        name: 'RegisterGMP',
        component: Register,
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Earth Blox | Register',
        }
    },
    {
        path: '/reset',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: 'Earth Blox | Reset Password',
        }
    },
    {
        path: '/dash/:orgId',
        name: 'dash',
        component: Dash,
        props: true,
        meta: {
            requiresAuth: true,
            title: 'Earth Blox | Organisation Dashboard',
        }
    },
    {
        path: '/workflow/:workflowId?',
        name: 'Workflow',
        component: Workflow,
        props: true,
        meta: {
            requiresAuth: true,
            title: 'Earth Blox | Workspace',
        }
    },
    {
        path: '/myaccount',
        name: 'MyAccount',
        component: MyAccount,
        meta: {
            requiresAuth: true,
            title: 'Earth Blox | My Account',
        },
        children: [
            {
                name: 'MyAccount-Profile',
                path: 'profile',
                component: MyAccount,
                meta: {
                    title: 'Earth Blox | My Profile',
                },
            },
            {
                name: 'MyAccount-Subscription',
                path: 'subscription',
                component: MyAccount,
                meta: {
                    title: 'Earth Blox | My Subscription',
                },
            }
        ]
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
            requiresAuth: true,
            title: 'Earth Blox | My Projects',
        },
        children: [
            {
                name: 'Projects-Workflows',
                path: '',
                component: ProjectWorkflows,
                meta: {
                    title: 'Earth Blox | My Projects',
                    explorerRedirect: true,
                    explorerRedirectPath: '/projects/explore',
                },
            },
            {
                path: 'templates',
                name: 'Templates',
                component: ProjectTemplateWorkflows,
                meta: {
                    title: 'Earth Blox | Template Projects',
                }
            },
            {
                path: 'templates/:workflowId',
                name: 'Template',
                component: ProjectTemplateWorkflow,
                meta: {
                    title: 'Earth Blox | Template Projects',
                }
            },
            {
                path: 'assets',
                name: 'Assets',
                component: Assets,
                meta: {
                    title: 'Earth Blox | My Assets',
                },
                children: [
                    {
                        name: 'Projects-Asset-Features',
                        path: '',
                        component: ProjectAreas,
                        meta: {
                            title: 'Earth Blox | Features',
                        },
                    },
                    {
                        name: 'Projects-Asset-Images',
                        path: 'images',
                        component: ProjectDatasets,
                        meta: {
                            title: 'Earth Blox | Images',
                            requiresAuth: true,
                            requiresRoles: ['creator'],
                        },
                    },
                ]
            },
            {
                name: 'Projects-Exports',
                path: 'exports',
                component: ProjectExports,
                meta: {
                    title: 'Earth Blox | Exports',
                },
            },
            {
                name: 'Projects-Explore',
                path: 'explore',
                component: ProjectExplore,
                meta: {
                    title: 'Earth Blox | Explore',
                },
            },
            {
                name: 'Package',
                path: 'explore/:packageId', 
                component: ProjectPackage,
                meta: {
                    title: 'Earth Blox | Package',
                },
            },
            {
                name: 'Projects-Dataset',
                path: 'dataset/:datasetId',
                component: ProjectDataset,
                meta: {
                    title: 'Earth Blox | Dataset',
                    requiresAuth: true,
                    requiresRoles: ['creator'],
                }
            },
        ]
    },
    {
        path: '/admin',
        name: 'Admin',
        component: Admin,
        meta: {
            requiresAuth: true,
            requiresRoles: ['superadmin','admin','customer_support','group:admin:*'],
            title: 'Earth Blox | Admin Panel',
        },
        children: [
            {
                name: 'Admin-Users',
                path: 'users',
                component: UserList,
                meta: {
                    title: 'Earth Blox | Users',
                },
            },
            {
                name: 'Admin-Features',
                path: 'features',
                component: OrganisationFeatures,
                meta: {
                    title: 'Earth Blox | Features',
                },
            },
            {
                name: 'Admin-Organisations',
                path: 'organisations',
                component: OrganisationList,
                meta: {
                    title: 'Earth Blox | Organisations',
                },
            },
            {
                name: 'Admin-Organisation-Groups',
                path: 'organisations/groups',
                component: OrganisationGroups,
                meta: {
                    title: 'Earth Blox | Organisation Groups',
                },
            },
            {
                name: 'Admin-Debugger',
                path: 'debugger',
                component: WorkflowDebugger,
                meta: {
                    title: 'Earth Blox | Debugger',
                },
            }
        ]
    }
]