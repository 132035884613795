<template>
    <div ref="areaView" class="area-view">
        <ul>
            <li class="area-view--areas-item"
                :class="[area.limitedArea ? 'area-view--areas-item__disabled' : '', isVariable(area.id) ? 'area-view--areas-item__locked' : (selectedArea.id === area.id ? 'area-view--areas-item__selected' : '')]"
                tabindex="0" 
                v-show="areas.length > 0"
                v-for="area in areas" 
                :key="area.id">
                <div class="area-view--areas-item__label" @click="updateSelectedArea(area)">
                    <md-button
                        @click="handleColourChange(area)"
                        :style="{ backgroundColor: area.colour }"
                        class="md-icon-button md-raised colour-selection-button"
                    />
                    <div class="md-list-item-text">
                        <md-field v-if="isRenamingArea(area)" class="md-field-inline">
                            <md-input 
                                :ref="'area_input_'+area.id" 
                                :model-value="area.name" 
                                @update:modelValue="setTempAreaValues(area, $event)" 
                                @keyup.enter.prevent="handleRenameArea(area)"
                                @blur="handleRenameArea(area)"
                                @keydown.escape.prevent
                                @keyup.escape.prevent="cancelRenameArea(area)"
                            />
                        </md-field>
                        <template v-else>
                            {{area.name}}
                        </template>    
                    </div>
                </div>

                <span v-if="isVariable(area.id)" class="material-icons ebx-icon ebx-icon__medium">lock</span>

                <v-menu v-if="!area.limitedArea" class="show-on-hover">
                    <template v-slot:activator="{ props }">
                        <v-btn 
                            icon="mdi-dots-vertical" 
                            variant="text" 
                            density="compact" 
                            v-bind="props"
                            aria-label="Actions menu"
                            @click="isAreaExportable(area)"></v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            prepend-icon="mdi-pencil-outline"
                            v-if="!isVariable(area.id)"
                            @click="renameArea(area)">
                            <v-list-item-title class="text-body-2">Rename</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            prepend-icon="mdi-vector-polyline"
                            v-if="!isVariable(area.id) && !hasUploadedShapes(area.id)"
                            @click="setDrawingModeEnabled(area)">
                            <v-list-item-title class="text-body-2">Edit shapes</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            variant="text"
                            prepend-icon="mdi-magnify"
                            @click="positionArea(area)">
                            <v-list-item-title class="text-body-2">Zoom to area</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            prepend-icon="mdi-tray-arrow-down"
                            v-if="isExportable"
                            @click="exportPolygons(area)">
                            <v-list-item-title class="text-body-2">Download</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            prepend-icon="mdi-delete-outline"
                            v-if="!isVariable(area.id)"
                            @click="removeArea(area)">
                            <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </li>
        </ul>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
import { AreaService }  from '@/services/area.service'; 
import { VariablesService } from '@/services/variable.service';
import { findIndex, without } from 'lodash'
import { MAP_STUDY_AREA_COLLECTION_ID } from '../../constants/nextGenConstants';

export default {
    props: {
        google: {
            type: Object,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        currentlySelectedArea: {
            type: Object,
            required: false,
            default: null
        },
        collectionId: {
            type: String,
            required: true
        },
        areas: {
            type: Array,
            required: true,
        }
    },
    emits: [
        'zoom-to-area',
        'remove-collection',
        'colour-changed'
    ],
    data() {
        return {
            areaIdsRenaming: [],
            areaTempRenameValues: {},
            exportableAreas: [],
            isExportable: false,
            study_collection_id: MAP_STUDY_AREA_COLLECTION_ID,
            editableAreas: [], 
            variableService: VariablesService.getAsReactiveObject()
        };
    },
    computed: {
        selectedArea: {
            get() {
                return this.currentlySelectedArea
            },
            set(newValue) {
                AreaService.setSelectedArea(newValue);
                return newValue
            }
        },
        variableIds() {
            return this.areaVariables();
        }, 
    },
    mounted() {
        this.shapeUpdatedSubscription = AreaService.shapeUpdated$.subscribe(() => {
            this.setEditableAreas(this.areas)
        })
    },
    beforeUnmount() {
        this.shapeUpdatedSubscription.unsubscribe()
    },
    watch: {
        areas: {
            handler: 'setEditableAreas',
            immediate: true, 
            deep: true
        }
    },
    methods: {
        updateSelectedArea(area) {
            this.selectedArea = area
        },
        isRenamingArea(area) {
            return this.areaIdsRenaming.indexOf(area.id) >=0
        },
        isAreaExportable(area) {
            let polygons = AreaService.getShapesForArea(area.id);
            this.isExportable = polygons.filter(polygon => polygon.type === 'UserDrawn').length > 0
        },
        onColourChanged(changedArea) {
            let area = {
                id: changedArea.id,
                colour: changedArea.colour
            }
            AreaService.updateArea(area)   
        },
        exportPolygons(area) {
           this.$store.commit('maplayers/exportPolygonsForAreaId', area.id)
        },
        positionArea(area) {
            this.$emit('zoom-to-area', area)
        },
        renameArea(area) {
            this.areaIdsRenaming.push(area.id)
            const refName = 'area_input_'+area.id
            //small delay for some browser (safari) has issues
            setTimeout(() => { 
                this.$refs[refName][0].$el.focus()
            }, 100)
        },
        removeArea(area){
            let areaIndex = this.areas.indexOf(area);
            const removeArea = this.areas.length === 1;
            
            if(area.id === this.selectedArea.id){
                this.selectedArea = {};
            }
            // get area shapes
            let shapes = AreaService.getShapesForArea(area.id);
            // remove shapes from map
            if (shapes && shapes.length > 0) {
                shapes.forEach(shape => {
                    this.$store.commit('maplayers/removeAssetLayer', shape.id)
                })
            }
            AreaService.removeArea({
                id: area.id,
                colour: area.colour
            });

            if(removeArea) {
                this.selectedArea = {};
                if(this.collectionId === this.study_collection_id) {
                    this.$emit('remove-collection', {id: this.collectionId})
                }
            } else if(areaIndex !== 0) {
                    this.selectedArea = this.areas[areaIndex - 1]
            } else {
                this.selectedArea = this.areas[0]
            }
            
        },
        handleRenameArea(area) {
            const areaIdx = findIndex(this.areas, {id: area.id})
            if(this.areaTempRenameValues[area.id] === undefined) {
                this.areaIdsRenaming = without(this.areaIdsRenaming, area.id)
                return
            }
            const newValue = this.areaTempRenameValues[area.id]
            if(areaIdx !== undefined && newValue.length > 0 && area.name !== newValue) {
                const clonedArea = Object.assign({}, area)
                clonedArea.name = newValue
                AreaService.updateArea(clonedArea)
                this.areaIdsRenaming = without(this.areaIdsRenaming, area.id)
            }
            delete this.areaTempRenameValues[area.id]
        },
        cancelRenameArea(area) {
            this.areaIdsRenaming = without(this.areaIdsRenaming, area.id)
            delete this.areaTempRenameValues[area.id]
        },
        setTempAreaValues(area, value) {
            this.areaTempRenameValues[area.id] = value
        },
        handleColourChange(model) {
            if (model.limitedArea) {
                return
            }
            this.$emit("colour-changed", model)
        },
        setDrawingModeEnabled(area) {
            AreaService.setSelectedArea(area)
            const shapes = AreaService.getShapesForArea(area.id)
            const action = shapes.length > 0 ? 'select' : 'rectangle'
            this.$store.commit('maplayers/setDrawingModeMode', action)
            this.$store.commit('maplayers/setDrawingModeEnabled', true)
        },
        /**
         * Adds whether or not the area is editable to the area objects
         */
        setEditableAreas(areas) {
            let editableAreas = areas.filter(area => AreaService.isAreaEditable(area.id))
            this.editableAreas = editableAreas.map(area => area.id);
        }, 
        /**
         * For each area variable, get its value so we can match it to the area ids
         */
        areaVariables() {
            const areaVariables = this.variableService.variables.filter(variable => variable.type === 'area')
            return areaVariables.map(variable => variable.value);
        },
        isVariable(areaId) { 
            if (this.variableIds.includes(areaId)) {
                return true;
            } else {
                return false;
            }
        },
        hasUploadedShapes(areaId) {
            const uploadedShapes = AreaService
                .getShapesForArea(areaId)
                .filter(area => area.type === 'UserUploaded')
            return uploadedShapes.length > 0
        }
    }
};
</script>