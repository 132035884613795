<template>
    <div ref="StyleguideCards">
        <!-- EBX CARDS -->
                <!-- Asset card -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Asset Card</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.asset_card)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="asset-card--item">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="md-body-2">
                                Asset card title
                            </div>
                        </div>
                        <div class="asset-card--sub-title">
                            <p class="ebx-secondary">asset card sub title</p>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <md-button class="md-icon-button"><md-icon>file_download</md-icon></md-button>
                    </div>
                </div>
            </div>

            <div class="styleguide--component-header">
                <h3>Asset Card with Icon</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.asset_card_icon)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="asset-card--item">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="ebx-icon">
                                <img :src="assets.icons.folderZip" alt="Folder">
                            </div>
                            <div class="md-body-2">
                                Asset card with icon
                            </div>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <md-button class="md-icon-button"><md-icon>file_download</md-icon></md-button>
                    </div>
                </div>
            </div>

            <div class="styleguide--component-header">
                <h3>Asset Card Clickable</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.asset_card_action)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="asset-card--item asset-card--item__action">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <p class="ebx-primary-bold">Card is clickable</p>
                        </div>
                        <div class="asset-card--sub-title">
                            <p class="ebx-secondary">description</p>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact"></v-btn>
                    </div>
                </div>
            </div>

            <div class="styleguide--component-header">
                <h3>Asset Card With Thumbnail</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.asset_card_thumbnail)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="asset-card--item">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="asset-card--thumbnail">
                                <img :src="assets.icons.datasetImagePlaceholder" alt="Thumnail">
                            </div>
                            <div class="ebx-primary-bold">
                                Card with thumbnail
                            </div>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact"></v-btn>
                    </div>
                </div>
            </div>

            <div class="styleguide--component-header">
                <h3>Asset Card With Thumbnail and text</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.asset_card_thumbnail_text)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="asset-card--item">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="asset-card--thumbnail">
                                <img :src="assets.icons.datasetImagePlaceholder" alt="Thumnail">
                            </div>
                            <div class="asset-card-body-text">
                                <div class="asset-card--item-title">
                                    <p class="ebx-primary-bold">Card title</p>
                                </div>
                                <div class="asset-card--sub-title">
                                    <p class="ebx-secondary">description</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact"></v-btn>
                    </div>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Asset Card Disabled</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.asset_card_disabled)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="asset-card--item disabled">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="asset-card--thumbnail">
                                <img :src="assets.icons.datasetImagePlaceholder" alt="Thumnail">
                            </div>
                            <div class="asset-card-body-text">
                                <div class="asset-card--item-title">
                                    <p class="ebx-primary-bold">Card title</p>
                                </div>
                                <div class="asset-card--sub-title">
                                    <p class="ebx-secondary">description</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact"></v-btn>
                    </div>
                </div>

                <div class="asset-card--item disabled">
                    <div class="asset-card--item-body">
                        <div class="asset-card--item-title">
                            <div class="ebx-icon">
                                <img :src="assets.icons.folderZip" alt="Folder">
                            </div>
                            <div class="md-body-2">
                                Asset card with icon
                            </div>
                        </div>
                    </div>
                    <div class="asset-card--item-actions">
                        <md-button class="md-icon-button"><md-icon>file_download</md-icon></md-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- EBX simple card -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Simple Card - blank</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_simple_card_blank)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-template-card ebx-template-card--blank" style="width: 200px">
                    <EbxSimpleCard title="Create new blank project"><template v-slot:image><md-icon>add</md-icon></template></EbxSimpleCard>
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Simple Card - thumbnail</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_simple_card_thumbnail)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-template-card" style="width: 200px">
                    <EbxSimpleCard :imgSrc="assets.icons.datasetImagePlaceholder" :title="'Thumbnail card'" />
                </div>
            </div>
            <div class="styleguide--component-header">
                <h3>Ebx Simple Card - actions</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_simple_card_actions)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <div class="ebx-template-card" style="width: 200px">
                    <EbxSimpleCard 
                        :imgSrc="assets.icons.datasetImagePlaceholder" :title="''"
                        :show-left-action="true"
                        :show-right-action="true"
                        text="Description text"
                        @action-left-click="alert('left action')"
                        @action-right-click="alert('right action')"
                    />
                </div>
            </div>
        </div>

        <!-- EBX package card -->
        <div class="styleguide--component">
            <div class="styleguide--component-header">
                <h3>Ebx Folder Card</h3>
                <button class="ebx-icon-button">
                    <span class="material-icons-outlined ebx-icon" @click="handleComponentCopy(styleguideConstants.ebx_folder_card)">content_copy</span>
                </button>
            </div>
            <div class="styleguide--component-body">
                <EbxFolderCard :style="{'width': 220 + 'px'}"/>
            </div>
        </div>
    </div>
</template>

<script>
import { styleguideConstants } from '@/constants/styleguideConstants.js'

import EbxSimpleCard from '@/components/EbxComponents/EbxSimpleCard.vue'
import EbxFolderCard from '@/components/EbxComponents/EbxFolderCard.vue'
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    name: 'StyleguideTypography',
    mixins: [assetsMixin],
    components: {
        EbxSimpleCard,
        EbxFolderCard,
    },
    data() {
        return {
            styleguideConstants: styleguideConstants,
        }
    },
    methods: {
        handleComponentCopy(value) {
            navigator.clipboard.writeText(value);
        },
    }
}
</script>