/**
 * Defines a class and helper methods for diplaying a custom vue component on a google maps api map.
 * https://developers.google.com/maps/documentation/javascript/examples/overlay-popup#maps_overlay_popup-javascript
 * Follows a similar pattern to the FieldModal class found in src/fields/FieldModal.js
 */

import {createApp, reactive, h} from "vue";
import store from '@/store/index';
import {VueMaterialPlugin} from "@/vue3-material/material";
import { VuetifyPlugin } from '@/vuetify/vuetifyPlugin';

/**
 * Define a popup class, extending the provided google.maps.OverlayView class.
 * Content will be a vue component reference.
 * @param {*} google 
 * @param {*} position 
 * @param {*} component
 * @param {*} props
 * @param {*} preventMapHits
 * @param {*} preventGestures
 */
export function popupFactory(google, position, component, props, preventMapHits=true, preventGestures=true) {

    /**
     * A customized popup on the map.
     * https://developers.google.com/maps/documentation/javascript/reference/overlay-view#OverlayView
    */
    class Popup extends google.maps.OverlayView {
        position;
        containerDiv;

        /**
         * Component to use for the popup.
         * @type {Vue}
         * @private
         */
        popupComponent_ = null;

        /**
         * Describes a vue class for the popup, extends Vue.Component.
         * @type {Vue.Component}
         * @private
         */
        ComponentClass_ = null;

        /**
         * The props to supply to the popup component.
         * @type {Object}
         * @private
         */
        propsData_ = null;

        /**
         * The instance of the popup component.
         * @type {Vue.Component}
         * @private
         */
        instance_ = null;

        /**
         * Determines whether the component is rendered.
         * @type {Boolean}
         */
        showPopup_ = false;

        /**
         * 
         * @param {*} position 
         * @param {*} content 
         */
        constructor(position, popupComponent, propsData, preventMapHits, preventGestures) {
            super();

            this.position = position;
            this.popupComponent_ = popupComponent;
            this.ComponentClass_ = this.popupComponent_
            this.propsData_ = reactive(propsData);

            this.instance_ = createApp({
                render: () => this.vm = h(this.ComponentClass_, this.propsData_)
            });
            this.instance_.use(store);
            this.instance_.use(VueMaterialPlugin);
            this.instance_.use(VuetifyPlugin);

            this.containerDiv = document.createElement("div");
            this.containerDiv.classList.add("ebx-map-popup-container");
            // must be set otherwise positioning completed in draw function won't make effect
            this.containerDiv.style.position = "absolute"; 
            this.instance_.mount(this.containerDiv);
            // Optionally stop clicks, etc., from bubbling up to the map.

            if (preventMapHits && preventGestures) {
                Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
            } else if (preventMapHits) {
                Popup.preventMapHitsFrom(this.containerDiv);
            } else if (preventGestures) {
                console.warn("Ignoring preventGestures = true, cannot prevent gestures without preventing map hits.");
            }
        }

        /** Called when the popup is added to the map. */
        onAdd() {
            this.getPanes().floatPane.appendChild(this.containerDiv);
        }

        disposeInstance() {
            this.instance_.unmount();
            if(this.containerDiv) {
                if(this.containerDiv.parentElement) {
                    this.containerDiv.parentElement.removeChild(this.containerDiv);
                }
            }
            this.instance_ = null;
            this.modalComponent_ = null;
        }

        /** Called when the popup is removed from the map. */
        onRemove() {
            if (!this.containerDiv.parentElement) {
                return
            }

            this.containerDiv.parentElement.removeChild(this.containerDiv);
            this.disposeInstance();
        }
        
        /** Called each frame when the popup needs to draw itself. */
        draw() {
            const divPosition = this.getProjection().fromLatLngToDivPixel(
                this.position,
            );
            // Hide the popup when it is far out of view.
            const display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
                    ? "block"
                    : "none";

            if (display === "block") {
                this.containerDiv.style.left = divPosition.x + "px";
                this.containerDiv.style.top = divPosition.y + "px";
            }

            if (this.containerDiv.style.display !== display) {
                this.containerDiv.style.display = display;
            }
        }

        /**
         * Set props of the popup component.
         * @param {Object} propsData
         */
        setProps(propsData) {
            Object.keys(propsData).forEach(key => {
                this.propsData_[key] = propsData[key];
            })
        }

        addClassToElement(className) {
            this.instance_.$el.classList.add(className);
        }

        addStylesToElement(styles) {
            Object.keys(styles).forEach(key => {
                this.instance_.$el.style[key] = styles[key];
            });
        }

        getElementPosition() {
            if(this.getProjection()) {
                return this.getProjection().fromLatLngToDivPixel(this.position);
            }
            return null
        }
    }

    return new Popup(position, component, props, preventMapHits, preventGestures);
}