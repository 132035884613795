<template>
    <div ref="addArea" class="add-area">
        <v-menu class="add-area--container" location="right">
            <template v-slot:activator="{ props }">
                <v-btn prepend-icon="mdi-plus" class="add-area--action" variant="text" v-bind="props">
                    Add area
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    prepend-icon="mdi-pencil-outline"
                    @click="$emit('add-new-area', null, collectionId, true)">
                    <v-list-item-title class="text-body-2">Draw area</v-list-item-title>
                </v-list-item>
                <v-list-item
                    prepend-icon="mdi-tray-arrow-up"
                    @click="$emit('upload-polygon-to-collection', collectionId, true)">
                    <v-list-item-title class="text-body-2">Upload new feature</v-list-item-title>
                </v-list-item>
                <v-list-item
                    prepend-icon="mdi-folder-outline"
                    @click="$emit('choose-area', collectionId)">
                    <v-list-item-title class="text-body-2">Choose from Assets</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 * 
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 * 
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/

export default {
    props: {
        collectionId: {
            type: String,
            required: true
        }
    },
    emits: [
        'add-new-area',
        'upload-polygon-to-collection',
        'choose-area'
    ]
};
</script>