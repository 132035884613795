
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import {AbstractBlock, AbstractFieldHelpers} from '@/blocks/_abstractBlock';
import {FieldNoTrimDropdown} from '@/fields/FieldNoTrimDropdown';
import { addModalInput, singleChannelModalData_, multiChannelModalData_ } from '@/blocks/mixins/visualisationModal';
import MultiChannelMapLayer from '@/modals/MultiChannelMapLayer.vue';
import {VIS_BANDS_R, VIS_BANDS_G, VIS_BANDS_B, VIS_BANDS_VALUE} from '@/constants/ebxStacConstants';
import { ContentService } from '@/services/content.service';
import { DEFAULT_LAYER_NAME, UPDATE_DEFAULT_LAYER_NAME, NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import { v2ContrastVisToValue } from './helper_functions'

var MultitemporalJson ={
    "type": "output_multitemporal",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11 %12 %13 %14 %15 %16 %17",
    "args0": [
        {
            "type": "field_label",
            "name": "multi_title",
            "text": "%{BKY_OUTPUT_MULTITEMPORAL_BLOCK_TITLE}",
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label",
            "name": "name_label",
            "text": "%{BKY_OUTPUT_MULTITEMPORAL_NAME_LABEL}",
        },
        {
            "type": "field_input",
            "name": "output_name",
            "text": DEFAULT_LAYER_NAME,
        },
        {
            "type": "input_dummy",
        },
        {
            "type": "field_label",
            "name": "band_label",
            "text": "%{BKY_OUTPUT_MULTITEMPORAL_BAND_LABEL}",
        },
        {
            "type": "field_notrim_dropdown",
            "name": "bands",
            "options": [["Select a band", NO_DATA_DROPDOWN_VALUE]],
        },
        {
            "type": "input_dummy",
            "name":"singleBand"
        },
        {
            "type": "field_label",
            "name": "R_label",
            "text": "%{BKY_OUTPUT_MULTITEMPORAL_R_LABEL}",
        },
        {
            "type": "field_notrim_dropdown",
            "name": "R",
            "options": [["None", 'none']],
        },
        {
            "type": "input_dummy",
            "name": "multiBand_r"
        },
        {
            "type": "field_label",
            "name": "G_label",
            "text": "%{BKY_OUTPUT_MULTITEMPORAL_G_LABEL}",
        },
        {
            "type": "field_notrim_dropdown",
            "name": "G",
            "options": [["None", 'none']],
        },
        {
            "type": "input_dummy",
            "name": "multiBand_g"
        },
        {
            "type": "field_label",
            "name": "B_label",
            "text": "%{BKY_OUTPUT_MULTITEMPORAL_B_LABEL}",
        },
        {
            "type": "field_notrim_dropdown",
            "name": "B",
            "options": [["None", 'none']],
        },
        {
            "type": "input_dummy",
            "name": "multiBand_b"
        },
        
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "mapLayer",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['output_multitemporal'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    addModalInput,
    singleChannelModalData_,
    multiChannelModalData_,
    onInit: function() {
        this.jsonInit(MultitemporalJson)
        this.singleChannelModalData = Object.assign({}, this.singleChannelModalData_)
        this.multiChannelModalData = Object.assign({}, this.multiChannelModalData_)
        // Fix ordering on intial state loading
        this.setState('dates', this.getField('R').getOptions())
        this.setState('bands', this.getField('bands').getOptions())

        this.about_block_url = ContentService.getAboutBlockUrl('output_multitemporal')
        this.getField('output_name').setValidator(this.layerNameValidator)
    },
    accept: async function (visitor) {
        console.log('accept output_multitemporal visitor')
        await visitor.visitOutputMultitemporalBlock(this);
    },
    ebxValidate: function(tooltips) {
        const dates = this.getState('dates')
        if(dates.length === 1 && dates[0][1] === 'none') {
            return this.setWarningText(tooltips['multi_temporal_no_date_range'] || 'To use this block, use a date range in the "Aggregate images in time" block in this workflow', 'multi_temporal_no_date_range')
        }
        return this.setWarningText(null,'multi_temporal_no_date_range')
    },
    updateShape_: function() {
        let datesArray = this.getState('dates')
        let bandsArray = this.getState('bands')
        if(datesArray.length === 0) {
            datesArray = [["None", 'none']]
        }
        if(bandsArray.length === 0) {
            bandsArray = [["None", 'none']]
        }
        let oldvalues = {
            r: this.getFieldValue('R'),
            g: this.getFieldValue('G'),
            b: this.getFieldValue('B')         
        }
        
        const contrast = this.getState('visContrast', {})
        const contrastType = v2ContrastVisToValue(contrast)

        if(this.hasStateChanged('min_max') && this.isLoadingWorkflow() === false) {
            
            if ( this.hasState('updateViz') === false){
                const minMax = this.getState('min_max')
    
                this.multiChannelModalData.minR = minMax.rgb ? minMax.rgb[0][0] : minMax.min ? minMax.min : 0
                this.multiChannelModalData.maxR = minMax.rgb ? minMax.rgb[0][1] : minMax.max ? minMax.max : 1
                this.multiChannelModalData.minG = minMax.rgb ? minMax.rgb[1][0] : minMax.min ? minMax.min : 0
                this.multiChannelModalData.maxG = minMax.rgb ? minMax.rgb[1][1] : minMax.max ? minMax.max : 1
                this.multiChannelModalData.minB = minMax.rgb ? minMax.rgb[2][0] : minMax.min ? minMax.min : 0
                this.multiChannelModalData.maxB = minMax.rgb ? minMax.rgb[2][1] : minMax.max ? minMax.max : 1
                this.multiChannelModalData.contrastValue = contrastType;
    
                if(this.inputExists('modal_input')) {
                    const modalValue = this.getFieldValue('modal_field')
                    if(modalValue) {
                        modalValue.minR = this.multiChannelModalData.minR
                        modalValue.maxR = this.multiChannelModalData.maxR
                        modalValue.minG = this.multiChannelModalData.minG
                        modalValue.maxG = this.multiChannelModalData.maxG
                        modalValue.minB = this.multiChannelModalData.minB
                        modalValue.maxB = this.multiChannelModalData.maxB
                        modalValue.contrastValue = this.multiChannelModalData.contrastValue
                        this.setFieldValue(modalValue, 'modal_field')
                    }
                }
            }
            
            if(this.hasState('updateViz') && this.isLoadingWorkflow() === false) {
                this.removeState('updateViz')
            }
        }

        if(this.hasStateChanged('viz')) {
            const viz = this.getState('viz', {})
            if(viz.display_name) {
                this.setLayerNameValue(viz.display_name)
            }
        }

        if(this.hasStateChanged('dates')) {
            this.updateOptionsIfSet('R',datesArray)
            this.updateOptionsIfSet('G',datesArray)
            this.updateOptionsIfSet('B',datesArray)
        }

        if(this.hasStateChanged('bands')) {
            
            const vizBands = this.getState('visBands', {}) || {}
                        
            let red, green, blue
            if (vizBands && Object.hasOwn(vizBands,VIS_BANDS_R)) {
                red = vizBands[VIS_BANDS_R]
                green = vizBands[VIS_BANDS_G]
                blue = vizBands[VIS_BANDS_B]
                console.log(`Using vis bands ${red} ${green} ${blue} for visualization`)
            } else if (Object.hasOwn(vizBands, VIS_BANDS_VALUE)) {
                const bandName = vizBands[VIS_BANDS_VALUE]
                red = bandName
                green = bandName
                blue = bandName
                console.log(`Using single band ${bandName} for visualization`)
            } else {
                red = oldvalues.r
                green = oldvalues.g
                blue = oldvalues.b
                console.log(`Using old rgb values ${red} ${green} ${blue} for visualization`)
            }
            console.log(`Using RGB ${red} ${green} ${blue}`)
            
            if(bandsArray.length > 1) {
                                
                if(this.fieldExists('R_band') === false){

                    this.removeInputIfExists('modal_input')
                    this.removeInputIfExists('singleBand')
                    this.removeInputIfExists('multiBand_r')
                    this.removeInputIfExists('multiBand_g')
                    this.removeInputIfExists('multiBand_b')

                    let input = {
                        r: this.appendDummyInput('multiBand_r'),
                        g: this.appendDummyInput('multiBand_g'),
                        b: this.appendDummyInput('multiBand_b'),
                    }

                    input.r.appendField('R','R_label')
                    input.g.appendField('G','G_label')
                    input.b.appendField('B','B_label')

                    input.r.appendField(this.createDropdownWithValue(bandsArray, red),'R_band')
                    input.g.appendField(this.createDropdownWithValue(bandsArray, green),'G_band')
                    input.b.appendField(this.createDropdownWithValue(bandsArray, blue),'B_band')

                    input.r.appendField(this.createDropdownWithValue(datesArray, oldvalues.r),'R')
                    input.g.appendField(this.createDropdownWithValue(datesArray, oldvalues.g),'G')
                    input.b.appendField(this.createDropdownWithValue(datesArray, oldvalues.b),'B')

                    // Set the selections after the field has been appended
                    this.getField('R_band').setValue(red)
                    this.getField('G_band').setValue(green)
                    this.getField('B_band').setValue(blue)
                    
                    this.addModalInput(this, MultiChannelMapLayer, this.multiChannelModalData, 'modal_input')

                } else {
                    this.updateOptionsIfSet('R_band',bandsArray)
                    this.updateOptionsIfSet('G_band',bandsArray)
                    this.updateOptionsIfSet('B_band',bandsArray)
                    this.updateOptionsIfSet('bands',bandsArray)
                }
            } else{
                if(this.inputExists('singleBand') === false) {

                    this.removeInputIfExists('modal_input')
                    this.removeInputIfExists('multiBand_r')
                    this.removeInputIfExists('multiBand_g')
                    this.removeInputIfExists('multiBand_b')

                    let bandInput = this.appendDummyInput('singleBand')
                    bandInput.appendField('Use band','R_label')
                    bandInput.appendField(new FieldNoTrimDropdown(bandsArray),'bands')

                    let input = {
                        r: this.appendDummyInput('multiBand_r'),
                        g: this.appendDummyInput('multiBand_g'),
                        b: this.appendDummyInput('multiBand_b'),
                    }

                    input.r.appendField('R','R_label')
                    input.g.appendField('G','G_label')
                    input.b.appendField('B','B_label')

                    input.r.appendField(this.createDropdownWithValue(bandsArray, red),'R')
                    input.g.appendField(this.createDropdownWithValue(bandsArray, green),'G')
                    input.b.appendField(this.createDropdownWithValue(bandsArray, blue),'B')
                    
                    // Set the selections after the field has been appended
                    this.getField('R').setValue(red)
                    this.getField('G').setValue(green)
                    this.getField('B').setValue(blue)
                    
                    //this.addModalInput(this, SingleChannelMapLayer, this.singleChannelModalData, 'modal_input')
                    this.addModalInput(this, MultiChannelMapLayer, this.multiChannelModalData, 'modal_input')
                    //
                }else {
                    this.removeInputIfExists('multiBand_r')
                    this.removeInputIfExists('multiBand_g')
                    this.removeInputIfExists('multiBand_b')
                    this.updateOptionsIfSet('bands',bandsArray)
                }
            }
        }
    },
    onLoadExtraState(state) {
        if(Object.keys(state).length > 0 && state.userChangedLayerName === undefined && UPDATE_DEFAULT_LAYER_NAME) {
            if(this.isLoadingWorkflow()) {
                this.setState('userChangedLayerName', true)
                this.setNonPersistentState('autoChangeLayerName', false)
            } else {
                this.setNonPersistentState('autoChangeLayerName', true)
            }
        }
        if(this.isLoadingWorkflow()) {
            this.setNonPersistentState('updateViz', true)
        }
        if(typeof state.visContrast === 'object' && state.visContrast !== null) {
            state.visContrast = v2ContrastVisToValue(state.visContrast)
        }
        return state
    },
    layerNameValidator(value) {
        if(UPDATE_DEFAULT_LAYER_NAME === false) {
            return
        }
        const block = this.getSourceBlock()
        if(block.isLoadingState() === false && block.hasState('autoChangeLayerName') === false && block.getFieldValue('output_name') !== value) {
            block.setState('userChangedLayerName', true)
        }
        block.removeState('autoChangeLayerName')
        return value
    },
    setLayerNameValue(layerName, forceChange = false) {
        if(UPDATE_DEFAULT_LAYER_NAME === false) {
            return
        }
        if(this.hasState('autoChangeLayerName') && forceChange === false) {
            return
        }
        if(this.hasState('userChangedLayerName') === false || forceChange === true) {
            this.setNonPersistentState('autoChangeLayerName', true)
            this.setFieldValue(layerName, 'output_name')
            this.removeState('autoChangeLayerName')
        }
    }
}; 