import { Definition } from './definition' 
export class WorkflowState {
    constructor(registry, id) {
        this.registry = registry
        this.id = id
        this.state = {
            definitions: {}
        }
        this.abilities = []
        this.definitionClasses_  = {}
        this.currentDefinitionStack = []
        this.delayedVisits = {}
        this.delayedVisitCallers = {}
    }

    getDefinitionKey() {
        return 'definitions'
    }

    setCurrentDefinitionId(definitionId) {
        this.currentDefinitionStack.push(definitionId)
        return this
    }

    delayVisitorExecution(blockId, visitorNames) {
        if(Array.isArray(visitorNames)=== false) {
            visitorNames = [visitorNames]
        }
        if (this.delayedVisits[this.registry.getCurrentBlockId()] == undefined) {
            this.delayedVisits[this.registry.getCurrentBlockId()] = {}
        }
        this.delayedVisits[this.registry.getCurrentBlockId()] = {
            blockId,
            visitorNames
        }
        if(this.delayedVisitCallers[blockId] == undefined) {
            this.delayedVisitCallers[blockId] = []
        }
        this.delayedVisitCallers[blockId].push(this.registry.getCurrentBlockId())
        return this
    }

    isVisitorDelayed(blockId, visitorName) {
        if (this.delayedVisits[blockId] == undefined) {
            return false
        }
        return this.delayedVisits[blockId] != undefined && this.delayedVisits[blockId].visitorNames.indexOf(visitorName) >= 0
    }
    getDelayedVisitorBlockIds(blockId, visitorName) {
        if(this.delayedVisitCallers[blockId]) {
            return this.delayedVisitCallers[blockId].filter(toCallBlockId => {
                return this.delayedVisits[toCallBlockId].visitorNames.indexOf(visitorName) >= 0
            })
        }
        return []
    }

    // shareAbilityPrepend(abilityConstructor, initialData = null, onlyForDefinitionId = null) {
    //     return this.shareAbility(abilityConstructor, initialData, onlyForDefinitionId, true);
    // }
    shareAbility(abilityConstructor, initialData = null, onlyForDefinitionId = null, prepend = false) {
        const ability = new abilityConstructor(this)
        if(onlyForDefinitionId !== null) {
            ability.setDefinitionIndexes([onlyForDefinitionId]).setCurrentDefinition(onlyForDefinitionId)
        }
        if(initialData !== null) {
            ability.commit(initialData)
        }
        if(prepend) {
            this.abilities.unshift(ability)
        } else {
            this.abilities.push(ability)
        }
        if(typeof ability.createDefinition === 'function') {
            this.definitionClasses_[ability.currentDefinitionKey] =  ability.createDefinition(this) 
        }
        return ability
    }

    gatherAbilitiesForDefinitions(abilityConstructors, definitionIds, includeSubscriptions = false, ignoreBlockPositioning = false) {
        // Get an array Of Abliities
        const ablitites = abilityConstructors.map(abilityConstructor => {
            return this.gatherAbilitiesForDefinition(abilityConstructor, definitionIds, includeSubscriptions, ignoreBlockPositioning).toArray()
        })

        // Merge the array of abilities into a single array
        const mergedAbilities = [].concat.apply([], ablitites)

        // create an array of indexs and positions
        const indexes = mergedAbilities.map((ability, index) => {
            return {
                index,
                position: this.registry.previousTraversedBlocks.indexOf(ability.associatedBlockId)
            }
        })

        // order the abilities by the position
        indexes.sort((a, b) => {
            return a.position - b.position
        })

        // return the ordered abilities
        return indexes.map(index => mergedAbilities[index.index])

    }

    gatherAbilitiesForDefinition(abilityConstructor, definitionId, includeSubscriptions = false, ignoreBlockPositioning = false) {
        const filteredAbilities = this
            .gatherAbilities(abilityConstructor, includeSubscriptions, ignoreBlockPositioning, definitionId || 'default')
            .filter(ability => ability.hasDefinition(definitionId))
        if(typeof abilityConstructor.newCollection === 'function') {
            return abilityConstructor.newCollection(filteredAbilities)
        }   
        return filteredAbilities
    }
    gatherAbilities(abilityConstructor, includeSubscriptions = false, ignoreBlockPositioning = false, definitionId = 'default') {
        let abilities = this.abilities
            .filter(ability => {
                return (
                    ability instanceof abilityConstructor && 
                    ability.hasDefinition(definitionId) && 
                    (ignoreBlockPositioning === true || ability.createdBeforeCurrentBlockId())
                )
            })
            .map(ability => ability.setCurrentDefinition(definitionId))
    
        if(includeSubscriptions) {
            this.registry.getSubscriptionsObject(this.id).forEach(({workflowId, definitionIds}) => {
                definitionIds.forEach(definitionId => {
                    const workflow = this.registry.getWorkflowForId(workflowId)
                    if(workflow){
                        abilities = abilities.concat(
                            workflow
                                .gatherAbilities(abilityConstructor, true, false, definitionId || 'default')
                                .filter(ability => ability.hasDefinition(definitionId || 'default') && (ignoreBlockPositioning === true || ability.createdBeforeCurrentBlockId()))
                                .map(ability => ability.setCurrentDefinition(definitionId || 'default'))
                        )
                    }
                })
            })
        }
        
        const filteredAbilities = abilities
            .filter(a => {
                if(a.getState() === undefined) {
                    console.debug('Undefined State Found', a.workflowState.getDefinitionKey(),a.currentDefinitionKey, a.stateKey, a)
                    return false
                }
                return true
            })

        if(typeof abilityConstructor.newCollection === 'function') {
            return abilityConstructor.newCollection(filteredAbilities)
        }    
        return filteredAbilities
    }

    gatherFirstAbility(abilityConstructor, includeSubscriptions = false, ignoreBlockPositioning = false) {
        const abilities = this.gatherAbilities(abilityConstructor, includeSubscriptions, ignoreBlockPositioning)
        return abilities.first()
    }
    
    gatherLastAbility(abilityConstructor, includeSubscriptions = false, ignoreBlockPositioning = false) {
        const abilities = this.gatherAbilities(abilityConstructor, includeSubscriptions, ignoreBlockPositioning)
        return abilities.last()
    }

    gatherFirstAbilityForDefinition(abilityConstructor,definitionId, includeSubscriptions = false, ignoreBlockPositioning = false) {
        const abilities = this.gatherAbilitiesForDefinition(abilityConstructor,definitionId, includeSubscriptions, ignoreBlockPositioning)
        return abilities.first()
    }
    
    gatherLastAbilityForDefinition(abilityConstructor,definitionId, includeSubscriptions = false, ignoreBlockPositioning = false) {
        const abilities = this.gatherAbilitiesForDefinition(abilityConstructor,definitionId,includeSubscriptions, ignoreBlockPositioning)
        return abilities.first()
    }

    createSavedDefinition(id, name, from = 'default', updatePreviousAbilites = true) {
        this.state.definitions[id] = Object.assign({},  this.state.definitions[from])
        this.state.definitions[id].user_defined_name = name
        if(updatePreviousAbilites) {
            this.abilities.forEach(ability => ability.appendDefinitionIndex(id))
        }
        this.definitionClasses_[id] = new Definition(this, id, name)
        return this
    }

    getDefinition(id) {
        return this.definitionClasses_[id] || undefined
    }

}