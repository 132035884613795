import Blockly from "blockly";
import {ProvidesMapArea} from "./abilities/providesMapArea";
import {AreaService } from '../services/area.service'
import { uniq } from 'lodash'
import {ProvidesDataset} from "./abilities/providesDataset";
import {SAVED_WORKFLOW_ID_SEPERATOR} from "../constants/nextGenConstants";

export class WorkflowRegistry {
    constructor() {
        this.workflows = {}
        this.subscriptions = {}
        this.workflowOrderSnapshot = []
        this.runState = null
        this.traversedBlocks = []
        this.previousTraversedBlocks = []
        this.isDragging = false
        this.currentEvent = null
        this.globalDatsetService = null
    }
    createState(id, stateConstructor) {
        this.workflows[id] = new stateConstructor(this, id)
        return this
    }
    appendCurrentBlockId(blockId) {
        this.traversedBlocks.push(blockId)
        return this
    }
    resetTraversedBlocks() {
        this.previousTraversedBlocks = this.traversedBlocks.slice(0)
        this.traversedBlocks = []
        return this
    }
    getCurrentBlockId() {
        return this.traversedBlocks[this.traversedBlocks.length -1]
    }
    hasProcessedBlockId(blockId) {
        return this.traversedBlocks.indexOf(blockId) >= 0
    }
    getWorkflowForId(id) {
        const subscriberParts = id.split(SAVED_WORKFLOW_ID_SEPERATOR)
        return this.workflows[subscriberParts[0]]
    }
    keys() {
        return Object.keys(this.workflows)
    }
    /**
     * 
     * @param {*} subscriberId the blockId that you want to subscribe
     * @param {*} subscribeToId the blockId that subscriberId is dependant on
     * @param {*} hidden hidden will only make the subscription available to ordering the topblocks and not reutrn abilities on gatherAbilites
     * @returns 
     */
    subscribe(subscriberId, subscribeToId, hidden = false) {

        if([null,undefined].indexOf(subscribeToId) >= 0) {
            console.warn(`Not subscribing ${subscriberId} as subscribeToId is null`)
            return this
        }

        // its not required to subscibe to self
        if([null,undefined].indexOf(subscribeToId) >= 0) {
            console.warn(`Not subscribing ${subscriberId} as subscribeToId is null`)
            return this
        }

        const subscriberParts = subscribeToId.split(SAVED_WORKFLOW_ID_SEPERATOR)
        
        if(subscriberId === subscriberParts[0]) {
            console.warn(`Not subscribing ${subscriberId} to ${subscribeToId} as workflow is the same`)
            return this
        }

        if(subscriberId === null) {
            console.warn(`Not subscribing subscriberId is null`)
            return this
        }
        if(subscribeToId === null) {
            console.warn(`Not subscribing subscribeToId is null`)
            return this
        }

        if (this.subscriptions[subscriberId] === undefined) {
            this.subscriptions[subscriberId] = []
        }

        const workflowIndex = this.subscriptions[subscriberId].findIndex(w => w.workflowId === subscriberParts[0])
        const defIdToAdd = subscriberParts[1] || 'default'

        if(workflowIndex >= 0) {
            if(this.subscriptions[subscriberId][workflowIndex].definitionIds.indexOf(defIdToAdd) < 0) {
                this.subscriptions[subscriberId][workflowIndex].definitionIds.push(subscriberParts[1] || 'default')
            }
        } else {
            this.subscriptions[subscriberId].push({ 
                workflowId: subscriberParts[0], 
                definitionIds: [defIdToAdd],
                hidden
            })
        }

        return this
    }

    subscribeHidden(subscriberId, subscribeToId) {
        return this.subscribe(subscriberId, subscribeToId, true)
    }

    getSubscriptions(subscriberId, justKeys = true, includeHidden = false) {
        if(this.subscriptions[subscriberId]) {
            const filtedSubscriptions = this.subscriptions[subscriberId].filter(sub => {
                return includeHidden === true || sub.hidden === false
            })
            if(justKeys){
                return uniq(filtedSubscriptions.map(sub => sub.workflowId))
            }
            return filtedSubscriptions
        }
        return []
    }

    getSubscriptionsObject(subscriberId, includeHidden = false) {
        return this.getSubscriptions(subscriberId, false, includeHidden)
    }

    getLastSortSnapshot() {
        if (this.workflowOrderSnapshot.length === 0) {
            throw new Error('Must have at least one block sorted to call snapshot')
        }
        return this.workflowOrderSnapshot
    }

    setRunState(runState) {
        this.runState = runState
        return this;
    }
    getIsBlockLoading(blockId) {
        if(this.runState !== null && this.runState.isLoading === true) {
            return this.runState.blocks.filter(blockDetail => blockDetail.id === blockId).length === 1
        }
        return false
    }
    setDragging(dragging) {
        this.isDragging = dragging
        return this
    }
    setCurrentEvent(event) {
        this.currentEvent = event
        return this
    }
    clear() {
        this.clearWorkflows()
        this.clearSubscriptions()
        return this
    }
    clearWorkflows() {
        this.workflows = {}
        this.workflowOrderSnapshot = []
        return this
    }
    clearSubscriptions() {
        this.subscriptions = {}
        return this
    }
    getDependantBlockIdsForBlockId(blockId) {
        let dependants = [blockId]
        if(this.subscriptions[blockId] !== undefined) {
            this.subscriptions[blockId].forEach(w => {
                dependants.push(w.workflowId)
                dependants = dependants.concat(this.getDependantBlockIdsForBlockId(w.workflowId))
            })
        }
        return dependants
    }
    getRunableBlocks(limitTopBlocksIds = null) {
        limitTopBlocksIds = limitTopBlocksIds || null
        let allowBlocks = []
        if(limitTopBlocksIds !== null) {
            allowBlocks = limitTopBlocksIds.flatMap(b => this.getDependantBlockIdsForBlockId(b)) 
            console.log('Runnable Blocks', allowBlocks)
        }
        return this.workflowOrderSnapshot
            .filter(blockId => {
                if(limitTopBlocksIds === null) {
                    return true
                }
                return allowBlocks.indexOf(blockId) >= 0;
            })
    }

    serializeWorkspace(workspace, limitTopBlocksIds = null) {
        // Re-sort blocks to get the latest version of the block snapshot
        this.topologicalSortTopBlocks(workspace.getTopBlocks(true))
        const topBlocks = this.getRunableBlocks(limitTopBlocksIds)
        const blocks = topBlocks.map(blockId => {
            return Blockly.serialization.blocks.save(workspace.getBlockById(blockId))
        }) 
        
        const studyAreaIds = AreaService.getStudyAreas().map(a => a[1]);

        const workflowAreaIds = uniq(this
            .gatherAbilities(ProvidesMapArea)
            .map(ability => ability.getAreaIds())
            .flat())

        // add all study area's as default if no study area has been provided    
        if(studyAreaIds.length > 0) {
            studyAreaIds.forEach(id => {
                if(workflowAreaIds.indexOf(id) < 0) {
                    workflowAreaIds.push(id)
                }
            })
        }

        const mapAreas = AreaService.getAreasAndShapesForRunDoc(workflowAreaIds);

        return {
            blocks,
            mapAreas
        }    
    }

    topologicalSortTopBlocks(topBlocks) {
        const nodes = {}
        var sorted_list = []
        // a list containing the nodes that have no incoming edge
        var no_edge_nodes = []
        var topBlocksIdMap = {}

        topBlocks.forEach(block => {
            topBlocksIdMap[block.id] = block
            if (this.subscriptions[block.id] === undefined) {
                return nodes[block.id] = []
            }
            return nodes[block.id] = this.subscriptions[block.id].map(s => s.workflowId)
        })
        var keys = Object.keys(nodes)

        var incoming_edge_matrix = keys.map(key => {
            return keys.map(edge => {
                if (nodes[key].includes(edge)) {
                    return 1
                }
                return 0
            })
        })

        // populate list
        incoming_edge_matrix.forEach((edges, node) => {
            if (!edges.includes(1)) {
                no_edge_nodes.push(node)
            }
        })

        console.log("no_edge_nodes", no_edge_nodes)

        while (no_edge_nodes.length > 0) {
            // remove no edge nodes and put in sorted list
            let n = no_edge_nodes.shift();
            sorted_list.push(n)

            // check if other nodes have an incoming edge from this node
            incoming_edge_matrix.forEach((edges, node) => {
                // if they do remove the edge from the graph
                if (edges[n] == 1) {
                    edges[n] = 0;
                    // if this node no longer has any edges add it to the no edge list
                    if (!edges.includes(1)) {
                        no_edge_nodes.push(node)
                    }
                }
            })
        }

        // there should be no edges remaining, so if there are it means some part of the graph is cyclic
        var cyclic = incoming_edge_matrix.some(edges => edges.includes(1))

        if (cyclic) {
            throw Error("Cyclic graph")
        }
        //Convert order Key maps to Blocks
        
        this.workflowOrderSnapshot = []
        const test = sorted_list.map(keyIdx => {
            this.workflowOrderSnapshot.push(keys[keyIdx])
            return topBlocksIdMap[keys[keyIdx]]
        })
        return test

    }
    
    gatherAbilities(abilityConstructor, includeSubscriptions = false, ignoreBlockPositioning = true, definitionId = 'default') {
        const filteredAbilities = this.keys()
            .map(key => {
                return this.workflows[key].gatherAbilities(abilityConstructor, includeSubscriptions, ignoreBlockPositioning, definitionId)
            })
            .filter(abilities => abilities.length > 0)
            .reduce((carry, abilities) => {
                const abilityArray = typeof abilities.toArray === 'function' ? abilities.toArray() : abilities
                return carry.concat(abilityArray)
            }, [])

        if(typeof abilityConstructor.newCollection === 'function') {
            return abilityConstructor.newCollection(filteredAbilities)
        }  
        return filteredAbilities
    }


    attachGlobalDatasetUpdateListener(globalDatasetService) {
        this.globalDatsetService = globalDatasetService
        return this
    }

    changed() {
        this.globalDatsetService.change()
    }

    async getGlobalDatasetData(currentWorkflowState, requireCurrentWorkflow = false) {
        let thisDataset = null;
        if (requireCurrentWorkflow) {
            thisDataset = currentWorkflowState.gatherFirstAbility(ProvidesDataset, false)
        }
        if(requireCurrentWorkflow && !thisDataset) {
            console.warn('No definiton found for state: ' + currentWorkflowState.id)
            return []
        }
        // Just get the default definition. It always the first one
        const thisDefinition = requireCurrentWorkflow ? thisDataset.getDefinitions()[0] : null

        // gather ability settings
        const workflowStateId = thisDefinition ? thisDefinition.getWorkflowStateId() : null;
        const getOnlySavedDefinitions = true
        const ignoreBlockPositioning = true;
        const includeSubscriptions = false;
        
        let datasetAbilities = this
            .gatherAbilities(ProvidesDataset, includeSubscriptions, ignoreBlockPositioning ,'default')
            .flatMap(ability => ability.getDefinitions(getOnlySavedDefinitions))
            .filter(ability => {
                return ability.getWorkflowStateId() !== workflowStateId
            })

        // add default workflow as the first
        if(thisDefinition) {
            datasetAbilities.unshift(thisDefinition)
        }

        datasetAbilities = ProvidesDataset.newCollection(datasetAbilities)

        return datasetAbilities.getGlobalDatasetData(workflowStateId)
    }

}
