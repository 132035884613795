/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import * as Blockly from 'blockly/core';
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock';
import geoprocessingModal from '@/modals/Geoprocessing.vue';
import { ContentService } from '@/services/content.service';
import assets from '@/assets.js';

const INPUT = Object.freeze({
    DUMMY: 'geoprocessing_dummy',
})

const FIELD = Object.freeze({
    PROCESSING_OPTIONS: 'geoprocessing_options',
    COLLECTION_OPTIONS: 'collection_options',
    WITH_LABEL: 'with_label'
})

const GEOPROCESSING_OPTIONS = [
    ['Intersect', 'intersect'],
    //['Combine', 'combine'],
    ['Difference', 'difference'],
    ['Dissolve', 'dissolve'],
]

const NON_VECTOR_GEOPROCESSING_OPTIONS = [
    ["Select an option", NO_DATA_DROPDOWN_VALUE]
]


const COLLECTION_OPTIONS = [
    ["Select an option", NO_DATA_DROPDOWN_VALUE]
]

const options = {
    "showModal": false, 
}

var geoprocessingJson = {
    "type": "analysis_geoprocessing",
    "message0": `%1 %2 %3 %4 %5 %6 %7`,
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "geoprocessing_title",
            "text": "%{BKY_ANALYSIS_GEOPROCESSING_BLOCK_TITLE} ", 
            "class": "boldTitleField"
        },
        {
            "type": "field_modal",
            "name": "geoprocessing_modal",
            "modal_component": geoprocessingModal,
            "src": assets.blockly.infoWhite24dp,
            "modal_data": options,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.PROCESSING_OPTIONS,
            "options": [
                ["Select an option", NO_DATA_DROPDOWN_VALUE]
            ]
        },
        {
            "type": "field_label_serializable",
            "name": FIELD.WITH_LABEL,
            "text": "%{BKY_ANALYSIS_GEOPROCESSING_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": FIELD.COLLECTION_OPTIONS,
            "options": COLLECTION_OPTIONS
        },
        {
            "type": "input_dummy",
            "name": INPUT.DUMMY
        },
    ],
    "previousStatement": null,
    "nextStatement": null,
    "style": "geoprocessing",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['analysis_geoprocessing'] = {
    ...AbstractBlock,
    ...AbstractFieldHelpers,
    FIELD: FIELD,
    INPUT: INPUT,
    // The list of geoprocessing options that do not require a vector collection to be selected
    NO_COLLECTION_VALS: ['dissolve'],

    onInit: function() {
        this.jsonInit(geoprocessingJson);
        // add validator to GEOPROCESSING_OPTIONS field
        let optionsField = this.getField(FIELD.PROCESSING_OPTIONS);
        optionsField.setValidator(this.validateProcessingOptions)
        this.about_block_url = ContentService.getAboutBlockUrl('analysis_geoprocessing')
    },

    accept: async function (visitor) {
        await visitor.visitAnalysisGeoprocessingBlock(this);
    },

    onSaveExtraState(state) {
        if (!this.fieldExists(FIELD.COLLECTION_OPTIONS)) {
            return state
        }

        const field = this.getField(FIELD.COLLECTION_OPTIONS)
        state.collection_dropdown = [field.getText(), field.getValue()]
        return state
    },

    onLoadExtraState(state) {
        if (!this.fieldExists(FIELD.COLLECTION_OPTIONS)) {
            return state
        }

        if (!state.collection_dropdown) {
            console.warn("No collection dropdown in state")
            return state
        }

        this.getField(FIELD.COLLECTION_OPTIONS).updateOptions([state.collection_dropdown])
        return state
    },

    updateShape_: async function() {
        let type = this.getState('type')
        this.updateProcessingOptions(type)
        this.updateCollectionOptions(type)
    },

    /**
     * Updates the geoprocessing fields based on the value of the processing type
     * @param {string} geoprocessingType - the type of geoprocessing
     * @returns {void}
     */
    updateGeoprocessingFields: function(geoprocessingType) {
        const oldProcessingOptions = this.getField(FIELD.PROCESSING_OPTIONS).getOptions();

        const oldVectorOptions = this.fieldExists(FIELD.COLLECTION_OPTIONS) ? this.getField(FIELD.COLLECTION_OPTIONS).getOptions() : COLLECTION_OPTIONS;
        const oldSelectedVector = this.fieldExists(FIELD.COLLECTION_OPTIONS) ? this.getFieldValue(FIELD.COLLECTION_OPTIONS) : NO_DATA_DROPDOWN_VALUE;

        this.removeInput(INPUT.DUMMY);

        const input = this.appendDummyInput(INPUT.DUMMY);

        const newProcessField = this.createDropdownWithValue(oldProcessingOptions, geoprocessingType)
        newProcessField.setValidator(this.validateProcessingOptions)
        input.appendField(newProcessField, FIELD.PROCESSING_OPTIONS);

        if (this.NO_COLLECTION_VALS.includes(geoprocessingType)) {
            return;
        }

        const labelValue = Blockly.Msg['ANALYSIS_GEOPROCESSING_LABEL']

        const newLabelField = new Blockly.FieldLabelSerializable(labelValue);
        const newCollectionField = this.createDropdownWithValue(oldVectorOptions, oldSelectedVector)

        input.appendField(newLabelField, FIELD.WITH_LABEL);
        input.appendField(newCollectionField, FIELD.COLLECTION_OPTIONS);
    },

    /**
     * Given a parent type either vector or raster, update the processing options
     * @param {string} type - type of parent dataset
     * @returns {void}
     */
    updateProcessingOptions: function(type) {
        const optionsField = this.getField(FIELD.PROCESSING_OPTIONS);

        if (type !== 'vector') {
            optionsField.updateOptions(NON_VECTOR_GEOPROCESSING_OPTIONS)
            return;
        }

        optionsField.updateOptions(GEOPROCESSING_OPTIONS)
    },

    /**
     * Given a parent type update the collection options, will query the state for 'vectors' to update the options.
     * Resilient to the field not existing.
     * @param {string} type - type of parent dataset
     * @returns {void}
     */
    updateCollectionOptions: function(type) {
        if (!this.fieldExists(FIELD.COLLECTION_OPTIONS)) {
            return;
        }

        const collectionOptionsField = this.getField(FIELD.COLLECTION_OPTIONS);

        if (type !== 'vector') {
            collectionOptionsField.updateOptions(COLLECTION_OPTIONS);
            return;
        }

        // loading extra options is handled by the onLoadExtraState, 
        // as such we don't want to update the options during this process
        if (this.isLoadingState() || this.isLoadingWorkflow()) {
            console.warn("Loading state or workflow, not updating collection options")
            return;
        } 

        const options = this.getState('vectors', []);

        if (options.length === 0) {
            collectionOptionsField.updateOptions(COLLECTION_OPTIONS);
            return;
        }

        collectionOptionsField.updateOptions(options);
    },

    ebxValidate: function(errors) {
        const type = this.getState('type')
        if (type !== "vector") {
            this.setWarningText(errors['not_vector_dataset'] || "This dataset doesn’t contain vectors. Use this block in workflows with a vector dataset input.")
            return
        }
        const collections = this.getFieldValue(FIELD.COLLECTION_OPTIONS);
        const analysis_option = this.getFieldValue(FIELD.PROCESSING_OPTIONS);
        if (collections === "nodata" && analysis_option !== "dissolve") {
            this.setWarningText(errors['no_collection'] || "Please add a collection")
        } else { 
            this.setWarningText(null)
        }
    },

    validateProcessingOptions: function(newValue) {
        const block = this.getSourceBlock();
        block.updateGeoprocessingFields(newValue);
        block.updateShape_();
        return newValue;
    }
}