<template>
    <div class="asset-card--item" :class="{'asset-card--item__no-action': !canShowMenu, 'asset-card--item__action': canShowMenu}">
        <div class="asset-card--item-body"  @click.prevent="handleClick">
            <div class="asset-card--item-title">
                <p class="ebx-primary-bold">{{ content.name || content.id }} </p>
                <div v-if="projectIsOrgTemplate" class="ebx-pill ebx-pill--pine ebx-tertiary">Template</div>
                <div v-if="projectIsGlobalTemplate" class="ebx-pill ebx-pill--pine ebx-tertiary">Earth Blox Template</div>
            </div>
        </div>
        <div v-if="canShowMenu" class="asset-card--item-actions">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                </template>
                <v-list>
                    <v-list-item 
                        v-if="canEdit" @click="$emit('edit', content)">
                        <v-list-item-title class="text-body-2">Edit name and description</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="canShareWorkflows && !projectIsGlobalTemplate && projectIsPrivate" @click="$emit('move-to-team', content)">
                        <v-list-item-title class="text-body-2">Move to team</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="userVariablesEnabled && canShareWorkflows && !projectIsOrgTemplate" @click="$emit('move-to-published', content)">
                        <v-list-item-title class="text-body-2">Publish as template</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="userVariablesEnabled && canShareWorkflows && projectIsOrgTemplate && (isAdmin || isOwnerOfProject)" @click="$emit('unpublish-org-template', content)">
                        <v-list-item-title class="text-body-2">Unpublish as template</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="isSuperAdmin && projectIsGlobalTemplate === false" @click="$emit('move-to-global-templates', content)">
                        <v-list-item-title class="text-body-2">Publish as Earth Blox template</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="isSuperAdmin && projectIsGlobalTemplate" @click="$emit('unpublish-global-template', content)">
                        <v-list-item-title class="text-body-2">Unpublish Earth Blox template</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="canMakeCopy" @click="$emit('duplicate', content)">
                        <v-list-item-title class="text-body-2">Make a copy</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="canEditApiAccess" @click="$emit('api-access', content)">
                        <v-list-item-title class="text-body-2">API access</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        v-if="canEdit" @click="$emit('delete', content)">
                        <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

    </div>
    
</template>

<script>
import { PROJECT_MODE } from '../../mixins/projectMixin';
import authMixin from '@/components/mixins/authMixin'

export default {
    mixins: [authMixin],
    data() {
        return {
            showModal: false,
        }
    },
    props: {
        content: {
            type: Object,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: true
        },
        canMakeCopy: {
            type: Boolean,
            required: true
        },
        canEditApiAccess: {
            type: Boolean,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        },
        canShareWorkflows: {
            type: Boolean,
            required: true
        },
        projectIsGlobalTemplate: {
            type: Boolean,
            required: true
        },
        projectIsOrgTemplate: {
            type: Boolean,
            required: true
        },
        projectIsPrivate: {
            type: Boolean,
            required: true
        }
    },
    emits: [
        'edit',
        'move-to-team',
        'move-to-published',
        'unpublish-org-template',
        'move-to-global-templates',
        'unpublish-global-template',
        'duplicate',
        'api-access',
        'delete',
        'load'
    ],
    computed: {
        canShowMenu() {
            return this.canEdit || this.canMakeCopy || this.canEditApiAccess || (this.canShareWorkflows && !this.projectIsTemplateType && this.projectIsPrivate)
        },
        isSelected:{
            get() {
                return this.selected ? this.content.id : false
            },
            set() {
                this.handleClick()
            }
        },
        hasThumbnail() {
            return this.content.thumbnail !== undefined && this.content.thumbnail !== '' && this.content.thumbnail !== null
        }
    },
    methods: {
        handleClick() {
            this.$emit('load', this.content, this.content.mode || PROJECT_MODE.READONLY)
        },
        isOwnerOfProject() {
            return this.content.uid === this.user.id
        }
    }
}
</script>
