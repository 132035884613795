<template>
    <div class="variables-content">
        <div class="variables-content--empty" v-show="!hasVariables">
            <div class="variables-content--empty-content">
                <div class="ebx-icon variables-content--empty-item">
                    <img :src="assets.icons.emptyStateVariables" alt="No Variables">
                </div>
                <p class="ebx-primary variables-content--empty-item">Add variables to your workflows to allow Explorer users to select areas and dates</p>
                <v-menu location="right">
                    <template v-slot:activator="{ props }">
                        <v-btn variant="text" v-bind="props" color="primary">Create a variable</v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="varType in variablesService.types" 
                            :key="varType.type"
                            @click="createNewVariable(varType.type)">
                            <v-list-item-title class="text-body-2">{{varType.name}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </div>
        <div class="variables-content--container" v-show="hasVariables">
            <EbxAlert theme="error" v-if="hasBlockWarnings && isExplorer">
                {{errorMessage}}
            </EbxAlert>
            <div class="variables-content--variable" v-for="variableObj in variablesService.variables" :key="variableObj.id">
                <AreaVariable 
                    v-if="variableObj.type === 'area'"
                    :variable="variableObj"
                    :is-explorer="isExplorer"
                    :are-warnings="hasBlockWarnings || runInProgress"
                    @error-dialog-fired="handleErrorDialogFired"
                    @cancel-editing="handleCancelEditing"
                    @edit-variable="handleEditVariable"
                    @save-variable="handleVariableSaved" />

                <DateVariable
                    v-if="variableObj.type === 'date'"
                    :variable="variableObj"
                    :is-explorer="isExplorer"
                    :are-warnings="hasBlockWarnings || runInProgress"
                    @error-dialog-fired="handleErrorDialogFired"
                    @cancel-editing="handleCancelEditing"
                    @edit-variable="handleEditVariable"
                    @save-variable="handleVariableSaved"
                />
                <DateRangeVariable 
                    v-if="variableObj.type === 'date range'"
                    :variable="variableObj"
                    :is-explorer="isExplorer"
                    :are-warnings="hasBlockWarnings || runInProgress"
                    @error-dialog-fired="handleErrorDialogFired"
                    @cancel-editing="handleCancelEditing"
                    @edit-variable="handleEditVariable"
                    @save-variable="handleVariableSaved" />
            </div>
            <div v-if="isNewVariable" class="variables-content--variable">
                <AreaVariable
                    v-if="newVariableType === 'area'"
                    :is-new="isNewVariable"
                    @error-dialog-fired="handleErrorDialogFired"
                    @cancel-editing="handleCancelEditing"
                    @edit-variable="handleEditVariable"
                    @save-variable="handleVariableSaved" />

                <DateVariable
                    v-if="newVariableType === 'date'"
                    :is-new="isNewVariable"
                    @error-dialog-fired="handleErrorDialogFired"
                    @cancel-editing="handleCancelEditing"
                    @edit-variable="handleEditVariable"
                    @save-variable="handleVariableSaved"
                />
                <DateRangeVariable 
                    v-if="newVariableType === 'date range'"
                    :is-new="isNewVariable"
                    @error-dialog-fired="handleErrorDialogFired"
                    @cancel-editing="handleCancelEditing"
                    @edit-variable="handleEditVariable"
                    @save-variable="handleVariableSaved"
                />
            </div>

            <v-menu location="right">
                <template v-slot:activator="{ props }">
                    <v-btn 
                        v-if="!isEditingVariable && !isExplorer"
                        prepend-icon="mdi-plus" 
                        variant="text" 
                        color="primary"
                        max-width="fit-content"
                        v-bind="props">Add new variable</v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="varType in variablesService.types" 
                        :key="varType.type"
                        @click="createNewVariable(varType.type)">
                        <v-list-item-title class="text-body-2">{{varType.name}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>

<script>
import AreaVariable from '@/components/Variables/AreaVariable/AreaVariable.vue'
import DateVariable from '@/components/Variables/DateVariable/DateVariable.vue'
import DateRangeVariable from '@/components/Variables/DateRangeVariable/DateRangeVariable.vue'
import { VariablesService } from '@/services/variable.service';
import { VARIABLE_PROVIDERS } from '@/constants/nextGenConstants';
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    mixins: [assetsMixin],
    name: 'Variables',
    components: {
        AreaVariable,
        DateVariable, 
        DateRangeVariable
    },
    props: {
        isExplorer: {
            type: Boolean,
            required: false,
        },
        runInProgress: {
            type: Boolean,
            required: false,
        }
    },
    emits: [
        'error-dialog-fired'
    ],
    data() {
        return {
            variablesService: VariablesService.getAsReactiveObject(),
            isEditingVariable: false,
            showChooseAreaDialog: false,
            isNewVariable: false,
            newVariableType: null,
            errorMessage: "To use variables in this project, fix the workflow errors in the workspace tab.",
        }
    },
    computed: {
        hasVariables() {
            return this.variablesService.variables.length > 0 || this.isNewVariable;
        },
        hasBlockWarnings() {
            return this.$store.getters['blockly/warningsPresent']
        },
    },
    created() {
    },
    methods: {
        createNewVariable(type) {
            if (this.isEditingVariable) {
                // if user is already editing a variable, don't allow them to create a new one
                return
            }
            this.isNewVariable = true;
            this.isEditingVariable = true;
            this.newVariableType = type;
        },
        handleCancelEditing() {
            this.isEditingVariable = false;
            this.isNewVariable = false;
        },
        handleEditVariable() {
            this.isEditingVariable = true;
        },
        handleErrorDialogFired(errorText) {
            this.$emit('error-dialog-fired', errorText);
        },
        handleVariableSaved(variable, type) {
            if(variable.id === undefined) {
                VariablesService.addVariable(type, VARIABLE_PROVIDERS.PROJECT.id, variable.title, variable.description, variable.value) 
            } else {
                const serviceVariable = VariablesService.getVariableById(variable.id);
                if (serviceVariable) {
                    Object.keys(variable)
                    .filter(key => ['id'].includes(key) === false)
                    .forEach(key => {
                        serviceVariable[key] = variable[key];
                    });
                }
            }       
            this.isEditingVariable = false;
            this.isNewVariable = false;     
        }
    }
}
</script>