/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import * as Blockly from 'blockly/core';
import assets from '@/assets.js';
import Algorithm from '@/modals/Algorithm.vue';
import TrainingData from '@/modals/TrainingData.vue'; 
import { AreaService } from '@/services/area.service';
import {togglePlayButton, addRunButtonInput, addStopButtonInput} from '@/blocks/mixins/togglePlayButtons'
import {NO_DATA_DROPDOWN_VALUE} from '@/constants/nextGenConstants';
import { AbstractBlock, AbstractFieldHelpers } from '@/blocks/_abstractBlock'
import { ContentService } from '@/services/content.service';

const initialAlgorithmData = {
    "number_trees" : 10,
    "variables": null,
    "leaf_pop": 1,
    "bag_fraction": 0.5,
    "max_nodes": null,
    "seed": 0,
    "kernel": 'RBF',
    "metric": 'euclidean',
    "knearest": 1,
    "cost": 1, 
    "gamma": 0.5,
}

const initialTrainingData = {
    "properties": [],
    "property": 'Map',
    "options" : [
        [
            'Random',
            'random'
    ]],
    "split": 'random',
    "ratios" : [
        [
            '80/20',
            '80/20'
        ],
        [
            '50/50',
            '50/50'
    ]],
    "ratio": '80/20',
}

const classifyImageJson = {
    "type": "workflow_classify_image",
    "lastDummyAlign0": "RIGHT",
    "message0": "%1 %2 %3 %4 %5 %6 %7 %8 %9 %10 %11 %12 %13 %14 %15 %16 %17 %18",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "classify_title",
            "text": "%{BKY_WORKFLOW_CLASSIFY_IMAGE_TITLE}", 
            "class": "boldTitleField"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "alg_label",
            "text": "%{BKY_WORKFLOW_CLASSIFY_IMAGE_ALG_LABEL}"
        },
        {
            "type": "field_dropdown",
            "name": "alg_options",
            "options": [
                ['Random Forest', 'random_forest'],
                ['CART', 'cart'], 
                ['Minimum Distance','min_distance'],
                ['Support Vector Machine (SVM)', 'svm']
        ]
        },
        {
            "type": "field_modal",
            "name": "modal_button",
            "modal_component": Algorithm,
            "modal_data": initialAlgorithmData,
            "src": assets.blockly.settingsWhite24dp,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "classify_image_input_label",
            "text": "%{BKY_WORKFLOW_CLASSIFY_IMAGE_INPUT_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "dataset"
        },
        {
            "type": "field_label_serializable",
            "name": "classify_image_training_label",
            "text": "%{BKY_WORKFLOW_CLASSIFY_IMAGE_TRAINING_LABEL}"
        },
        {
            "type": "field_notrim_dropdown",
            "name": "training_data",
            "options": [["No training data", NO_DATA_DROPDOWN_VALUE]]
        },
        {
            "type": "field_modal",
            "name": "training_modal_button",
            "modal_component": TrainingData,
            "modal_data": initialTrainingData,
            "src": assets.blockly.settingsWhite24dp,
            "width": 24,
            "height": 24,
            "opt_alt": "ALT STRING"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "field_label_serializable",
            "name": "classify_image_output_label",
            "text": "%{BKY_WORKFLOW_CLASSIFY_IMAGE_OUTPUT_LABEL}"
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_statement",
            "name": "output_data"
        },
        {
            "type": "field_label_serializable",
            "name": "run_button_label",
            "text": "Run"
        },
        {
            "type": "field_play_button",
            "name": "run_button_field",
        }
    ],
    "style": "classify",
    "tooltip": "",
    "helpUrl": ""
}

Blockly.Blocks['workflow_classify_image'] = {
    ... AbstractBlock,
    ... AbstractFieldHelpers,
    togglePlayButton,
    addRunButtonInput,
    addStopButtonInput,
    onInit: function () {
        this.jsonInit(classifyImageJson);

        let runButtonField = this.getField('run_button_field')
        runButtonField.getParentInput().name = "run_button_input"

        this.about_block_url = ContentService.getAboutBlockUrl('workflow_classify_image')

        this.getField('alg_options').setValidator(this.algOptionValidator);
    },
    
    accept: async function (visitor) {
        console.log('accept workflow_classify visitor')
        await visitor.visitWorkflowClassifyBlock(this);
    },

    algOptionValidator: function (value) {
        const block = this.getSourceBlock();
        let modalData = block.getFieldValue('modal_button')
        modalData.algorithm = value
        block.setFieldValue(modalData, 'modal_button')
        return value
    },
    onLoadExtraState: function (extraState) {
        if (extraState.vectors) {
            this.updateOptionsIfSet('training_data', extraState.vectors)
        }
        return extraState
    },
    _validateTrainingData: function(trainingData, errors) {
        const noAreaText = errors['no_areas_training_data_warning'] || 'Collection contains no areas so can not be used for supervised classification.';
        const noCollectionText = errors['no_training_data_warning'] || 'To run a supervised classification, select a Collection to use as training data.';
        const outsideAoiText = errors['training_data_outside_aoi_warning'] || 'Areas used as training data must be within the boundary of  your Area of Interest.';

        if(!trainingData) {
            // this.setWarningText(null, "no_training_data_warning");
            return;
        }

        // Check if trainingData is a map area. if not assume its a dataset/vector.
        // May need to make this more complicated in the future but for now this is fine (with only 2 types)
        const isMapArea = AreaService.getCollectionById(trainingData) !== undefined
        // check if trainingData collection has any areas associated with it
        let hasAreas = AreaService.getAreasForCollection(trainingData).length > 0;
        
        if (isMapArea === false) {
            return
        }
        if (this.hasState('studyAreas') && this.getState('studyAreas') && trainingData !== NO_DATA_DROPDOWN_VALUE) {
           
            let studyAreas = this.getState('studyAreas');
            // check if trainingData geometry is enclosed in the AOI            
            var trainingDataInAoi = AreaService.isGeometryInAoi(trainingData, studyAreas);

            if (trainingDataInAoi === false) { //if any polygons in the training data are not in the AOI
                this.setWarningText(outsideAoiText, "no_areas_training_data_warning");
                return;
            }
        }

        if (trainingData === NO_DATA_DROPDOWN_VALUE) {
            this.setWarningText(noCollectionText, "no_training_data_warning");
            return;
        }

        if (!hasAreas) {
            // scenario where we have error message for lack of areas in training data
            this.setWarningText(noAreaText, "no_training_data_warning");
            return;
        }  
    },

    _validateInputsAndOutputs: function(inputs, outputs, errors) {
        const missingInputText = errors['missing_input_warning'] || 'Add an input block to the workflow.';
        const missingInputAndOutputText = errors['missing_input_and_output_warning'] || 'Add an input and an output block to the workflow.';
    
        if (inputs.length === 0 && outputs.length === 0) {
            this.setWarningText(missingInputAndOutputText, "missing_input_and_output_warning");
            return;
        }

        if (inputs.length === 0) {
            this.setWarningText(missingInputText, "missing_input_warning");
            return;
        }
    },

    ebxValidate: function(errors) {
        const trainingData = this.getFieldValue('training_data');
        this._validateTrainingData(trainingData, errors);

        // from the input statement, dataset, get the blocks connected to it
        const inputs = []

        let input_block = this.getInputTargetBlock('dataset');

        while (input_block) {
            inputs.push(input_block);
            input_block = input_block.getNextBlock('dataset');
        }

        const outputs = []

        let output_block = this.getInputTargetBlock('output_data');

        while (output_block) {
            outputs.push(output_block);
            output_block = output_block.getNextBlock('output_data');
        }

        this._validateInputsAndOutputs(inputs, outputs, errors);
    },
        // this.setFieldValue(initialModalData, 'modal_button');
    updateShape_: function() {
        // check if block has parent, then remove the play button
        this.togglePlayButton()
        if(this.hasStateChanged('vectors')) {
            this.updateOptionsIfSet('training_data', this.getState('vectors'))
        } 
    }
    
}