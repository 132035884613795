<template>
    <div class="access-denied d-flex justify-center align-center h-screen">
        <div class="access-denied--container d-flex flex-column justify-center align-center">
            <img :src="assets.icons.padlock" alt="No Access" class="mb-10" />
            <p class="ebx-primary">You don’t have access to this page. </p>
        </div>
    </div>
</template>

<script>
import assetsMixin from '@/components/mixins/assetsMixin.js'
export default {
    name: 'AccessDenied',
    metaInfo: {
        title: '403 No Access',
    },
    mixins: [
        assetsMixin
    ]
};
</script>