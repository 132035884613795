<template>
    <div class="collection-layer">
        <md-card>
            <ebx-card-expand v-model="expand" class="layer-control--layer">
                <div class="layer-control--layer-items">
                    <div class="layer-control--layer-items__lhs-cluster">
                        <!-- Expander CTA -->
                        <md-card-expand-trigger>
                            <md-button class="ebx-button--icon">
                                <div class="folder-carat">
                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                </div>
                            </md-button>
                        </md-card-expand-trigger>
                        <div class="list-item-text-container">
                            <md-tooltip>
                                {{collection.name}} 
                            </md-tooltip>
                            <span class="md-list-item-text">
                                <md-field v-if="isRenamingCollection" class="md-field-inline">
                                    <md-input 
                                        ref="collectionInputRef" 
                                        :model-value="collection.name" 
                                        @update:modelValue="setTempCollectionValues(collection, $event)" 
                                        @keyup.enter.prevent="handleRenameCollection(collection)"
                                        @blur="handleRenameCollection(collection)"
                                        @keyup.escape="cancelRenameCollection(collection)"
                                    />
                                </md-field>
                                <div v-else>
                                    {{collection.name}} 
                                </div>
                            </span>
                        </div>
                    </div>

                    <md-card-actions>
                        <!-- Context Menu CTA -->
                        <v-menu v-if="collectionIsStudyArea(collection)===false" class="show-on-hover">
                            <template v-slot:activator="{ props }">
                                <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    prepend-icon="mdi-pencil-outline"
                                    @click="renameCollection(collection)">
                                    <v-list-item-title class="text-body-2">Rename</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    prepend-icon="mdi-tray-arrow-down"
                                    @click="exportCollection(collection)">
                                    <v-list-item-title class="text-body-2">Export</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    prepend-icon="mdi-delete-outline"
                                    @click="removeCollection(collection)">
                                    <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </md-card-actions>
                </div>
                <!-- Expander Hidden content -->
                <md-card-expand-content>
                    <md-card-content>
                        <div>
                            <GoogleMapAreaFeatures
                                :map="map"
                                :google="google"
                                :areas="collectionAreas"
                                :collectionId="collection.id"
                                :currentlySelectedArea="selectedArea"
                                @colour-changed="handleColourChange"
                                @remove-collection="removeCollection"
                            />
                            <GoogleMapAddArea
                                :collectionId="collection.id"
                                @add-new-area="addArea"
                                @upload-polygon-to-collection="handleOpenPolygonAreaDialog"
                                @choose-area="handleOpenChooseAreaDialog"
                            />
                        </div>
                    </md-card-content>
                </md-card-expand-content>
            </ebx-card-expand>
        </md-card>        
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import { AreaService }  from '@/services/area.service';

import GoogleMapAreaFeatures from '@/components/ResultMap/GoogleMapAreaFeatures.vue'
import GoogleMapAddArea from '@/components/ResultMap/GoogleMapAddArea.vue'
import EbxCardExpand from '@/components/EbxComponents/EbxCardExpand.vue'

import { MAP_STUDY_AREA_COLLECTION_ID } from '../../constants/nextGenConstants';
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    mixins: [
        assetsMixin
    ],
    components: {
        GoogleMapAreaFeatures,
        GoogleMapAddArea,
        EbxCardExpand
    },
    props: {
        collection: {
            type: Object,
            required: true 
        },
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        areas: {
            type: Array,
            required: true
        },
        selectedArea: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: [
        'colour-changed',
        'upload-polygon-to-collection',
        'choose-area',
        'rename-collection',
        'set-temp-collection-values',
        'removed-collection',
        'rename-collection-cancel',
        'export-collection',
        'add-new-area',  
    ],
    data() {
        return {
            study_collection_id: MAP_STUDY_AREA_COLLECTION_ID,
            showGeometriesDropdown: false,
            lastCollectionCounter: 0,
            collectionIdsRenaming: [],
            collectionTempRenameValues: {},
            isRenamingCollection: false,
            expand: true
        };
    },
    methods: {
        collectionIsStudyArea(collection) {
            return collection.id === this.study_collection_id
        },
        handleColourChange(model) {
            this.$emit("colour-changed", model)
        },
        handleOpenPolygonAreaDialog(collectionId, addToMap){
            this.$emit('upload-polygon-to-collection', collectionId, addToMap)
        },
        handleOpenChooseAreaDialog(collectionId) {
            this.$emit('choose-area', collectionId)
        },
        /**
         * Methods brought in from old GoogleMapAreasView component
         */
        handleRenameCollection(collection) {
            this.$emit('rename-collection', collection)
            this.isRenamingCollection = false
        },
        setTempCollectionValues(collection, value) {
            this.$emit('set-temp-collection-values', collection, value)
        },
        renameCollection(collection) {
            this.collectionIdsRenaming.push(collection.id)
            //small delay for some browser (safari) has issues
            setTimeout(() => { 
                this.$refs.collectionInputRef.$el.focus()
            }, 100)
            this.isRenamingCollection = true
        },
        removeCollection(collection) {
            this.$emit('removed-collection', collection)
        },
        cancelRenameCollection(collection) {
            this.$emit('rename-collection-cancel', collection)
            this.isRenamingCollection = false
        },
        exportCollection(collection) {
            this.$emit('export-collection', collection)

        },
        addArea() {
            this.$emit('add-new-area', null, this.collection.id, true)
        },
    },
    mounted() {
        this.clearCollectionsSubscription = AreaService.clearCollections$.subscribe(function() {
            this.collections = [];
            this.lastCollectionCounter = 0;
        }.bind(this));
    },
    computed: {
        collectionAreas() {
            return this.areas.filter((area) => area.collectionId === this.collection.id)
        },
    },
    beforeUnmount() {
        this.clearCollectionsSubscription.unsubscribe();
    }
};
</script>