/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import {FieldButton} from './FieldButton';
import {createApp, reactive, h, nextTick} from 'vue'
import store from '@/store/index'
import {VueMaterialPlugin} from "@/vue3-material/material";
import { VuetifyPlugin } from '@/vuetify/vuetifyPlugin';
import {createGlobalRouter} from "@/router";
import { globalEventBus } from '@/eventbus.js'
import {GlobalPlugin} from '@/plugins/global'


/**
 * @extends {FieldButton}
 */
class FieldModal extends FieldButton {
    /**
     * The component to use for the modal.
     * @type {Vue}
     */
    modalComponent_ = null;

    /**
     * The instance of the modal component.
     * @type {Vue.Component}
     */
    instance_ = null;
    /**
     * Determines whether the field is visable.
     * @type {Boolean}
     */
    onSave_ = null;
    onClose_ = null;

    /**
     * Constructor for a FieldModal.
     * @param {Object} modal_component 
     * @param {Object} modal_data - the initial modal props
     * @param {String} src 
     * @param {Number} width 
     * @param {Number} height 
     * @param {Function} opt_validator - A function that is called to validate
     *    changes to the field's value. Takes in a value & returns a validated
     *    value, or null to abort the change.
     * @param {Function} opt_onSave - A function that is called when the modal is saved, takes in the modal data.
     * @param {Function} opt_onClose - A function that is called when the modal is closed.
     * @param {String} opt_alt
     * @param {Boolean} opt_flipRtl
     * @param {Object} opt_config
     */
    constructor(modal_component, modal_data, src, width, height, opt_validator, opt_onSave, opt_onClose, opt_alt, opt_flipRtl, opt_config) {
        super(src, width, height, opt_alt, undefined, opt_flipRtl, opt_config);

        // can't pass as an argument to super so we use setter instead
        this.setOnClickHandler(this.showModal)

        //Setup events
        this.eventCallbacks = {}
        this.instance_ = null;

        // create vue component and setup events
        this.modalComponent_ = modal_component;

        this.ComponentClass = this.modalComponent_;

        // create reactive props
        this.propsData_ = reactive({
            showModal: false,
            ...this.value_
        })
        this.reactiveData_ = reactive({})

        if([undefined, null].indexOf(modal_data) < 0) {
            this.setValue(modal_data);
        }
        
        if(opt_validator) this.setValidator(opt_validator);
        if(opt_onSave) this.setOnSave(opt_onSave);
        if(opt_onClose) this.setOnClose(opt_onClose);

        this.EDITABLE = true;
        this.SERIALIZABLE = true;
    }

    createModalInstance_() {
        if(this.instance_ !== null) {
            return;
        }
        // create vue instance
        this.instance_ = createApp({
            render: () => this.vm = h(this.ComponentClass, this.propsData_)
        })
        this.instance_.use(store)
        this.instance_.use(VueMaterialPlugin)
        this.instance_.use(VuetifyPlugin)
        this.instance_.use(GlobalPlugin())
        this.instance_.use(createGlobalRouter())
        this.providedValues = {
            onModalSave: this.onModalSave.bind(this),
            onModalClose: this.onModalClose.bind(this),
            eventCallbacks: this.eventCallbacks,
            toWatchData: this.reactiveData_ 
        }

        this.instance_.provide('onModalSave', this.providedValues.onModalSave)
        this.instance_.provide('onModalClose', this.providedValues.onModalClose)
        this.instance_.provide('eventCallbacks', this.providedValues.eventCallbacks)
        this.instance_.provide('toWatchData', this.providedValues.toWatchData)

        // create empty div and mount to document body, with field-modal class
        this.field_modal_div_ = document.createElement('div')
        this.field_modal_div_.classList.add('ebx-field-modal');
        document.body.appendChild(this.field_modal_div_);

        // mount the instance to the div
        this.instance_.mount(this.field_modal_div_);
    }

    /**
     * Sets the modal component.
     * @param {Object} modal_component
     */
    setModalComponent(modal_component) {
        this.modalComponent_ = modal_component;
    }

    setValue(newValue) {
        if (newValue !== null && typeof newValue === 'object') {
            newValue = JSON.parse(JSON.stringify(newValue))
        }
        return super.setValue(newValue)
    }

    /**
     * Helper Methods to set a key value pair on the field Value
     * @param {*} key 
     * @param {*} value 
     * @returns 
     */
    setValueKeyValue(key, value) {
        return this.setValue({
            ...this.value_,
            [key]: value
        })
    }


    /**
     * Set a data variable on the modal component without having to pass it in the constructor.
     * @param {String} key - the modal prop to set
     * @param {*} value - any value
     * @returns {FieldModal} - this field
     */
    setModalData(key, value) {
        this.reactiveData_[key] = value
        return this
    }

    /**
     * Gets the modal component.
     * @return {Object} modal_component
     */
    getModalComponent() {
        return this.modalComponent_;
    }

    /**
     * Gets the modal instance.
     * @return {Object} instance_
     */
    getModalInstance() {
        return this.instance_;
    }


    /**
     * Called when the modal is saved.
     * @param {Object} values - the values to save
     */
    onModalSave(data) {
        this.closeModalAndRemoveModalInstance_();
        this.setValue(data);
        if(this.onSave_) {
            this.onSave_(data);
        }
    }

    /**
     * Called when modal is closed.
     */
    onModalClose() {
        if (this.propsData_.showModal === false) {
            return;
        }
        this.closeModalAndRemoveModalInstance_();
        if(this.onClose_) {
            this.onClose_();
        }
    }

    /**
     * Update the modal component props to be the same as the given data.
     * @param {Object} data
     * @private
     */
    updateInstanceProps_(values) {
        Object.keys(values).forEach(key => {
            this.propsData_[key] = values[key];
        })
    }

    /**
     * Used to validate a value. Returns input by default. Can be overridden by
     * subclasses, see FieldDropdown.
     * @param {*=} opt_newValue The value to be validated.
     * @return {*} The validated value, same as input by default.
     * @protected
     */
    doClassValidation_(opt_newValue) {
        if (opt_newValue === null || opt_newValue === undefined) {
            return null;
        }
        return opt_newValue;
    }

    /**
     * Used to update the value of a field. Can be overridden by subclasses to do
     * custom storage of values/updating of external things.
     * @param {*} newValue The value to be saved.
     * @protected
     */
    doValueUpdate_ = function(newValue) {
        this.value_ = newValue;
        this.isDirty_ = true;
    };

    /***
     * Closes the modal and removes the modal instance.
     */
    closeModalAndRemoveModalInstance_() {
        globalEventBus.$emit('supressDeleteAction', false)
        this.propsData_.showModal = false;
        this.disposeInstance_();
    }

    /**
     * Disposes the vue instance.
     */
    disposeInstance_() {
        if( this.instance_) {
            this.instance_.unmount();
            // remove the div from the body
            this.field_modal_div_.remove();
            this.field_modal_div_ = null;
            this.instance_ = null;
            this.modalComponent_ = null;
        }
    }

    /**
     * Dispose of all DOM objects and events belonging to this editable field. 
     * Removes vue instance on field/block disposal.
     * @override
     */
    dispose() {
        this.disposeInstance_();
        super.dispose();
    }

    /**
     * Shows modal content.
     */
    showModal() {
        // ensures instance modal shares the same values as the field (i.e. if field value is changed by blockly or if user didn't save changes)
        this.createModalInstance_();
        nextTick(() => {
            this.updateInstanceProps_(this.value_);
            this.propsData_.showModal = true;
        })
    }

    setOnSave(onSave) {
        if(typeof onSave !== 'function') {
            throw new Error('onSave must be a function');
        }
        this.onSave_ = onSave;
    }

    setOnClose(onClose) {
        if(typeof onClose !== 'function') {
            throw new Error('onClose must be a function');
        }
        this.onClose_ = onClose;
    }

    addEventListener(event,callback) {
        if(this.eventCallbacks[event] === undefined) {
            this.eventCallbacks[event] = []
        }
        this.eventCallbacks[event].push(callback)
        return this
    }

    /**
     * Construct a FieldModal from a json arg object,
     * @param {Object} options A JSON object with options
     * @returns {FieldModal} The new field instance.
     * @override
     */
    static fromJson(options) {
        return new this(
            options['modal_component'],
            options['modal_data'],
            options['src'],
            options['width'],
            options['height'],
            options['opt_validator'],
            options['opt_onSave'],
            options['opt_onClose'],
            options['opt_alt'],
            options['opt_flipRtl'],
            options['opt_config']
        );
    }
}

export {FieldModal};
