<template>
    <div class="area-variable">
        <!-- creator version -->
        <VariableDetailComponent
            varType="area"
            descHelperText="Add a description to provide guidance for users selecting an area."
            :variable="variable"
            :hasValue="hasValue"
            :isEditing="isEditingVariable"
            @cancel-editing="handleEditCancel"
            @edit-variable="handleEditVariable"
            @save-variable="handleVariableSaved"
            @changed-name="handleVariableNameChanged"
            >
            <div v-if="selectedArea" class="area-variable--selected-area">
                <p class="ebx-secondary area-variable--label">
                    Default area
                </p>
                <div class="area-variable--selection-card">
                    <div class="area-variable--area">
                        <div v-if="selectedArea.colour" class="roundel" :style="{'background-color': selectedArea.colour}"></div>
                        <p class="ebx-secondary">{{selectedArea.name}}</p>
                    </div>
                    <div class="area-variable--action">
                        <button @click="handleRemoveArea" class="ebx-icon-button ebx-icon-button__no-shadow"><span class="material-icons-outlined ebx-icon">delete</span></button>
                    </div>
                </div>
            </div>
            <v-menu location="right">
                <template v-slot:activator="{ props }">
                    <v-btn 
                        prepend-icon="mdi-plus" 
                        variant="text" 
                        color="primary" 
                        v-bind="props"
                        @click="showAddMapArea">Add example area</v-btn>
                </template>
                <v-list>
                    <v-list-item
                        prepend-icon="mdi-map-outline"
                        v-if="showAddMapAreaOption"
                        @click="handleAddMapArea">
                        <v-list-item-title class="text-body-2">Area from map</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        prepend-icon="mdi-folder-outline"
                        @click="handleChooseArea">
                        <v-list-item-title class="text-body-2">Choose from assets</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </VariableDetailComponent>
    </div>
</template>

<script>
import VariableDetailComponent from '@/components/Variables/VariableDetail/VariableDetailComponent.vue'

export default {
    name: 'AreaVariableCreator',
    components: {
        VariableDetailComponent
    },
    props: {
        variable: {
            type: Object,
            required: true
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        },
        selectedArea: {
            type: Object,
            required: false,
            default: null
        },
        hasValue: {
            type: Boolean,
            default: false,
            required: false
        },
        showAddMapAreaOption: {
            type: Boolean,
            default: false,
            required: false
        },
        isDrawn: {
            type: Boolean,
            default: false,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    emits: [
        'changed-name',
        'add-map-area',
        'cancel-editing',
        'edit-variable',
        'remove-area',
        'save-variable',
        'choose-from-assets',
        'show-add-map-area'
    ],
    data() {
        return {
            isEditingVariable: false
        }
    },
    created() {
        if (this.isNew) {
            this.isEditingVariable = true;
        }
    },
    computed: {
        variableId() {
            return this.variable.id;
        },
    },
    methods: {
        handleVariableNameChanged(name) {
            this.$emit('changed-name', name);
        },
        handleAddMapArea() {
            this.$emit('add-map-area');
        },
        handleEditCancel() {
            this.$emit('cancel-editing');
            this.isEditingVariable = false;
        },
        handleEditVariable() {
            this.$emit('edit-variable');
            this.isEditingVariable = true;
        },
        handleRemoveArea() {
            this.$emit('remove-area');
        },
        handleVariableSaved(variable) {
            variable.value = this.selectedArea.id
            this.isEditingVariable = false;
            this.$emit('save-variable', variable);
        },
        handleChooseArea() {
            this.$emit('choose-from-assets');
        },
        showAddMapArea() {
            this.$emit('show-add-map-area')
        }
    }
}
</script>