<template>
    <div class="ebx-drawing-controls--reassign-popup">
        <md-list>
            <md-list-item>
                <v-menu location="right" offset="16px">
                    <template v-slot:activator="{ props }">
                        <span class="text-body-2" v-bind="props">Move to area…</span>
                    </template>
                    <v-list class="ebx-drawing-controls--reassign-popup-menu-content">
                        <v-list-item
                            v-for="area in areas"
                            :key="area.id"
                            :disabled="areaIsDisabled(area)"
                            @click="handleReassign(area)">
                            <div type="button" class="colour-selection-button" :style="{backgroundColor: area.colour}">
                                &nbsp;
                            </div>
                            <v-list-item-text class="text-body-2"> {{  area.name }}</v-list-item-text>
                        </v-list-item> 
                    </v-list>
                </v-menu>
            </md-list-item>
        </md-list>
    </div>
</template>

<script>

export default {
    name: 'ReassignShapeMenu',
    props: {
        areas: {
            type: Array,
            default: () => []
        },
        selectedArea: {
            type: Object,
            default: null
        },
        clickHandler: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        handleReassign(area) {
            if(this.areaIsDisabled(area)=== false) {
                this.clickHandler(area);
            }
        },
        areaIsDisabled(area) {
            return area.id === this.selectedArea.id;
        }
    }, 
}

</script>