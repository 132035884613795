<template>
    <div ref="basemapDropdown" class="basemap-dropdown">
        <md-field class="field-dropdown-button">
            <md-select v-model="basemap" name="basemap" id="basemap" class="field-dropdown-select" md-dense>
                <md-option 
                v-for="(layer, name) in basemapsToDisplay" 
                :key="layer.basemapName" 
                :value="name" 
                class="field-dropdown-option">{{layer.optionName}}</md-option>
            </md-select>
        </md-field>
        <template v-if="showBasemapHasExtraOptionsComponent">
            <component :is="shownBasemapExtraOptionsComponent" :options="shownBasemapOptions" :map="map" @shown="reRenderMap" @change="shownBasemapOptions.customOptionHandler"></component>
        </template>
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */

import { AuthService } from "@/services/auth.service";
import { ConfigService } from '@/services/config.service';
import {PlanetMapType} from "./CustomMapTypes/PlanetMapType";
import {WMTSMapType} from "./CustomMapTypes/WMTSMapType";
import {WMSMapType} from "./CustomMapTypes/WMSMapType";
import { functions } from "@/firebaseFunctions.js"
import NicfiOptions from "./CustomBasemapOptions/Nicfi.vue";

export default {
    name: "GoogleMapBasemapView",
    components: {
        NicfiOptions
    },
    data() {
        let ddata = {
            basemap: "roadmap",
            basemaps: {
                "roadmap": {
                    basemapName: "roadmap",
                    optionName: "Roadmap",
                    isBasemap: true,
                    isCustom: false
                },
                "satellite": {
                    basemapName: "satellite",
                    optionName: "Satellite",
                    isBasemap: true,
                    isCustom: false
                },
                "hybrid": {
                    basemapName: "hybrid",
                    optionName: "Hybrid",
                    isBasemap: true,
                    isCustom: false
                },
                "terrain": {
                    basemapName: "terrain",
                    optionName: "Terrain",
                    isBasemap: true,
                    isCustom: false
                },
                "vivid": {
                    basemapName: "Maxar Vivid",
                    optionName: "Maxar Vivid",
                    isBasemap: true,
                    isCustom: true,
                    display: false,
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        try {
                            let params = {
                                TileMatrixSet: "EPSG:3857", 
                                connectId: apiKey,
                                LAYER: "DigitalGlobe:ImageryTileService",
                                FORMAT: "image/png",
                                STYLE: "",
                                featureProfile: "Global_Currency_Profile"
                            }
                            let url = "https://securewatch.maxar.com/earthservice/wmtsaccess"
                            let minZoom = 0
                            let maxZoom = 20
                            if(this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMTSMapType(new this.google.maps.Size(256, 256), url, minZoom, maxZoom, params))
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                },
                "vivid_demo": {
                    basemapName: "Maxar Vivid Demo",
                    optionName: "Maxar Vivid Demo",
                    isBasemap: true,
                    isCustom: true,
                    display: false,
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        try {
                            let params = {
                                TileMatrixSet: "EPSG:3857",
                                connectId: apiKey,
                                LAYER: "DigitalGlobe:ImageryTileService",
                                FORMAT: "image/png",
                                STYLE: "",
                                featureProfile: "Global_Currency_Profile"
                            }
                            let url = "https://securewatch.digitalglobe.com/earthservice/wmtsaccess"
                            let minZoom = 0
                            let maxZoom = 20
                            if (this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMTSMapType(new this.google.maps.Size(256, 256), url, minZoom, maxZoom, params))
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                },
                "securewatch": {
                    basemapName: "Maxar SecureWatch",
                    optionName: "Maxar SecureWatch",
                    isBasemap: true,
                    isCustom: true,
                    display: false,
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        try {
                            let params = {
                                TileMatrixSet: "EPSG:3857", 
                                connectId: apiKey,
                                LAYER: "DigitalGlobe:ImageryTileService",
                                FORMAT: "image/png",
                                STYLE: "",
                                featureProfile: "Default_Profile"
                            }
                            let url = "https://securewatch.maxar.com/earthservice/wmtsaccess"
                            let minZoom = 0
                            let maxZoom = 20
                            if(this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMTSMapType(new this.google.maps.Size(256, 256), url, minZoom, maxZoom, params))
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                },
                "maxarbasemaps": {
                    basemapName: "Maxar Basemaps",
                    optionName: "Maxar Basemaps",
                    isBasemap: true,
                    isCustom: true,
                    display: false,
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        try {
                            let params = {
                                TileMatrixSet: "EPSG:3857", 
                                connectId: apiKey,
                                LAYER: "DigitalGlobe:ImageryTileService",
                                FORMAT: "image/png",
                                STYLE: "",
                                featureProfile: "Default_Profile"
                            }
                            let url = "https://securewatch.maxar.com/earthservice/wmtsaccess"
                            let minZoom = 0
                            let maxZoom = 20
                            if(this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMTSMapType(new this.google.maps.Size(256, 256), url, minZoom, maxZoom, params))
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                },
                "firstlook": {
                    basemapName: "Maxar FirstLook",
                    optionName: "Maxar FirstLook",
                    isBasemap: true,
                    isCustom: true,
                    display: false,
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        try {
                            let params = {
                                TileMatrixSet: "EPSG:3857", 
                                connectId: apiKey,
                                LAYER: "DigitalGlobe:ImageryTileService",
                                FORMAT: "image/png",
                                STYLE: "",
                                featureProfile: "Default_Profile"
                            }
                            let url = "https://securewatch.maxar.com/earthservice/wmtsaccess"
                            let minZoom = 0
                            let maxZoom = 20
                            if(this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMTSMapType(new this.google.maps.Size(256, 256), url, minZoom, maxZoom, params))
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                },
            },
            overlay_basemaps: {
                // overlay basemaps require another basemap and must define a defaultBasemap
                // it must also define a function which returns an instance of a MapType object
                "nicfi": {
                    basemapName: "NICFI",
                    optionName: "Planet-NICFI tropical regions",
                    isBasemap: false,
                    isCustom: true,
                    defaultBasemap: "roadmap",
                    additionalOptionsComponent: "NicfiOptions",
                    pendingRequests: [],
                    requesting: false,
                    responses: {},
                    pageSize: 100,
                    requestUrlConstructor: (apiKey, page = 0, pageSize = 100) =>{
                            // returns products in ascending date order
                            // https://developers.planet.com/docs/basemaps/reference/#operation/listMosaics
                            var productID = `planet_medres_visual_`
                            var url = new URL('https://api.planet.com/basemaps/v1/mosaics')
                            var params = {name__contains: productID, api_key: apiKey, _page: page, _page_size: pageSize}
                            url.search = new URLSearchParams(params).toString()
                            return url
                    },
                    requestMosaics: async (apiKey, basemapOptions, page) => {
                        if(basemapOptions.requesting) {
                            return new Promise((resolve, reject) => {
                                basemapOptions.pendingRequests.push({resolve, reject})
                            })
                        }
                        try {
                            basemapOptions.requesting = true

                            let data = null
                            const url = basemapOptions.requestUrlConstructor(apiKey, page, basemapOptions.pageSize)

                            if(basemapOptions.responses[url]) {
                                data = basemapOptions.responses[url]
                            } else {
                                const response = await fetch(url)
                                data = await response.json()
                                basemapOptions.responses[url] = data
                            }
                           
                            basemapOptions.requesting = false
                            if(basemapOptions.pendingRequests.length > 0) {
                                const pendingRequest = basemapOptions.pendingRequests.pop()
                                pendingRequest.resolve(data)
                            }
                            return data
                        } catch (error) {
                            basemapOptions.requesting = false
                            if(basemapOptions.pendingRequests.length > 0) {
                                const pendingRequest = basemapOptions.pendingRequests.pop()
                                pendingRequest.reject(error)
                            }
                            throw error
                        }
                    },
                    createMapType: async (apiKey, basemapOptions) => {
                        const data = await basemapOptions.requestMosaics(apiKey, basemapOptions, 0)
                        let mosaicName = null
                        if(basemapOptions.selectedName) {
                            mosaicName = basemapOptions.selectedName
                        } else {
                            const mosaic = data.mosaics[data.mosaics.length - 1]
                            basemapOptions.selectedName = mosaic.name
                            mosaicName = mosaic.name
                        }
                        return new PlanetMapType(
                            new this.google.maps.Size(256, 256),
                            '',
                            'planet-tiles',
                            mosaicName,
                            apiKey,
                            this.map
                        )
                    },
                    customOptionHandler: async (nicfiSelectedName) => {
                        this.shownBasemapOptions.selectedName = nicfiSelectedName
                        await this.updateBasemap('nicfi')    
                    }
                },
                "vividmetadata": {
                    basemapName: "Vivid 2022 Metadata",
                    optionName: "Vivid 2022 Metadata",
                    isBasemap: false,
                    isCustom: true,
                    display: false,
                    defaultBasemap: "vivid",
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        try {
                            let params = {
                                TILEMATRIXSET: "GLOBAL_WEBMERCATOR",
                                layer: "SpatialOnDemand:Maxar_Vivid_Standard_Metadata",
                                format: "image/png",
                                style: "default",
                                authkey: apiKey
                            }
                            let url = `https://spatialondemand.maxar.com/wmts`
                            let minZoom = 0
                            let maxZoom = 20
                            if(this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMTSMapType(new this.google.maps.Size(256, 256), url, minZoom, maxZoom, params))
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                },
                "securewatchmetadata": {
                    basemapName: "Maxar Securewatch Seamlines",
                    optionName: "Maxar Securewatch Seamlines",
                    isBasemap: false,
                    isCustom: true,
                    display: false,
                    defaultBasemap: "securewatch",
                    createMapType: (apiKey) => new Promise((resolve, reject) => {
                        // uses the WMS service because this isn't available in WMTS
                        // the layer is DigitalGlobe:ImageryFootprint

                        try {
                            let params = {
                                connectid: apiKey,
                                SRS: "EPSG:4326",
                                LAYERS: "DigitalGlobe:ImageryFootprint",
                                FORMAT: "image/png",
                                STYLES: "",
                                cql_filter:"ageDays<90",
                                DPI: 72,
                                MAP_RESOLUTION: 72,
                                FORMAT_OPTIONS: "dpi:72",
                            }

                            let url = `https://securewatch.digitalglobe.com/mapservice/wmsaccess`
                            let minZoom = 0
                            let maxZoom = 20
                            if(this.map.getZoom() < minZoom) {
                                this.map.setZoom(minZoom)
                            }
                            resolve(new WMSMapType(new this.google.maps.Size(1024, 1024), url, minZoom, maxZoom, params, this.google, this.map));
                        } catch (err) {
                            console.error(err)
                            reject(err)
                        }
                    })
                }
            },
            overlayMap: null,
            orgConfig: null,
            activeMapType: null
        };
        return ddata;
    },
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        orgCustomisations: {
            type: Object,
            required: true
        },
    },
    mounted() {
        const basemapDropdown = this.$refs.basemapDropdown;
        this.map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(
            basemapDropdown
        );

        functions.getBasemaps().then(res => {
            // returns object of basemap keys and api keys
            const basemapsApiKeys = res.data;

            for (let basemapName in basemapsApiKeys) {
                const basemapObject = this.basemaps[basemapName] || this.overlay_basemaps[basemapName] || null;

                if(!basemapObject) {
                    console.warn(`Basemap ${basemapName} is not available`)
                    continue;
                }

                basemapObject.key = basemapsApiKeys[basemapName];
                basemapObject.display = true;

                console.info(`Basemap ${basemapName} is available`)
            }
        }).catch(err => {
            console.warn("There was an error getting basemaps, only defaults will be available")
            console.error(err)
        })
    },
    created() {
        this.subscription = AuthService.loggedUser$.subscribe(function(user) {
            this.user = user;
            if (!user || !user.uid) {
                return
            }
            ConfigService.getOrgConfig(this.user.orgId).then(function(config){
                this.orgConfig = config;
            }.bind(this));
        }.bind(this));
        if (this.defaultBasemap) {
            this.basemap = this.defaultBasemap;
        }
        if (this.roadmapName) {
            this.basemaps["roadmap"].basemapName = this.roadmapName;
        }
    },
    methods:{
        async changeBasemap(basemap) {
            // checks for custom basemap, register it and change to it - assumes custom basemap is promise based
            if(basemap.isCustom){
                // createMapType is an async request so use await
                const basemapApiKey = basemap.key || "";
                try {
                    this.activeMapType = await basemap.createMapType(basemapApiKey, this.shownBasemapOptions);
                    // adds basemap to registry
                    this.map.mapTypes.set(basemap.basemapName, this.activeMapType);
                    this.map.setMapTypeId(basemap.basemapName)
                } catch(err){
                    console.error(`An error occurred in changeBasemap, called with basemap ${basemap.basemapName}: ${err}`);
                }
            } else {
                this.map.setMapTypeId(basemap.basemapName)
            }
        },
        async addOverlayMap(basemap) {
            // change basemap to the required basemap by the overlay
            await this.changeBasemap(this.basemaps[basemap.defaultBasemap])

            // the check for isCustom is a bit redundant, if the basemap
            // is an overlay, then it's creating a tileOverlay normally
            // meaning that it has to implement a customMapType
            // TODO consider refactoring this, as it's confusing, we need to make
            // a clear distinction between basemaps and overlays that sit on top of
            // basemaps
            // consider implementing a default method for adding normal ImageMapTypes
            if(basemap.isCustom){
                // createMapType is an async request so use await
                const basemapApiKey = basemap.key || "";
                try {
                    this.activeMapType = await basemap.createMapType(basemapApiKey, this.shownBasemapOptions);       
                    this.overlayMap = this.activeMapType;
                    this.$store.commit('maplayers/setBasemapLayer', this.overlayMap) ;
                } catch(err){
                    console.error(`An error occurred in addOverlayMap, called with basemap ${basemap.basemapName}: ${err}`);
                }
            } else {
                console.error("implementing an overlay basemap, but the isCustom flag is missing, this will not set an overlay");
                this.$store.commit('maplayers/setBasemapLayer', this.overlayMap);
            }
        },
        removeOverlayMap() {
            this.$store.commit('maplayers/clearBasemapLayers')
            this.overlayMap = null;
        },
        async updateBasemap(newBasemap) {
            let basemap = this.basemaps[newBasemap] || this.overlay_basemaps[newBasemap];
            // if an overlay exists remove it
            if(this.overlayMap){
                this.removeOverlayMap();
            }
            
            // if the new basemap replaces the entire map
            if(basemap.isBasemap){
                await this.changeBasemap(basemap);
            } else { // else add it on top of the current basemap
                await this.addOverlayMap(basemap);
            }
        },
        showBasemapInList(basemap) {
            if(!Object.hasOwn(basemap, 'display')) {
                return true;
            }

            return basemap.display;
        },
        reRenderMap() {
            // this is a hack to force the map to re-render
            // it's needed because the basemap is not re-rendered
            // when the basemap is changed
            // TODO find a better way to do this
            const center = this.map.getCenter();
            this.map.setCenter(center);
        }
    },
    watch: {
        // when user changes basemap selection
        async basemap(newBasemap){
            await this.updateBasemap(newBasemap);
        }
    },
    computed: {
        defaultBasemap() {
            if (this.orgCustomisations) {
                return this.orgCustomisations['map.basemap.default'] ? this.orgCustomisations['map.basemap.default'] : 'roadmap';
            } else {
                return 'roadmap';
            }
        },
        roadmapName() {
            if (this.orgCustomisations) {
                return this.orgCustomisations['map.roadmap.name'] ? this.orgCustomisations['map.roadmap.name'] : 'roadmap';
            } else {
                return 'roadmap';
            }
        },
        shownBasemapOptions() {
            return this.basemaps[this.basemap] || this.overlay_basemaps[this.basemap]
        },
        showBasemapHasExtraOptionsComponent() {
            if(!this.shownBasemapOptions) {
                return false
            }
            return typeof this.shownBasemapOptions.additionalOptionsComponent === 'string'
        },
        shownBasemapExtraOptionsComponent() {
            if(!this.showBasemapHasExtraOptionsComponent) {
                return null
            }
            return this.shownBasemapOptions.additionalOptionsComponent
        },
        showMaxarBasemaps() {
            return false;
        },
        basemapsToDisplay() {
            let basemaps = {};
            Object.entries(this.basemaps).forEach(([key, value]) => {
                if(this.showBasemapInList(value)){
                    basemaps[key] = value;
                }
            });

            // add overlay basemaps to the list
            Object.entries(this.overlay_basemaps).forEach(([key, value]) => {
                if(this.showBasemapInList(value)){
                    basemaps[key] = value;
                }
            })
            
            return basemaps;
        }
    }
};
</script>