<template>
    <div class="ebx-reassign-new-class">
        <div class="ebx-reassign-new-class--detail" :class="{'editing': editing}">
            <div class="value-container" >
                <md-field v-if="editing">
                    <label>Value</label>
                    <md-input v-model="changedValue"></md-input>
                    <div class="value-container--error-container">
                        <p class="md-error" v-if="showValidationErrors && v$.changedValue.$invalid && v$.changedValue.required.$invalid">required</p>
                        <p class="md-error" v-if="showValidationErrors && v$.changedValue.$invalid && v$.changedValue.integer.$invalid">must be a number</p>
                        <p class="md-error" v-if="hasDuplicateClassValues">You have duplicate class values.<br>Please make them unique</p>
                    </div>
                </md-field>
                <template v-else>
                    {{ cls.value }}
                </template>              
            </div>
            <div class="color-container">
                <EbxColorPicker 
                v-model="standarisedColor" 
                :disabled="!editing" 
                theme="square"></EbxColorPicker>
                <p class="md-error" v-if="showValidationErrors && v$.changedColor.$invalid">Color is required</p>
            </div>
            <div class="description-container">
                <md-field v-if="editing">
                    <label>Name</label>
                    <md-input v-model="changedDescription"></md-input>
                    <p class="md-error" v-if="showValidationErrors && v$.changedDescription.$invalid">Name is required</p>
                </md-field>
                <template v-else>
                    {{ cls.description }}
                </template>
            </div>
            <div v-if="!editing" class="ebx-reassign-new-class--action">
                <v-menu>
                    <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            @click="handleEdit">
                            <v-list-item-title class="text-body-2">Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            @click="handleRemove">
                            <v-list-item-title class="text-body-2">Remove</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

        </div>
        <div class="ebx-reassign-new-class--dragger">
            <div v-if="editing" class="ebx-reassign-new-class--save-action">
                <div class="md-layout-item">
                    <EBXButton @click="handleClose" theme="secondary">Cancel</EBXButton>
                    <EBXButton @click="handleSave" theme="primary" :disabled="this.v$.$invalid && showValidationErrors && !hasDuplicateClassValues">Save</EBXButton>
                </div>
            </div>
            <div v-else>
                <draggable 
                    tag="ul" 
                    group="reassign_classes" 
                    draggable=".draggable" 
                    handle=".handle" 
                    class="ebx-dragger--container"
                    item-key="value"
                    :model-value="subClassesAsArray"
                    @end="handleSubChange('end', $event)"
                    @add="handleSubChange('add', $event)" 
                    @remove="handleSubChange('remove', $event)"
                    @sort="handleSubChange('sort', $event)"
                >
                    <template #header>
                        <div v-if="!hasSubClasses" class="ebx-dragger--no-options-message">
                            Drag and drop existing classes
                        </div>
                    </template>
                    <template #item="{element}">
                        <ClassDragger 
                            :cls="element" 
                            :all-new-classes="allNewClasses" 
                            :current-class-uuid="cls.uuid"  
                            @move="$emit('move', $event)"
                        />
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import ClassDragger from "./ClassDragger.vue";
import EBXButton from "../EbxComponents/EbxButton.vue";
import { useVuelidate } from '@vuelidate/core'
import { required, integer, minLength } from '@vuelidate/validators'
import EbxColorPicker from "../EbxComponents/EbxColorPicker.vue";

export default {
    components: {
        draggable,
        ClassDragger,
        EBXButton,
        EbxColorPicker
    },
    setup: () => ({ v$: useVuelidate() }),
    props: {
        cls: {
            type: Object,
            default() {
                return {}
            }
        },
        editing: {
            type: Boolean,
            default: false
        },
        bandClasses: {
            type: Array,
            default() {
                return []
            }
        },
        allNewClasses: {
            type: Array,
            default() {
                return []
            }
        }
    },
    emits: [
        'move',
        'save',
        'remove',
        'close',
        'edit'
    ],
    data() {
        return {
            changedValue: '',
            changedDescription: '',
            changedColor: '',
            subClasses: [],
            showValidationErrors: false
        }
    },
    computed: {
        standarisedColor: {
            get() {
                if (this.changedColor === undefined || this.changedColor === null) {
                    return '#000000'
                }
                return this.changedColor
            },
            set(value) {
                this.changedColor = value
            }
        },
        hasSubClasses() {
            return this.subClasses.length > 0
        },
        subClassesAsArray() {
            return this.subClasses.map(cls => {
                return this.bandClasses.find(bandClass => bandClass.value == cls)
            })
        },
        otherNewClasses() {
            return this.allNewClasses.filter(cls => cls.uuid != this.cls.uuid)
        },
        hasDuplicateClassValues() {
            const allClassValues = this.otherNewClasses.map(cls => cls.value)
            return allClassValues.includes(this.changedValue)
        }
    },
    watch: {
        cls: {
            handler(cls) {
                this.changedValue = cls.value
                this.changedDescription = cls.description
                this.changedColor = cls.color
                this.subClasses = cls.classes
            },
            immediate: true,
            deep: true
        }
    },
    methods :{
        handleSubChange(action, event) {
            const id = event.item.getAttribute('id')
            if (action === 'add') {
                this.subClasses.push(id)
                event.item.remove()
            }
            if (action === 'remove') {
                this.subClasses = this.subClasses.filter(cls => cls != id)
            }
            if (action === 'sort') {
                const newSubClasses = []
                for (const child of event.target.children) {
                    const id = child.getAttribute('id')
                    newSubClasses.push(id)
                }
            }
            this.$emit('save', {
                uuid: this.cls.uuid,
                value: this.changedValue,
                description: this.changedDescription,
                color: this.changedColor,
                classes: this.subClasses
            })
        },
        handleClose() {
            const isEmpty = this.changedValue === '' &&  this.changedDescription === '';
            if(isEmpty && this.changedColor === this.cls.color) {
                return this.$emit('remove', this.cls.uuid)
            } else {
                this.$emit('close')
            }
            this.changedColor = null
            this.changedDescription = null
            this.changedValue = null
            this.showValidationErrors = false
        },
        handleSave() {
            this.showValidationErrors = true
            this.v$.$touch()
            if (this.v$.$invalid === false) {
                this.showValidationErrors = false
                this.$emit('save', {
                    uuid: this.cls.uuid,
                    value: this.changedValue,
                    description: this.changedDescription,
                    color: this.changedColor,
                    classes: this.subClasses
                })
            }
        },
        handleEdit(){
            this.$emit('edit', this.cls.uuid)   
        },
        async handleRemove() {
            const message = 'Are you sure you would like to remove ' + this.changedDescription
            const confimed = await this.$confirmModal(message, { okButtonText: 'Remove'})
            if (confimed) {
                this.$emit('remove', this.cls.uuid)
            }
        }
    },
    validations: {
        changedValue: {
            required,
            integer
        },
        changedDescription: {
            required,
            minLength: minLength(1)
        },
        changedColor: {
            required,
            minLength: minLength(7)
        }
    }
}
</script>