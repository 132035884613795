<template>
    <div>
        <md-dialog v-model:md-active="computedModalShown" class="blockly-modals ebx-dialog modal-m">
            <md-dialog-title class="geoprocessing-modal-title">
                <div class="top-actions--container">
                    <div class="title--container">
                        <span>Modify features</span>
                    </div>
                    <div class="close-button">
                        <md-button class="md-primary" @click="computedModalShown = false"><md-icon>close</md-icon></md-button>
                    </div>
                </div>
            </md-dialog-title>
            <div class="md-layout-item">
                <md-field>
                <md-select v-model="geoprocessing_options" name="geoprocessing_options" id="geoprocessing_options" >
                    <md-option value="intersect">Intersect</md-option>
                    <md-option value="difference">Difference</md-option>
                    <md-option value="dissolve">Dissolve</md-option>
                </md-select>
                </md-field>
            </div>
            <div v-if="geoprocessing_options === 'intersect'">
                <p class="ebx-primary"><strong>Preserve</strong> the features in your inputted dataset that intersect with your chosen collection, and remove any geometries outside of the boundaries.</p>
                <img :src="assets.gifs.intersect" alt="Animated intersect GIF" class="explanation-gif"/>
            </div>
            <div v-else-if="geoprocessing_options === 'difference'">
                <p class="ebx-primary"><strong>Remove</strong> any geometries from your inputted dataset that intersect with your chosen collection.</p>
                <img :src="assets.gifs.difference" alt="Animated intersect GIF" class="explanation-gif"/>
            </div>
            <div v-else-if="geoprocessing_options === 'dissolve'">
                <p class="ebx-primary"><strong>Dissolve</strong> all geometries in a feature collection into a single feature.</p>
                <img :src="assets.gifs.dissolve" alt="Animated dissolve GIF" class="explanation-gif"/>
            </div>
            <md-dialog-actions>
                <EbxButton theme="primary" @click="computedModalShown = false">Okay</EbxButton>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import assetsMixin from "@/components/mixins/assetsMixin.js" 
import blocklyModalsMixin from './blocklyModalsMixin.js'
import EbxButton from "../components/EbxComponents/EbxButton.vue";


export default {
    name: 'Geoprocessing',
    mixins: [assetsMixin, blocklyModalsMixin],
    components: { 
        EbxButton 
    },
    props: {
        showModal: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            geoprocessing_options: 'intersect',
        }
    },
    methods: {
        closeModal() {
            this.showModal = false;
        }
    },
    watch: {
        showModal: function (open) {
            if(!open) {
                this.blockyEventCallback('modal-close');
            }
        }
    }
}
</script>