<template>
    <div :class="[currentPage, themeClass]">
        <md-app>
            <md-app-toolbar class="md-primary thin">
                <div class="md-toolbar-section-start">
                    <a @click.prevent="handleLogoNavigation">
                        <img class="logo-toolbar" :src="banner" alt="EarthBlox Banner" />
                    </a>
                    <ProjectHeader v-if="isLoggedIn && $route.name === 'Workflow'" />
                </div>

                <a tabindex="0" v-on:click="skipToContent()" v-on:keyup.enter="skipToContent()" class="skipLink">Skip to main content</a>

                <div class="md-toolbar-section-end">
                    <EbxSwitch v-if="isLoggedIn && showUserModeToggle" theme="dark" off-text="Creator" on-text="Explorer" v-model="userForcedExplorer" />
                    <h3 class="md-title" v-if="isLoggedIn && user.displayName && showUserModeToggle === false">Welcome {{ user.displayName.split(" ")[0] }}</h3>
                    <md-button id="ebx-login-btn" v-if="$router.currentRoute.name === 'Register' || $router.currentRoute.name === 'RegisterSuccess'" class="md-raised" @click="signin">Log in</md-button>
                    <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                            <img 
                                v-if="isLoggedIn && user.photoURL" 
                                :src="user.photoURL" 
                                alt="Avatar" 
                                v-bind="props" />
                            <v-btn 
                                v-else 
                                id="ebx-user-account-button" 
                                icon="mdi-account" 
                                variant="text" 
                                density="comfortable"
                                aria-label="Your account"
                                v-bind="props"></v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-if="isLoggedIn"
                                @click="$router.push('/myaccount/profile')">
                                <v-list-item-title class="text-body-2">My Account</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                id="ebx-sign-out-button"
                                v-if="isLoggedIn"
                                @click="signout">
                                <v-list-item-title class="text-body-2">Sign Out</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-else-if="$router.currentRoute.name === 'Register'"
                                @click="signin">
                                <v-list-item-title class="text-body-2">Sign In</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-if="appVersion">
                                <v-list-item-title class="text-body-2">{{appVersion}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" id="ebx-menu-button" variant="text" density="comfortable" v-bind="props" aria-label="Menu"></v-btn>
                        </template>
                        <v-list id="ebx-user-menu">
                            <v-list-item
                                id="ebx-admin-users-button"
                                v-if="isOrgGroupAdmin || isCustomerSupport"
                                @click="$router.push('/admin/users')">
                                <v-list-item-title class="text-body-2">Admin Panel</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                id="ebx-admin-users-button"
                                v-if="isSuperAdmin"
                                @click="$router.push('/styleguide')">
                                <v-list-item-title class="text-body-2">Styleguide</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                class="text-decoration-none remove-link-green"
                                append-icon="mdi-open-in-new"
                                :href="helpGuide" target="_blank">
                                <v-list-item-title class="text-body-2">Getting started guide</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                append-icon="mdi-open-in-new"
                                class="text-decoration-none remove-link-green"
                                href="https://www.earthblox.io/privacy" target="_blank">
                                <v-list-item-title class="text-body-2">Privacy policy</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </md-app-toolbar>

            <md-app-content tabindex="-1" id="main" ref="mainContent">
                <router-view v-slot="{ Component }">
                    <keep-alive :include="keepAliveComponents">
                        <component :is="Component" />
                    </keep-alive>
                </router-view> 
            </md-app-content>
        </md-app>
        <template v-if="isLoggedIn">
            <EbxDialog :showEbxDialog="showStripeDialog" :cancellable="false" :linkDialog="{
                isLink: false,
                linkText: 'Add payment details',
                linkTarget: stripePortalKey,
                linkEmail: user.email
                    }" :dialogContent="{
                title: 'Your trial has ended',
                body: 'To continue using Earth Blox, add your payment details. '
            }" :dialogClasses="['modal-ms']" />
            <EbxDialog :action="false" :showEbxDialog="showMarketPlaceDialog" :cancellable="false" 
                :linkDialog="{
                    isLink: false,
                    linkText: 'Add payment details',
                    linkTarget: gMarketPlacePortal,
                    linkEmail: user.email
                        }" 
                :dialogContent="{
                    htmlBody: gmpHtmlBody
                }" 
                :dialogClasses="['modal-ms']" />
            <div class="progress-indicators">
                <ProgressIndicator 
                    v-for="operation in userAssetsOperations" 
                    :key="operation.id"
                    :operation="operation" />
            </div>

            <SaveProjectModal 
                v-model="saveProjectPromptIsActive" 
                :form-state="saveProjectFormState" 
                :project-state="saveProjectProjectState" 
                :project-id="projectId"
                :defaults="saveProjectDefaults"
                :assets="saveProjectMoveRequiredAssets"
                :can-share-workflows="canShareWorkflows"
                :can-share-global-workflows="isSuperAdmin"
                @save="saveProjectSave" 
            /> 
            <ProjectSettingsModal
                v-model="saveProjectSettingsIsActive"
                :form-state="saveProjectFormState" 
                :project-state="saveProjectProjectState"
                :default-settings="defaultProjectSettings"
                @apply-settings="handleApplySettings"
            ></ProjectSettingsModal>
        </template>
        <Global :product-update="productUpdate" @show-product-update="handleProductUpdate" />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
*/
// @ is an alias to /src

import { earthbloxCollection } from "@/firebase.js";
import { doc as firestoreDoc, onSnapshot, getDoc, updateDoc } from "firebase/firestore";
import authMixin from '@/components/mixins/authMixin'
import stripeMixin from '@/components/mixins/stripeMixin'
import userAssetsMixin from '@/components/mixins/userAssetsMixin'
import googleMarketPlaceMixin from '@/components/mixins/googleMarketPlaceMixin'
import { ConfigService } from '@/services/config.service';
import { DatasetService } from '@/services/dataset.service';
import { AuthService } from '@/services/auth.service';
import ProjectHeader from '@/components/ProjectSaveLoad/Header.vue';
import SaveProjectModal from '@/components/ProjectSaveLoad/SaveProjectModal.vue'
import ProjectSettingsModal from '@/components/ProjectSaveLoad/ProjectSettingsModal.vue'
import Global from '@/components/Global.vue';
import { saveWorkflowMixin, sharedWorkflowMixin } from '@/components/mixins/projectMixin';
import EbxDialog from '@/components/EbxComponents/EbxDialog.vue'
import EbxSwitch from "./components/EbxComponents/EbxSwitch.vue";
import { isNotNullOrUndefined } from '@/helpers/generalHelperFunctions.js'
import {APP_VERSION_STRING} from './constants/appConstants';
import ProgressIndicator from '@/components/ProgressIndicator.vue';

export default {
    name: "App",
    components: {
        ProgressIndicator,
        SaveProjectModal,
        ProjectHeader,
        Global,
        EbxDialog,
        ProjectSettingsModal,
        EbxSwitch
    },
    mixins: [
        authMixin,
        stripeMixin,
        userAssetsMixin,
        saveWorkflowMixin,
        sharedWorkflowMixin,
        googleMarketPlaceMixin
    ],
    data: () => ({
        banner: null,
        showNavigation: false,
        currentPage: null,
        helpGuide: null,
        userSubscription: null,
        automaticSignout: false,
        productUpdate: {
            show: false,
            template: ''
        },
        productUpdateFBData: {
            show: false,
            template: ''
        },
        hasDismissedProductUpdate: true,
        userDocSubscription: null,
        keepAliveComponents: [],
        gMarketPlacePortal: "https://www.google.com",
        gmpHtmlBody: `You don't have an active subscription, contact <a href="mailto:support@earthblox.io">support@earthblox.io</a>. `,
        appVersion: null,
    }),
    mounted() {
        if(this.automaticSignout){
            document.addEventListener('click', AuthService.resetSessionTimeout());
        }
    },
    created() {
        this.appVersion = APP_VERSION_STRING;
        let self = this;
        onSnapshot(firestoreDoc(earthbloxCollection, "homepage"), doc => {
            if(!doc.exists()) {
                self.helpGuide = "https://www.earthblox.io";
            }

            const data = doc.data() || {};

            self.banner = data.banner || null;
            self.helpGuide = data.helpGuide || "https://www.earthblox.io";
            self.productUpdateFBData = data.productUpdateModal || { show: false, template: '' };
        }, error => {
            console.error('homepage', error);
        });
        this.subscribeToUserChanges();

        this.$store.dispatch('stripe/getStripePortalKey');
        console.info("Vue app created")
    },
    beforeUnmount() {
        // App is never destroyed
    },
    methods: {
        setShowProductUpdate() {
            return new Promise(resolve => {
                this.userDocSubscription = onSnapshot(this.userRef, (userDoc) => {
                    if (userDoc.exists()) {
                        this.hasDismissedProductUpdate = userDoc.data().hasDismissedProductUpdate === true;
                        // show proudtc update modal if user not seen already and it's active in the app
                        if (this.hasDismissedProductUpdate !== true && this.productUpdateFBData.show === true && this.isLoggedIn) {
                            // show product update modal with template
                            this.productUpdate = {
                                show: true,
                                template: this.productUpdateFBData.template
                            }
                        }
                    } else {
                        console.warn('No user doc exists in users collection')
                        this.productUpdate.show = false;
                    }
                    resolve()
                });
            })
        },
        handleProductUpdate(shown) {
            if(shown === 'dismiss') {
                this.productUpdate.show = false;
                getDoc(this.userRef).then((doc) => {
                    if (doc.exists()) {
                        updateDoc(doc.ref, {
                            hasDismissedProductUpdate: true
                        });
                    }
                })
            } else {
                this.productUpdate.show = shown;
            }
        },
        handleSignoutCleanup() {
            if (this.userDocSubscription) {
                this.userDocSubscription()
                this.userDocSubscription = null
            }
            DatasetService.unsubscribeListeners();
            ConfigService.cleanup();
            this.$store.dispatch('userassets/unsubscribeUserOperations');
            this.$store.commit('userdatasets/unregisterSnapshots');
            this.$store.commit('stripe/unregisterAllSubscriptions');
            this.$store.dispatch('packages/unregisterAllSubscriptions');
            this.keepAliveComponents = []
        },
        handleLogoNavigation() {
            if(this.isLoggedIn && this.$router.currentRoute.name !== 'Projects-Workflows') {
                if (this.isExplorer) {
                    this.$router.push('/projects/explore')
                } else {
                    this.$router.push('/projects')
                }
            }
        },
        redirectUserToLoggedInHome() {
            if(this.$router.currentRoute.value.name === 'SignIn' || this.$router.currentRoute.value.name === 'Home') {
                if (this.user.roles.includes("explorer")) {
                     this.$router.push({ path: `/projects/explore/` })
                } else {
                    this.$router.push({ path: `/projects/` })
                }
            }
        },
        async setupUser(user) {
            if (user){
                const isCurrentlyLoggedInState = this.isLoggedIn
                await this.$store.dispatch('auth/setUser', user)
                if(user && user.uid !== null) {
                    this.$store.dispatch('userassets/queryUserOperations', this.user.uid);
                    this.$store.dispatch('packages/unregisterAllSubscription', {key: 'teamProjects'});
                    this.$store.dispatch('global/getAPIVersion');
                    this.keepAliveComponents = ['Workflow']
                    this.redirectUserToLoggedInHome()
                } else {
                    this.productUpdate.show = false;
                    if (isCurrentlyLoggedInState) {
                        this.$router.push({ path: '/' })
                    }
                }
            } else {
                throw new Error('No user found')
            }
        },
        subscribeToUserChanges() {
            this.userSubscription = AuthService.loggedUser$.subscribe(async function(user) {
                AuthService.handleAuthSetup(async () => {
                    await this.setupUser(user)
                    return user
                })
            }.bind(this));
        },
        skipToContent() {
            console.log('skip links clicked')
            this.$refs.mainContent.$el.focus();
        },
        signin() {
            this.$router.push({ name: "SignIn" });
        },
        register() {
            window.location.href = 'https://www.earthblox.io/pricing'; // TODO make a confguration in /earthblox/registration collection
        },
        signout() {
            this.handleSignoutCleanup()
            this.$store.dispatch('auth/signOut')
        },
        dispatchGmpEntitlementCheck() {
            if (this.gmpEntitled === null) {
                return
            } else {
                this.$store.dispatch('googleMarketPlace/queryOrgSubscriptions', this.user.orgId)
            }
        },
        handleApplySettings(form) {
            // check if new settings are different from the existing settings
            const currentProjectSettings = JSON.stringify(this.projectSettings)
            const newProjectSettings = JSON.stringify(form)
            if (currentProjectSettings === newProjectSettings) {
                return
            } else {
                // update project settings
                this.$store.commit('project/setProjectSettings', form)
                // update project state
                this.changeProjectState('changed')
            }
        },
    },
    computed: {
        showUserModeToggle() {
            return this.userVariablesEnabled && this.$route.name === 'Workflow' && this.$store.getters['auth/isCreator'] === true;
        },
        activeRoute() {
            return this.$router.currentRoute.name;
        },
        themeClass() {
            if (this.orgCustomisations) {
                return this.orgCustomisations['client.theme'] ? this.orgCustomisations['client.theme'] : '';
            } else {
                return '';
            }
        },
        showStripeDialog() {
            if (this.isSuperAdmin) {
                // hide paywall if superAdmin user
                return false;
            } else if (this.isStripeActive === true && this.isPayingUser === false) {
                // show paywall if stripe subscription is required and user hasn't got an active stripe subscription
                return true;
            } else if (this.isStripeActive === true && this.isPayingUser === true) {
                // hide paywall if stripe subscription is required and user has got an active stripe subscription
                return false;
            } else {
                // hide paywall otherwise
                return false;
            }
        },
        showMarketPlaceDialog() {
            if (this.gmpEntitled === null || this.isSuperAdmin) {
                // hide paywall if not GMP user OR superAdmin user
                return false;
            } else if (this.isGoogleMarketPlaceEntitlementActive === true && this.gmpEntitled === true) {
                // if user is is GMP and has an active subscription
                return false;
            } else {
                // show paywall otherwise
                return true;
            }
        },
        isPayingUser() {
            if (typeof this.userHasActiveSubscription === 'boolean') {
                return this.userHasActiveSubscription;
            } else {
                return null;
            }
        },
        defaultProjectSettings() {
            // if the projectSettings are an empty object, return the orgCoreConfig
            if (this.projectSettings && Object.keys(this.projectSettings).length > 0) {
                return this.saveProjectDefaults.projectSettings
            } else {
                return this.orgCoreConfig
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                if (isNotNullOrUndefined(to)) { 
                    this.currentPage = to.name?.toLowerCase() + "-page";
                }
            },
        },
        isLoggedIn: {
            immediate: true,
            handler(loggedIn) {
                if(loggedIn === true) {
                    this.dispatchGmpEntitlementCheck()
                    this.setShowProductUpdate()
                }
            }
        }
    }
};
</script>