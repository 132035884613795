<template>
    <div ref="layerView" class="layer-view">
        <div class="button-container">
            <md-button
                @click="toggleLayers"
                v-show="map"
                ref="layersButton"
                class="md-icon-button md-raised layers-button"
            >
                <md-icon>{{ showLayerView ? "chevron_left" : "menu" }}</md-icon>
                <md-tooltip md-direction="left">
                Layers
                </md-tooltip>
            </md-button>
        </div>
        <Transition tag="div" name="slide">
            <div class="layer-control" ref="layerControl" v-show="showLayerView">
                <md-list class="md-dense layer-control--layers">

                    <!-- Map Areas -->
                    <div class="layer-control--section" v-if="showAreas">
                        <md-card class="top-level-card">
                            <ebx-card-expand v-model="expandAOI" class="layer-control--layer">
                                <md-card-header>
                                    <div class="layer-control--layer__lhs-cluster">
                                        <!-- Expander CTA -->
                                        <md-card-expand-trigger>
                                            <md-button class="ebx-button--icon">
                                                <div class="top-level folder-carat">
                                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                                </div>
                                            </md-button>
                                        </md-card-expand-trigger>
                                        <div class="ebx-primary-bold">Areas</div>
                                    </div>
                                    <md-card-actions>
                                        <span class="material-icons ebx-icon" v-if="pinnedAreas">push_pin</span>
                                        <v-menu>
                                            <template v-slot:activator="{ props }">
                                                <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                    :prepend-icon="pinnedAreas ? 'mdi-pin-off-outline' : 'mdi-pin-outline'"
                                                    @click="manageAreasPin">
                                                    <v-list-item-title class="text-body-2" v-if="!pinnedAreas">Pin areas to map</v-list-item-title>
                                                    <v-list-item-title class="text-body-2" v-else>Unpin from map</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    prepend-icon="mdi-delete-outline"
                                                    @click="deleteAreas">
                                                    <v-list-item-title class="text-body-2">Delete all</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </md-card-actions>
                                    
                                </md-card-header>
                                <md-card-expand-content>
                                    <md-card-content>
                                        <md-card v-if="showAreas && showNewStudyArea" class="layer-control--empty-state layer-control--empty-state" >
                                            <div class="ebx-icon">
                                                <img :src="assets.icons.addArea" alt="Add area">
                                            </div>
                                            <p class="ebx-primary">
                                                To get started, add an Area of Interest
                                            </p>
                                            <v-menu>
                                                <template v-slot:activator="{ props }">
                                                    <v-btn variant="text" color="primary" v-bind="props">
                                                        Add area
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        prepend-icon="mdi-pencil-outline"
                                                        @click="addArea(generateAreaName(), study_collection_id, true)">
                                                        <v-list-item-title class="text-body-2">Draw area</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        prepend-icon="mdi-tray-arrow-up"
                                                        @click="$emit('upload-polygon-to-collection', study_collection_id, true)">
                                                        <v-list-item-title class="text-body-2">Upload new feature</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        prepend-icon="mdi-folder-outline"
                                                        @click="$emit('choose-area', study_collection_id)">
                                                        <v-list-item-title class="text-body-2">Choose from Assets</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </md-card>
                                        <md-card v-for="collection in studyCollections" :key="collection.id">
                                            <GoogleMapAreaFeatures
                                                v-if="canShowCollection(collection)"
                                                :map="map"
                                                :google="google"
                                                :collectionId="collection.id"
                                                :currentlySelectedArea="selectedArea"
                                                :areas="areas.filter(area => area.collectionId === collection.id)"
                                                @colour-changed="handleColourChange"
                                                @remove-collection="removeCollection"
                                                @zoom-to-area="positionArea"
                                            />
                                            <GoogleMapAddArea
                                                v-if="canShowCollection(collection)"
                                                :collectionId="collection.id"
                                                @add-new-area="addArea"
                                                @upload-polygon-to-collection="handleOpenPolygonAreaDialog"
                                                @choose-area="handleOpenChooseAreaDialog"
                                            />
                                        </md-card>

                                        <!-- Map Collections -->
                                        <md-card v-if="showCollections" class="sub-level-card">
                                            <ebx-card-expand v-model="expandCollections" class="layer-control--layer">
                                                <md-card-header>
                                                    <div class="layer-control--layer__lhs-cluster">
                                                        <!-- Expander CTA -->
                                                        <md-card-expand-trigger>
                                                            <md-button class="ebx-button--icon">
                                                                <div class="sub-level folder-carat">
                                                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                                                </div>
                                                            </md-button>
                                                        </md-card-expand-trigger>
                                                        <div class="ebx-primary-bold">Collections</div>
                                                    </div>
                                                </md-card-header>
                                                <md-card-expand-content>
                                                    <md-card-content>
                                                        <draggable v-model="collectionsWithoutStudyArea" item-key="id" draggable=".layer-control--layer--draggable">
                                                            <template #item="{element}">
                                                                <md-list-item class="layer-control--layer layer-control--layer--draggable">
                                                                    <GoogleMapAreaCollection 
                                                                        :map="map"
                                                                        :google="google"
                                                                        :collection="element"
                                                                        :areas="areas"
                                                                        :selectedArea="selectedArea"
                                                                        @removed-collection="removeCollection"
                                                                        @add-new-area="addArea"
                                                                        @upload-polygon-to-collection="handleOpenPolygonAreaDialog"
                                                                        @choose-area="handleOpenChooseAreaDialog"
                                                                        @colour-changed="handleColourChange"
                                                                        @rename-collection="handleRenameCollection"
                                                                        @rename-collection-cancel="cancelRenameCollection"
                                                                        @set-temp-collection-values="setTempCollectionValues"
                                                                        @export-collection="exportCollection"
                                                                    />
                                                                </md-list-item>
                                                            </template>
                                                        </draggable>
                                                        <div class="add-area" role="button" title="Add new collection" @click="addCollection();">
                                                            <v-btn prepend-icon="mdi-plus" class="add-area--action" variant="text">
                                                                Add collection
                                                            </v-btn>
                                                        </div>
                                                    </md-card-content>
                                                </md-card-expand-content>
                                            </ebx-card-expand>
                                        </md-card>
                                    </md-card-content>
                                </md-card-expand-content>
                            </ebx-card-expand>
                        </md-card>
                    </div>
                    <!-- Map Layers -->
                    <div class="layer-control--section map-layers" :class="{'map-layers-only':onlyShowingLayers}">
                        <md-card class="top-level-card">
                            <ebx-card-expand v-model="expandResults" class="layer-control--layer">
                                <md-card-header>
                                    <div class="layer-control--layer__lhs-cluster">
                                        <!-- Expander CTA -->
                                        <md-card-expand-trigger>
                                            <md-button class="ebx-button--icon">
                                                <div class="top-level folder-carat">
                                                    <img :src="assets.icons.carat" alt="Toggle expanded section">
                                                </div>
                                            </md-button>
                                        </md-card-expand-trigger>
                                        <div class="ebx-primary-bold">Layers</div>
                                    </div>
                                </md-card-header>
                                <md-card-expand-content>
                                    <md-card-content>
                                        <div class="layer-control--empty-state layer-control--empty-state__results" v-if="draggingMapLayers.length === 0">
                                            <div class="layer-icon"></div>
                                            <p class="ebx-primary">
                                                Map layer outputs from workflows will appear here 
                                            </p>
                                        </div>
                                        <draggable v-model="draggingMapLayers" item-key="mapLayerID" draggable=".layer-control--layer--draggable" handle=".layer-control--layer-items">
                                            <template #item="{element}">
                                                <md-list-item class="layer-control--layer layer-control--layer--draggable">
                                                    <LayerResult
                                                        :mapLayer="element"
                                                        :google="google"
                                                        :map="map"
                                                        :map-layer-tiles-loading="mapLayerTilesLoading"
                                                    />
                                                </md-list-item> 
                                            </template>
                                        </draggable>
                                    </md-card-content>
                                </md-card-expand-content>
                            </ebx-card-expand>
                        </md-card>
                    </div> 

                </md-list>
                <div class="layer-control--resizer" ref="resizer" @mousedown="resizeLayersView">
                </div>
            </div>
        </Transition>
        <ColourPickerDialog ref="colourPickerDialog" @colour-selected="onColourChanged" />
    </div>
</template>

<script>
/*
 * ---------------------------------------------------------------------------
 * COMMERCIAL IN CONFIDENCE
 *
 * (c) Copyright Quosient Ltd. All Rights Reserved.
 *
 * See LICENSE.txt in the repository root.
 * ---------------------------------------------------------------------------
 */
import draggable from "vuedraggable";
import { AreaService }  from '@/services/area.service';
import LayerResult from '@/components/ResultMap/LayerResult.vue'
import GoogleMapAreaCollection from '@/components/ResultMap/GoogleMapAreaCollection.vue'
import EbxCardExpand from '@/components/EbxComponents/EbxCardExpand.vue'
import GoogleMapAreaFeatures from '@/components/ResultMap/GoogleMapAreaFeatures.vue'
import GoogleMapAddArea from '@/components/ResultMap/GoogleMapAddArea.vue'
import {ShapeMapType}  from '@/components/ResultMap/CustomMapTypes/ShapeMapType.js';

import ColourPickerDialog from "@/components/ColourPickerDialog.vue";
import { v4 as uuidv4 } from 'uuid';
import { MAP_STUDY_AREA_COLLECTION_ID } from '../../constants/nextGenConstants';
import { findIndex, without, isEqual } from 'lodash'
import { globalEventBus } from '@/eventbus.js'
import assetsMixin from "@/components/mixins/assetsMixin.js" 

export default {
    mixins: [assetsMixin],
    components: {
        draggable,
        LayerResult,
        GoogleMapAreaCollection,
        ColourPickerDialog,
        EbxCardExpand,
        GoogleMapAreaFeatures,
        GoogleMapAddArea
    },
    props: {
        google: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
        mapBBox: {
            required: true,
        },
        mapLayerTilesLoading: {
            type: Object,
            required: true
        },
        showAreas: {
            type: Boolean,
            required: false,
            default: true
        },
        showCollections: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    emits: [
        'upload-polygon-to-collection',
        'choose-area',
        'added-new-polygon',
        'added-new-marker',
        'removed-collection'
    ],
    data() {
        return {
            showLayerView: true,
            isMapImage: true,
            selectedArea: {},
            areas: [],
            collections: [],
            study_collection_id: MAP_STUDY_AREA_COLLECTION_ID,
            showGeometriesDropdown: false,
            lastCollectionCounter: 0,
            collectionTempRenameValues: {},
            collectionIdsRenaming: [],
            nAreas: 0,
            expandAOI: true,
            expandCollections: false,
            expandResults: false,
            lastValidBbox: null,
            pinnedAreas: false,
        };
    },
    methods: {
        positionArea(area) {
            var bbox;
            try {
                bbox = AreaService.getAreaBoundingBox(area.id);
            } catch {
                console.error("No shapes to find bounding box");
                return;
            }
        
            const bounds = new this.google.maps.LatLngBounds(
                new this.google.maps.LatLng({lng: bbox['SW'][0], lat: bbox['SW'][1]}),
                new this.google.maps.LatLng({lng: bbox['NE'][0], lat: bbox['NE'][1]})
            );

            this.map.fitBounds(bounds);
        },
        canShowCollection(collection) {
            if(collection.id === MAP_STUDY_AREA_COLLECTION_ID) {
                return this.showAreas
            }
            return this.showCollections
        },
        async deleteAreas() {
            const message = `Are you sure you want to delete all areas and collections from the map?`
            const confirmedDelete = await this.$confirmModal(message ,{
                okButtonText: 'Delete',
                hideTitle: true,
                hideCloseButton: true,
            })
            if (confirmedDelete === false) {
                return
            } else {
                // clear areas
                AreaService.clearMap()
                if (this.pinnedAreas) {
                    this.pinnedAreas = false;
                    AreaService.setHasPinnedAreas(this.pinnedAreas);
                }
            }
        },
        manageAreasPin() {
            this.pinnedAreas = !this.pinnedAreas;
            AreaService.setHasPinnedAreas(this.pinnedAreas);
        },
        resizeLayersView(event) {
            event.preventDefault();
            document.onmousemove = event => {
                event.preventDefault();
                const layerControlWidth = this.$refs.layerControl.offsetWidth;
                const resizerWidth = this.$refs.resizer.offsetWidth;

                const newX = event.offsetX;
                const diffX = newX - layerControlWidth;
                const totalLayerControlWidth = layerControlWidth + diffX + resizerWidth;
                if (newX !== 0 && (totalLayerControlWidth <= 500 && totalLayerControlWidth >= 220)) {
                    this.$refs.layerControl.style.width = layerControlWidth + diffX +  'px';
                }
            }
            document.onmouseup = () => {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        },
        handleColourChange(model) {
            this.$refs.colourPickerDialog.showDialog(model);
        },
        handleOpenPolygonAreaDialog(collectionId, addToMap){
            this.$emit('upload-polygon-to-collection', collectionId, addToMap)
        },
        handleOpenChooseAreaDialog(collectionId) {
            this.$emit('choose-area', collectionId)
        },
        toggleLayers() {
            this.showLayerView = !this.showLayerView;
        },
        removeLayer(layerId) {
            this.$store.commit('maplayers/removeLayer', layerId)
            this.$store.commit('maplayers/removeLegendById', layerId)
        },
        setTempCollectionValues(collection, newName) {
            this.collectionTempRenameValues[collection.id] = newName
        },
        exportCollection(collection) {
            console.log("export collection in GoogleMapLayersView - collection id:" + collection.id)
            let areas = AreaService.getAreasForCollection(collection.id);
          
            
             // create a new google maps Data object, which we'll then use to create geojson
             let polygonData = new this.google.maps.Data();

            areas.forEach(area => {
                // get the polys for each area
                let polygons = AreaService.getShapesForArea(area.id);
                // get only polgyons that have type UserDrawn, and get the overlay
                let userDrawnPolygons = polygons.filter(polygon => polygon.type === 'UserDrawn');
                userDrawnPolygons = userDrawnPolygons.map(polygon => polygon.overlay);
               
                // create a feature for each poly
                userDrawnPolygons.forEach((polygon) => { 
                        polygonData = this.addFeatureToData(polygonData, area, polygon)
                 })
            
            });
            // convert the Google.maps.Data object in geoJSON and click download
            polygonData.toGeoJson(function(json) {
                let string = JSON.stringify(json, null, 2);
                let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(string);
                let downloadAnchorNode = document.createElement('a');
                
                downloadAnchorNode.setAttribute("href", dataStr);
                let fileName = prompt('Please name the file being downloaded:')
                if(!fileName || fileName === '') {
                    fileName = 'polygons';
                }

                downloadAnchorNode.setAttribute("download", `${fileName}.geojson`);
                document.body.appendChild(downloadAnchorNode);
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })
     
            
        },
        // TODO:maybe this function should move to Area Service as similar code dottted around code base
        // this function coverts the polygon shape into a google.maps.Data.Feature object
        // and adds it to the google.maps.Data collection
        // capturing the name of the area which the polygon belongs to as a feature property

        addFeatureToData(polyData, area, polygon)
        {
           
            var coords;
                // rectangle
                try {
                    let bounds = polygon.getBounds();
                    let NE = bounds.getNorthEast();
                    let SW = bounds.getSouthWest();
                    let NW = new this.google.maps.LatLng(NE.lat(), SW.lng());
                    let SE = new this.google.maps.LatLng(SW.lat(), NE.lng());
                
                    coords = [NE, NW, SW, SE];
                } catch { // polygon
                    coords = polygon.getPath().getArray();
                }

            
                // add to data object
                polyData.add(new this.google.maps.Data.Feature({
                    geometry: new this.google.maps.Data.Polygon([coords]),
                    properties: {"name": area.name}
                }))
                return polyData ;

        },
        handleRenameCollection(collection) {
            const collectionIdx = findIndex(this.collections, {id: collection.id})
            if(this.collectionTempRenameValues[collection.id] === undefined) {
                return
            }
            const newValue = this.collectionTempRenameValues[collection.id]
            if(collectionIdx !== undefined && newValue.length > 0 && collection.name !== newValue) {
                const clonedCollection = Object.assign({}, collection)
                clonedCollection.name = newValue
                this.collections[collectionIdx] = clonedCollection
                AreaService.updateCollection(clonedCollection)
                this.collectionIdsRenaming = without(this.collectionIdsRenaming, collection.id)
            }
            delete this.collectionTempRenameValues[collection.id]
        },
        cancelRenameCollection(collection) {
            this.collectionIdsRenaming = without(this.collectionIdsRenaming, collection.id)
            delete this.collectionTempRenameValues[collection.id]
        },
        removeCollection(collection) {
            console.log('removing collection ', collection)
            
            AreaService.removeCollection(collection)

            for (let i=0; i < this.collections.length; i++){
                if (this.collections[i].id === collection.id){
                    this.collections.splice(i, 1);
                }
            }

            this.$emit('removed-collection', collection.id)
        },
        addCollection(collectionName, collectionId) {
            console.log('adding new collection')

            let newCollection = {}

            newCollection.name = collectionName || this.generateCollectionName()
            newCollection.id = collectionId || uuidv4()
            newCollection.clippedAsset = false
            newCollection.clippedBy = ''

            AreaService.addCollection(newCollection);
            AreaService.setSelectedCollection(newCollection);
        },
        getAreaByName(areaName, collectionId) {
            for(let i =0; i < this.areas.length; i++) {
                if(this.areas[i].collectionId === collectionId && this.areas[i].name == areaName) {
                    return this.areas[i]
                }
            }
            return null
        },
        addArea(areaName, collectionId, showRectangleTool, areaColour, limitedArea=false) {
            let newArea = {};

            // area number for colour should not be based on the number in the name
            // as the user can rename the area

            let newAreaNumber = this.nAreas + 1;
            let colours = this.$refs.colourPickerDialog.colours;
            let colour = colours[((newAreaNumber-1) * 3) % colours.length];

            // set up the newArea object
            newArea.id = uuidv4()
            if (areaColour) {
                newArea.colour = areaColour
            } else {
                newArea.colour = colour
            }
            if (limitedArea) {
                newArea.limitedArea = limitedArea
            }
            newArea.name = [null, undefined].indexOf(areaName) < 0 && String(areaName).length > 0 ? String(areaName) : this.generateAreaName()
            if(collectionId === null || collectionId === undefined) {
                const selectedCollection = AreaService.getSelectedCollection()
                if(selectedCollection && selectedCollection.id) {
                    collectionId = selectedCollection.id
                }else {
                    collectionId = this.study_collection_id
                }
            }

            if(collectionId === this.study_collection_id) {
                const studyCollectionIdx = AreaService.findCollectionIndex(this.study_collection_id)
                if(studyCollectionIdx === -1) {
                    AreaService.addStudyAreaIfNotExists()
                }
            }
            
            if (collectionId) {
                // specify collection if given
                newArea.collectionId = collectionId
            } else {
                let newCollectionId = uuidv4()
                // add new collection for this area
                newArea.collectionId = newCollectionId
                this.addCollection(this.generateCollectionName(), newCollectionId)
            }

            this.areas.push(newArea);
            this.nAreas++;

            AreaService.addArea(newArea);
            AreaService.setSelectedArea(newArea);
            this.selectedArea = newArea;
            if (showRectangleTool == true){
                this.$store.commit('maplayers/setDrawingModeMode', 'rectangle')
                this.$store.commit('maplayers/setDrawingModeEnabled', true)
            }
            return newArea;
        },
        generateAreaName() {
            return `Area ${this.getNewAreaNumber()}`
        },
        getNewAreaNumber() {
            const highestAreaNumber = this.areas.reduce((c, area) => {
                const matches = area.name.match(/^Area ([0-9]+)$/);
                if (matches && matches.length === 2) {
                    let matchedNumber = parseInt(matches[1]);
                    return c < matchedNumber ? matchedNumber : c;
                }
                return c;
            }, 0);
            
            return parseInt(highestAreaNumber) + 1
        },
        onColourChanged(changedArea) {
            console.log("colour changed", changedArea);
            let area = {
                id: changedArea.id,
                colour: changedArea.colour,
                collectionId: changedArea.collectionId,
                name: changedArea.name
            }
            AreaService.updateArea(area)   
        },
        toggleGeometriesDropdown() {
            this.showGeometriesDropdown = !this.showGeometriesDropdown
        },
        generateCollectionName() {
            return `Collection ${this.getNewCollectionNumber()}`
        },
        getNewCollectionNumber() {
            this.lastCollectionCounter = this.lastCollectionCounter + 1
            return this.lastCollectionCounter
        },
    },
    mounted() {
        const layerView = this.$refs.layerView;
        this.map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(
            layerView
        );

        // on drag remove last valid bbox
        this.map.addListener('dragend', () => {
            this.lastValidBbox = null
        })

        this.pinnedAreasSubscription = AreaService.pinnedAreasUpdate$.subscribe((area) => {
            this.pinnedAreas = area
        })

        this.collectionChangeSubscription = AreaService.collectionChange$.subscribe(function() {
            this.collections = AreaService.getCollections(true)
        }.bind(this));

        this.areaChangeSubscription = AreaService.areaChange$.subscribe(function() {
            this.areas = AreaService.getCustomAreas()
        }.bind(this));

        this.shapeUpdatedSubscription = AreaService.shapeUpdated$.subscribe(function(shapeEvent) {
            let shapeArea = AreaService.getUserAreaById(shapeEvent.areaId)
            let shape = AreaService.getShapeById(shapeEvent.shapeId)

            if (shape && shape.coordinates) {
                if (shape.overlay) {
                    return;
                }
                
                if (shape.geometryType === "Polygon") {
                    let coords = []
                    shape.coordinates.forEach(coordSet => {
                        coords.push({
                            lat: parseFloat(coordSet[1]),
                            lng: parseFloat(coordSet[0])
                        })
                    })

                    const newPolygon = new this.google.maps.Polygon({
                        paths: coords,
                        fillOpacity: 0,
                        strokeWeight: 4,
                        strokeColor: shapeArea.colour,
                        shapeId: shapeEvent.shapeId,
                        areaId: shapeEvent.areaId,
                        clickable: false
                    })
                    shape.overlay = newPolygon
                    newPolygon.setMap(this.map);
                    globalEventBus.$emit('added-new-polygon', newPolygon)
                } else if (shape.geometryType === "Point") {
                    const lng = parseFloat(shape.coordinates[0])
                    const lat = parseFloat(shape.coordinates[1])

                    const newMarker = new this.google.maps.Marker({
                        position: {lat, lng},
                        map: this.map,
                        shapeId: shapeEvent.shapeId,
                        areaId: shapeEvent.areaId,
                        icon: {
                            path: this.google.maps.SymbolPath.CIRCLE, 
                            scale: 5, 
                            fillColor: shapeArea.colour,
                            fillOpacity: 1, 
                            strokeColor: 'white', 
                            strokeWeight: 2,
                        },
                        clickable: false
                    })
                    shape.overlay = newMarker;
                    newMarker.setMap(this.map);
                    globalEventBus.$emit('added-new-marker', newMarker)
                }

                AreaService.updateShape(shape);                
            }
        }.bind(this));

        this.selectedAreaSubscription = AreaService.selectedAreaUpdate$.subscribe(function() {
            this.selectedArea = AreaService.getSelectedArea()
        }.bind(this));
        /**
         * Functionality imported from old GoogleMapAreasView
         */
        this.clearAreasSubscription = AreaService.clearAreas$.subscribe(function() {
            this.areas = [];
        }.bind(this));
        this.clearCollectionsSubscription = AreaService.clearCollections$.subscribe(function() {
            this.collections = [];
            this.lastCollectionCounter = 0;
        }.bind(this));

        this.userUploadedShapesSubscription = AreaService.userUploadedShape$.subscribe((shape) => {
            console.log("user uploaded shape event", shape);
            // when a user uploads a shape, we need to check if it has an area to be assigned to
            // if it doesn't, we need to create a new area and assign it to that
            // we then

            let selectedCollection = AreaService.getSelectedCollection();
            let selectedCollectionId = selectedCollection === null ? this.study_collection_id : selectedCollection.id;
            let foundArea;
            if (shape.areaID) {
                const shapeArea = AreaService.getUserAreaById(shape.areaID)
                if (shapeArea) {
                    if (shapeArea.collectionId) {
                        const shapeCollection = AreaService.getCollectionById(shapeArea.collectionId)
                        if (shapeCollection) {
                            selectedCollection = shapeCollection
                            selectedCollectionId = shapeCollection.id
                        }
                    }
                    foundArea = shapeArea
                }
            }

            if(!foundArea) {
                if(shape.meta && shape.meta.areaName) {
                    let foundArea = this.getAreaByName(shape.meta.areaName, selectedCollectionId)
                    if(foundArea === null) {
                        foundArea = this.getAreaByName(shape.meta.className, selectedCollectionId)
                    }
                    if(foundArea === null) {
                        foundArea = this.addArea(shape.meta.areaName, selectedCollectionId,false)
                    }
                    shape.areaID = foundArea.id
                }

                if(!shape.areaID) {
                    // creates a new area

                    if(shape.isFeatureView && shape.meta) {
                        shape.meta.forEach((meta) => {
                            this.addArea(meta.areaName,null,false,meta.areaColour,shape.isFeatureView)
                        })
                    } else if (!Object.keys(shape).includes('isFeatureView')) {
                        this.addArea()
                    }
                    
                    // gets area id and updates shape to have that id
                    shape.areaID = this.selectedArea.id;
                    AreaService.updateShape(shape);
                }

            }
            
            
            // gets colour shape should be
            let shapeColour = AreaService.getColour(shape.areaID);

            // add shape to mapx
            let shapeMap = new ShapeMapType(shape.mapURL, shape.id, this.map, this.google, shapeColour);
            shape.overlay = shapeMap.overlay;

            shapeMap.setRemoveHandler(shapeMap => {
                this.$store.commit('maplayers/removeAssetLayer', shapeMap.id)
            })
            this.$store.commit('maplayers/appendAssetLayer', shapeMap)
            

            // shift map to bounding box of shape
            if(shape.bbox) {
                let bounds = new this.google.maps.LatLngBounds(
                new this.google.maps.LatLng({lng: shape.bbox['SW'][0], lat: shape.bbox['SW'][1]}),
                new this.google.maps.LatLng({lng: shape.bbox['NE'][0], lat: shape.bbox['NE'][1]})
                );

                this.map.fitBounds(bounds)
            }
            // if shape has a callback, call it after we've added the shape to the map
            if(shape.meta && typeof shape.meta.callback === 'function') {
                const callback = shape.meta.callback;
                delete shape.meta.callback;
                callback(shape)
            }
        })
        // Show layers toggle if no areas or collections are shown
        if (this.onlyShowingLayers) {
            this.expandResults = true
        }

        globalEventBus.$on('zoom-to-area', (area) => {
            this.positionArea(area);
        })
    
        globalEventBus.$on('add-area-to-map', this.addArea)
    },
    watch: {
        draggingMapLayers: {
            handler: function(draggingMapLayers) {
                if (draggingMapLayers.length > 0 && !this.expandResults) {
                    this.expandResults = true;
                } else if (this.expandResults) {
                    this.expandResults = true;
                } else {
                    this.expandResults = false;
                }
            },
            deep: true 
        },
    
        mapBBox: {
            immediate: true,
            handler(mapBBox) {
                const isBboxValid = (mapBBox) => {
                    const validatorFunc = (pair) => {
                        return pair.every((point) => isFinite(point));
                    };

                    return mapBBox.every(validatorFunc);
                };

                if (mapBBox === null) {
                    return;
                }

                if (!isBboxValid(mapBBox)) {
                    return;
                }

                // check if last validBbox is the same as current using _
                if (isEqual(this.lastValidBbox, mapBBox)) {
                    return;
                }

                var bounds = new this.google.maps.LatLngBounds(
                    new this.google.maps.LatLng(mapBBox[0][1], mapBBox[0][0]),
                    new this.google.maps.LatLng(mapBBox[1][1], mapBBox[1][0])
                );

                this.map.fitBounds(bounds, 0);
                this.lastValidBbox = mapBBox;
            },
        },
        selectedArea(selectedArea) {
            AreaService.setSelectedArea(selectedArea);
        }
    },
    computed: {
        draggingMapLayers: { 
            get() {
                return this.mapLayers.slice(0).reverse()
            },
            set(mapLayers) {
                const filteredLayers = mapLayers.filter(layer => layer !== undefined)
                this.$store.commit('maplayers/setMapLayers',filteredLayers.reverse())
            }
        },
        mapLayers: {
            get() {
                return this.$store.state.maplayers.mapLayers
            },
            set(mapLayers) {
                this.$store.commit('maplayers/setMapLayers', mapLayers)
            }
        },
        legends() {
            return this.$store.state.maplayers.legends
        },
        studyCollections() {
            return this.collections.filter(c => c.id === this.study_collection_id)
        },
        collectionsWithoutStudyArea: {
            get() {
                // filter out the study_collection by id from the collections array and return the array
                return this.collections.filter(c => c.id !== this.study_collection_id)
            },
            set(newCollectionOrder) {
                const studyAreas = this.collections.filter(c => c.id === this.study_collection_id)
                const allNewOrder = [...studyAreas, ...newCollectionOrder]
                this.collections = allNewOrder
            }
        },
        showNewStudyArea() {
            return this.studyCollections.length === 0
        },
        orderedCollections() {
            return this.studyCollections.concat(this.collections.filter(c => c.id !== this.study_collection_id))
        },
        onlyShowingLayers() {
            return this.showAreas === false && this.showCollections === false
        }
    },
    beforeUnmount() {
        this.clearAreasSubscription.unsubscribe();
        this.userUploadedShapesSubscription.unsubscribe();
        this.clearCollectionsSubscription.unsubscribe();
        this.areaChangeSubscription.unsubscribe();
        this.collectionChangeSubscription.unsubscribe();
        this.shapeUpdatedSubscription.unsubscribe();
        this.selectedAreaSubscription.unsubscribe(),
        this.pinnedAreasSubscription.unsubscribe()
    }
};
</script>