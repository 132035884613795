<template>
    <div class="variable-detail">
        <div class="variable-detail__collapsed" v-if="variable && !isEditing">
            <div class="variable-detail--container">
                <div class="variable-detail--content">
                    <!-- <div class="drag-handle">
                        <span class="material-icons-outlined ebx-icon">drag_handle</span>
                    </div> -->
                    <div class="variable-detail--info">
                        <div class="variable-detail--type">
                            <p class="ebx-tertiary">{{variable.type}}</p>
                        </div>
                        <div class="variable-detail--title">
                            <p class="ebx-primary-bold">{{variable.title}}</p>
                        </div>
                    </div>
                </div>
                <div class="variable-detail--actions">
                    <v-menu location="right">
                        <template v-slot:activator="{ props }">
                            <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                prepend-icon="mdi-pencil-outline"
                                @click="handleVariableEdit">
                                <v-list-item-title class="text-body-2">Edit</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                prepend-icon="mdi-delete-outline"
                                @click="handleVariableDelete">
                                <v-list-item-title class="text-body-2">Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
        <div class="variable-detail__expanded" v-else>
            <div class="variable-detail--container">
                <div class="variable-detail--title">
                    <p class="ebx-header-4">New {{ varType }} variable</p>
                </div>
                <div class="variable-detail--field">
                    <md-field md-inline>
                        <label>Variable name</label>
                        <md-input v-model="varName"></md-input>
                    </md-field>
                </div>
                <div class="variable-detail--field variable-detail--field__subtext">
                    <md-field md-inline>
                        <label>Description</label>
                        <md-input v-model="varDesc"></md-input>
                        <span v-if="descHelperText" class="md-helper-text">{{descHelperText}}</span>
                    </md-field>
                </div>
                <div class="variable-detail--variable-type">
                    <!-- add a slot for variable type so we can add custom markup depending on variable type -->
                    <slot></slot>
                </div>
                <div class="variable-detail--actions">
                    <button v-if="isComplete" class="ebx-button ebx-button--primary" @click="handleVariableSave">Save</button>
                    <button v-else class="ebx-button ebx-button--primary__disabled">Save</button>
                    <button class="ebx-button ebx-button--secondary" @click="handleCancelEditing">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VariablesService } from '@/services/variable.service';

export default {
    name: 'VariableDetailComponent',
    props: {
        descHelperText: {
            type: String,
            default: '',
            required: false
        },
        hasValue: {
            type: Boolean,
            required: true,
            default: false
        },
        isEditing: {
            type: Boolean,
            required: false
        },
        variable: {
            type: Object,
            required: false
        },
        varType: {
            type: String,
            required: true
        },
    },
    emits: [
        'cancel-editing',
        'changed-description',
        'changed-name',
        'edit-variable',
        'save-variable'
    ],
    data() {
        return {
            varNameCurrent: '',
            varDescCurrent: '',
        }
    },
    computed: {
        isComplete() {
            return this.varName.length > 0 && this.hasValue;
        }, 
        isVariableUsed() {
            if (this.variable.blockIds === undefined) {
                return false;
            } else {
                return true
            }
        },
        varName: {
            get() {
                return this.varNameCurrent;
            },
            set(value) {
                this.varNameCurrent = value;
                this.$emit('changed-name', value)
            }
        },
        varDesc: {
            get() {
                return this.varDescCurrent;
            },
            set(value) {
                this.varDescCurrent = value;
                this.$emit('changed-description', value)
            }
        }
    },
    created() {
        if (this.variable) {
            this.varName = this.variable.title || '';
            this.varDesc = this.variable.description || '';
        }
    },
    methods: {
        handleCancelEditing() {
            this.$emit('cancel-editing');
        },
        async handleVariableDelete() {
            if (this.isVariableUsed) {
                const message = 'This variable is used in one or more of the project workflows, are you sure you want to delete it?';
                const confirmedLeave = await this.$confirmModal(message ,{
                    hideTitle: true,
                    okButtonText: 'Delete',
                })
                if (confirmedLeave === false) {
                    return
                }
                this.variableDelete();
            } else { 
                this.variableDelete();
            }
        },
        variableDelete() { 
            if(VariablesService.getVariableById(this.variable.id) !== undefined) {
                VariablesService.removeVariable(this.variable.id);
            }
       },
        handleVariableEdit() {
            this.$emit('edit-variable');
        },
        handleVariableSave() {
            if (!this.isComplete) {
                return;
            } 
            const variable = {
                title: this.varName,
                description: this.varDesc,
                type: this.varType
            }
            this.$emit('save-variable', variable);
        },
    }
}
</script>