import { sharedCollection, organisationsCollection, usersCollection } from '@/firebase.js';
import { collection, doc as firestoreDoc, getDoc } from "firebase/firestore";

export const FORM_STATE = {
    DEFAULT: 'default',
    ERROR: 'error',
    SAVING: 'saving',
    SAVED: 'saved'
}

export const PROJECT_MODE = {
    PRIVATE: 'private',
    READONLY: 'readonly',
    EDIT: 'edit',
    TEMPLATE: 'template'
}

export const PROJECT_STATE = {
    NEW: 'new',
    CHANGED: 'changed',
    SAVED: 'saved',
    DUPLICATE: 'duplicate',
    DETAIL_UPDATE: 'detail_update'
}

const state = {
    projectId: null,
    projectState: PROJECT_STATE.NEW,
    projectMode: PROJECT_MODE.PRIVATE,
    projectOwnerUid: null,
    projectName: null,
    projectDescription: null,
    projectLoadedDefinitionAndArea: {},
    projectUpdatedAt: null,
    projectThumbnail: null,
    projectToBeLoaded: false,
    projectChanged: false,
    projectApiAccess: false,
    projectApiVersion: null,
    projectVariables: [],
    projectProvenance: null,
    projectSettings: {},
    saveProjectPrompt: {
        isActive: false,
        formState: FORM_STATE.DEFAULT,
        projectState: PROJECT_STATE.NEW,
        saving: false,
        forceUpdateSave: false,
        clearLoadedWorkflowOnSave: false,
        moveRequiredAssets: [],
        defaults: {
            name: null,
            description: null,
            mode: null,
            thumbnail: null, 
            variables: [],
            provenance: null,
            projectSettings: {}
        },
        settingsIsActive: false
    }
};

// Make sure we do not pass undefined values to the workflow
const standardiseParameter = (extraParameter, defaultValue, fallbackValue = null) => {
    defaultValue = defaultValue === undefined ? fallbackValue : defaultValue
    return extraParameter !== undefined ? extraParameter : defaultValue
}

const getters = {
    getWorkflow: (state) => {
        return (extraData = {}) => {
            return {
                id: standardiseParameter(extraData.projectId, state.projectId),
                name: standardiseParameter(extraData.projectName, state.projectName),
                description: standardiseParameter(extraData.projectDescription, state.projectDescription),
                mode: standardiseParameter(extraData.projectMode, state.projectMode),
                thumbnail: standardiseParameter(extraData.projectThumbnail, state.projectThumbnail),
                uid: standardiseParameter(extraData.projectOwnerUid, state.projectOwnerUid),
                updatedAt: standardiseParameter(extraData.projectUpdatedAt, state.projectUpdatedAt),
                userAreas: standardiseParameter(extraData.userAreas, state.projectLoadedDefinitionAndArea.userAreas),
                blockDefinition: standardiseParameter(extraData.blockDefinition, state.projectLoadedDefinitionAndArea.blockDefinition),
                api_access: standardiseParameter(extraData.projectApiAccess, state.projectApiAccess),
                api_version: standardiseParameter(extraData.projectApiVersion, state.projectApiVersion), 
                variables: standardiseParameter(extraData.projectVariables, state.projectVariables),
                provenance: standardiseParameter(extraData.projectProvenance, state.projectProvenance),
                projectSettings: standardiseParameter(extraData.projectSettings, state.projectSettings)
            }
        }
    },
    getChangesToWorkflow: (state) => {
        return (extraData = {}) => {
            return {
                id: standardiseParameter(extraData.projectId,state.saveProjectPrompt.defaults.id),
                name: standardiseParameter(extraData.projectName,state.saveProjectPrompt.defaults.name),
                description: standardiseParameter(extraData.projectDescription, state.saveProjectPrompt.defaults.description),
                mode: standardiseParameter(extraData.projectMode, state.saveProjectPrompt.defaults.mode),
                thumbnail: standardiseParameter(extraData.projectThumbnail, state.saveProjectPrompt.defaults.thumbnail),
                uid: standardiseParameter(extraData.projectOwnerUid, state.saveProjectPrompt.defaults.uid),
                updatedAt: standardiseParameter(extraData.projectUpdatedAt, state.saveProjectPrompt.defaults.updatedAt),
                userAreas: standardiseParameter(extraData.userAreas, state.saveProjectPrompt.defaults.userAreas),
                blockDefinition: standardiseParameter(extraData.blockDefinition, state.saveProjectPrompt.defaults.blockDefinition),
                api_access: standardiseParameter(extraData.projectApiAccess, state.saveProjectPrompt.defaults.api_access, false),
                api_version: standardiseParameter(extraData.projectApiVersion, state.saveProjectPrompt.defaults.api_version), 
                variables: standardiseParameter(extraData.projectVariables, state.saveProjectPrompt.defaults.variables, []),
                provenance: standardiseParameter(extraData.projectProvenance, state.saveProjectPrompt.defaults.provenance),
                projectSettings: standardiseParameter(extraData.projectSettings, state.saveProjectPrompt.defaults.projectSettings)
            }
        }
    }
};

const mutations = {
    clearSavedWorkflow(state) {
        state.projectId= null;
        state.projectState= PROJECT_STATE.NEW;
        state.projectMode= PROJECT_MODE.PRIVATE;
        state.projectOwnerUid= null;
        state.projectName= null;
        state.projectDescription= null;
        state.projectLoadedDefinitionAndArea= {};
        state.projectUpdatedAt= null;
        state.projectThumbnail= null;
        state.projectToBeLoaded= false
        state.projectChanged = false
        state.projectApiAccess = false
        state.projectApiVersion = null
        state.projectVariables = []
        state.projectProvenance = null
        state.projectSettings = {}
        state.saveProjectPrompt= {
            isActive: false,
            formState: FORM_STATE.DEFAULT,
            projectState: PROJECT_STATE.NEW,
            saving: false,
            forceUpdateSave: false,
            clearLoadedWorkflowOnSave: false,
            moveRequiredAssets: [],
            defaults: {
                name: null,
                description: null,
                mode: null,
                thumbnail: null,
                variables: [],
                provenance: null,
            },
            settingsIsActive: false
        }
    },
    setState(state, { key, value }) {
        state[key] = value;
    },
    setSaveProjectPromptState(state, { key, value }) {
        state.saveProjectPrompt[key] = value;
    },
    setProjectToBeLoaded(state, loaded) {
        state.projectToBeLoaded = loaded === true;
    },
    setWorkflowAndMode(state, { workflow, projectMode, projectToBeLoaded }) {
        state.projectState = PROJECT_STATE.SAVED
        state.projectId = workflow.id;
        state.projectName = workflow.name;
        state.projectDescription = workflow.description;
        state.projectMode = projectMode || PROJECT_MODE.PRIVATE;
        state.projectOwnerUid = workflow.uid === undefined ? null : workflow.uid;
        state.projectUpdatedAt = workflow.updatedAt;
        state.projectThumbnail = workflow.thumbnail === undefined ? null : workflow.thumbnail;
        state.projectApiAccess = workflow.api_access === true ? true : false;
        state.projectApiVersion = workflow.api_version === undefined ? null : workflow.api_version;
        state.projectVariables = workflow.variables || [];
        state.projectProvenance = workflow.provenance === undefined ? null : workflow.provenance;
        state.projectToBeLoaded = projectToBeLoaded ? projectToBeLoaded === true: false
        state.projectLoadedDefinitionAndArea = {
            userAreas: workflow.userAreas,
            blockDefinition: workflow.blockDefinition
        }
        state.projectSettings = workflow.projectSettings || {}
        state.saveProjectPrompt.defaults = Object.assign({}, workflow)
    },
    setProjectSettings(state, settings) {
        state.projectSettings = settings
    }
};

const actions = {
    // Load project from firestore given a project id
    async loadProjectFromDatastore(context, actionObj) {
        const userId = actionObj.userId || null
        const orgId = actionObj.orgId || null
        const projectId = actionObj.id || null
        if (!userId || !projectId || !orgId) {
            return
        }
        
        // is it a user project, check user projects collection
        let ref = firestoreDoc(usersCollection, userId)
        const userProjectsCollection = collection(ref, 'workflows')//usersCollection.doc(userId).collection('workflows')
        const userProjectDocRef = firestoreDoc(userProjectsCollection, projectId)
        try {
            const userProjectDoc = await getDoc(userProjectDocRef)
            if (userProjectDoc.exists()) {
                // add the id to the workflow data
                const workflow = { ...userProjectDoc.data(), id: userProjectDoc.id }
                context.commit('setWorkflowAndMode', { workflow: workflow, projectMode: userProjectDoc.data().mode || null, projectToBeLoaded: true })
                return
            }
        } catch {
            console.error('User does not have access to this project')
        }
        // is it an organisation project, check organisation projects collection
        ref = firestoreDoc(organisationsCollection, orgId)
        const orgProjectsCollection = collection(ref, 'workflows')//organisationsCollection.doc(orgId).collection('workflows')
        const orgProjectDocRef = firestoreDoc(orgProjectsCollection, projectId)
        try {
            const orgProjectDoc = await getDoc(orgProjectDocRef)
            if (orgProjectDoc.exists()) {
                // add the id to the workflow data
                const workflow = { ...orgProjectDoc.data(), id: orgProjectDoc.id }
                context.commit('setWorkflowAndMode', { workflow: workflow, projectMode: orgProjectDoc.data().mode || null, projectToBeLoaded: true })
                return
            }
        } catch {
            console.error('User does not have access to this project')
        }
        // is it a published org template, check organisation published_workflows collection
        const orgPublishedProjectsCollection = collection(ref, 'published_workflows')//organisationsCollection.doc(orgId).collection('published_workflows')
        const orgPublishedProjectDocRef = firestoreDoc(orgPublishedProjectsCollection, projectId)
        try {
            const orgPublishedProjectDoc = await getDoc(orgPublishedProjectDocRef)
            if (orgPublishedProjectDoc.exists()) {
                // add the id to the workflow data
                const workflow = { ...orgPublishedProjectDoc.data(), id: orgPublishedProjectDoc.id }
                context.commit('setWorkflowAndMode', { workflow: workflow, projectMode: orgPublishedProjectDoc.data().mode || null, projectToBeLoaded: true })
                return
            }
        } catch {
            console.error('User does not have access to this project')
        }
        // is it a shared project, check shared projects collection
        ref = firestoreDoc(sharedCollection, 'default')
        const sharedProjectsCollection = collection(ref, 'workflows');//sharedCollection.doc('default').collection('workflows')
        const sharedProjectDocRef = firestoreDoc(sharedProjectsCollection, projectId)
        try {
            const sharedProjectDoc = await getDoc(sharedProjectDocRef)
            if (sharedProjectDoc.exists()) {
                // add the id to the workflow data
                const workflow = { ...sharedProjectDoc.data(), id: sharedProjectDoc.id }
                context.commit('setWorkflowAndMode', { workflow: workflow, projectMode: sharedProjectDoc.data().mode || null, projectToBeLoaded: true })
                return
            }
        } catch {
            console.error('User does not have access to this project')
        }
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}