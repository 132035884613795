<template>
    <md-list-item :key="cls.value" :id="cls.value" class="draggable ebx-draggable--item">
        <div class="md-list-item-action ebx-draggable--handle">
            <md-icon class="handle">drag_handle</md-icon>
        </div>
        <div class="md-list-item-text md-list-item-text--value">
            {{ cls.value }}
        </div>
        <div class="md-list-item-text md-list-item-text--color">
            <EbxColorPicker 
            v-model="cls.color" 
            :disabled="true" 
            theme="square"></EbxColorPicker>
        </div>
        <div class="md-list-item-text md-list-item-text--name">
            {{ cls.name }}
        </div>
        <div class="md-list-item-action">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                </template>
                <v-list>
                    <v-list-item 
                        v-if="hasCurrentClass"
                        @click="handleMove()">
                        <v-list-item-title class="text-body-2">Remove from {{ currentClass ? currentClass.description : '' }}</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="hasCurrentClass && otherClasses.length > 0"></v-divider>
                    <v-list-subheader
                        v-if="otherClasses.length > 0">Move to</v-list-subheader>
                    <v-list-item 
                        v-for="otherClass in otherClasses" :key="otherClass.uuid" @click="handleMove(otherClass.uuid)">
                        <v-list-item-title class="text-body-2">{{ otherClass.description }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                        class="draggable-new-class-button"
                        @click="createNewClass()">
                        <EBXButton theme="tertiary" @click="createNewClass" icon="add">Create new class</EBXButton>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </md-list-item>
</template>

<script>
import EbxColorPicker from "../EbxComponents/EbxColorPicker.vue";
import EBXButton from "../EbxComponents/EbxButton.vue";
export default {
    components: {
        EbxColorPicker,
        EBXButton
    },
    props: {
        cls:{
            type: Object,
            required: true
        },
        currentClassUuid: {
            type: String,
            default: ''
        },
        allNewClasses: {
            type: Array,
            default() {
                return []
            }
        }
    },
    emits: [
        'move'
    ],
    computed: {
        hasNewClasses() {
            return this.allNewClasses.length > 0
        },
        hasCurrentClass() {
            return this.currentClassUuid != ''
        },
        currentClass() {
            return this.allNewClasses.find(cls => cls.uuid == this.currentClassUuid)
        },
        otherClasses() {
            return this.allNewClasses.filter(cls => cls.uuid != this.currentClassUuid)
        }
    },
    methods: {
        handleMove(moveTo) {
            this.$emit('move', {
                moveTo,
                'current':this.currentClassUuid,
                'cls': this.cls,
            })
        },
        createNewClass() {
            this.$emit('move', {
                moveTo: '__new__',
                'current':this.currentClassUuid,
                'cls': this.cls,
            })
        }
    }
}
</script>