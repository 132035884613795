<template>
    <div>
        <md-table v-if="numericBands.length > 0" class="md-table-ebx border-bottom">
            <md-table-row>
                <md-table-head class="md-table--bands-name-head">Name</md-table-head>
                <md-table-head class="md-table--bands-unit-head">Unit</md-table-head>
                <md-table-head class="md-table--bands-min-head">Min</md-table-head>
                <md-table-head class="md-table--bands-max-head">Max</md-table-head>
                <md-table-head class="md-table--bands-description-head">Description</md-table-head>
                <th class="md-table-head md-table-action">
                    &nbsp;
                </th>
            </md-table-row>
            <md-table-row v-for="band in numericBands" :key="band.id" :class="rowClass(band)">
                <md-table-cell>
                    <EbxInlineInput 
                        v-model="band.name"
                        :disabled="!canEdit"
                        :field-being-edited="fieldBeingEdited"
                        :id="band.id"
                        :input-modifier="`in-table-cell`"
                        :input-style-classes="['ebx-primary-bold']"
                        :input-validation="band.validation.name"
                        input-type="text"
                        edit-actions-position="bottom-right"
                        @editing-ebx-field="updateFieldBeingEdited"
                        @save="updateBand(band, 'name', band.name)">
                    </EbxInlineInput>
                    <div v-if="bandHasErrored(band.id)" class="ebx-error">{{ formState.error }}</div>
                </md-table-cell>
                <md-table-cell>
                    <EbxInlineInput 
                        v-model="band.unit"
                        :disabled="!canEdit"
                        :field-being-edited="fieldBeingEdited"
                        :id="band.id"
                        :input-modifier="`in-table-cell`"
                        :input-style-classes="['ebx-primary-bold']"
                        :input-validation="band.validation.unit"
                        input-type="text"
                        edit-actions-position="bottom-right"
                        @editing-ebx-field="updateFieldBeingEdited"
                        @save="updateBand(band, 'units', band.unit)">
                    </EbxInlineInput>
                </md-table-cell>
                <md-table-cell>
                    <EbxInlineInput 
                        v-model="band.min"
                        :disabled="!canEdit"
                        :field-being-edited="fieldBeingEdited"
                        :id="band.id"
                        :input-modifier="`in-table-cell`"
                        :input-style-classes="['ebx-primary-bold']"
                        :input-validation="band.validation.min"
                        input-type="text"
                        edit-actions-position="bottom-right"
                        @editing-ebx-field="updateFieldBeingEdited"
                        @save="updateBand(band, 'min', band.min)">
                    </EbxInlineInput>
                </md-table-cell>
                <md-table-cell>
                    <EbxInlineInput 
                        v-model="band.max"
                        :disabled="!canEdit"
                        :field-being-edited="fieldBeingEdited"
                        :id="band.id"
                        :input-modifier="`in-table-cell`"
                        :input-style-classes="['ebx-primary-bold']"
                        :input-validation="band.validation.max"
                        input-type="text"
                        edit-actions-position="bottom-right"
                        @editing-ebx-field="updateFieldBeingEdited"
                        @save="updateBand(band, 'max', band.max)">
                    </EbxInlineInput>    
                </md-table-cell>
                <md-table-cell>
                    <EbxInlineInput 
                        v-model="band.description"
                        :disabled="!canEdit"
                        :field-being-edited="fieldBeingEdited"
                        :id="band.id"
                        :input-modifier="`in-table-cell`"
                        :input-style-classes="['ebx-primary-bold']"
                        :input-validation="band.validation.description"
                        input-type="text"
                        edit-actions-position="bottom-right"
                        @editing-ebx-field="updateFieldBeingEdited"
                        @save="updateBand(band, 'description', band.description)">
                    </EbxInlineInput>    
                </md-table-cell>
                <md-table-cell class="md-table-action">
                    <template v-if="bandSaving(band.id)">
                        <md-progress-spinner class="md-accent" md-mode="indeterminate" :md-diameter="20" :md-stroke="2"></md-progress-spinner>
                    </template>
                    <template v-else-if="canEdit">
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn icon="mdi-dots-vertical" aria-label="Actions menu" variant="text" density="compact" v-bind="props"></v-btn>
                            </template>
                            <v-list>
                                <v-list-item 
                                    prepend-icon="mdi-pencil-outline"
                                    @click="handleChangeType(band, 'thematic')">
                                    <v-list-item-title class="text-body-2">Change to thematic Band</v-list-item-title>
                                </v-list-item>
                                <v-list-item 
                                    prepend-icon="mdi-delete-outline"
                                    @click="handleDelete(band)">
                                    <v-list-item-title class="text-body-2">Delete band</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    
                </md-table-cell>
            </md-table-row>
        </md-table>

        <div v-if="thematicBands.length > 0">
            <ThematicBand 
                v-for="band in thematicBands" 
                :key="'thematic_' + band.id" 
                :band="band" 
                :field-being-edited="fieldBeingEdited"
                :form-state="formState" 
                :can-edit="canEdit"
                class="mt-20"
                @editing-ebx-field="updateFieldBeingEdited"
                @update="updateThematicBand" 
                @update-band-name="band.name = $event"
                @save-band-name="updateBand"
                @update-type="handleChangeType"
                @delete-band="handleDelete"
            />
        </div> 

    
        <confirmation-modal ref="modal" :hide-title="true" :ok-button-text="okButtonText" :ok-button-class="okButtonClass" :if-warning="destructiveAction">
            <p class="ebx-primary"
                v-html="confirmationMessage"
            ></p>
        </confirmation-modal>
    </div>
</template>


<script>
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import EbxInlineInput from '@/components/EbxComponents/EbxInlineInput.vue'
import ThematicBand from '@/components/Dataset/ThematicBand.vue'

export default {
    name: 'Bands',
    components: {
        ConfirmationModal,
        EbxInlineInput,
        ThematicBand
    },
    props: {
        dataset: {
            type: Object,
            required: true
        },
        fieldBeingEdited: {
            type: String,
            required: true
        },
        formState: {
            type: Object,
            required: true
        },
        canEdit: {
            type: Boolean,
            required: true
        }
    },
    emits: [
        'update-type',
        'update',
        'editing-ebx-field'
    ],
    data() {
        return {
            confirmationMessage: '',
            okButtonText: 'Delete',
            okButtonClass: 'md-raised md-danger',
            destructiveAction: true
        }
    },
    computed: {
        hasChanges() {
            return false
        },
        numericBands() {
            return this.bands.filter(band => band.type === 'numeric')
        },
        thematicBands() {
            return this.bands.filter(band => band.type === 'thematic')
        },
        bands() {
            return this.dataset.bands
                .filter(band => band['ebx:display'] === true)
                .map(band => {
                    const vis = this.dataset['ebx:visualize_v2'][band.name] || {min:0, max:255}
                    const formError = (name) => {
                        if (
                            this.formState.data && 
                            this.formState.data.bandName === name && 
                            this.formState.state === 'errored' && 
                            this.formState.data.formData && 
                            Object.keys(this.formState.data.formData).includes(name)
                        ) {
                            return {
                                error: this.formState.error,
                                active: true
                            }
                        }
                        return {
                                error: '',
                                active: false
                            }
                    }
                    const handleClasses = classes => {
                        if (classes === undefined || classes === null) {
                            return []
                        }
                        if (typeof(classes) === 'object') {
                            return Object.keys(classes).map(c => {
                                return {
                                    id: c,
                                    value: parseFloat(c),
                                    color: classes[c].color,
                                    description: classes[c].description
                                }
                            })
                        }
                        return []
                    }
                    return {
                        id: band.name,
                        name: band['ebx:name'],
                        description: band.description,
                        min: vis.min !== null ? '' + vis.min : null,
                        max: vis.max !== null ? '' + vis.max : null,
                        unit: band['ebx:units'] || 'digital_number',
                        type: band['ebx:datatype'],
                        display: band['ebx:display'] === true,
                        classes: handleClasses(band['gee:classes']),
                        validation: {
                            name: formError('name'),
                            unit: formError('units'),
                            min: formError('min'),
                            max: formError('max'),
                            description: formError('description')
                        },
                    }
                })
        }
    },
    methods: {
        async handleChangeType(band, type) {
            if (this.canEdit === false) {
                return
            }
            if (type === 'thematic') { 
                this.destructiveAction = false
            } else if (type === 'numeric') {
                this.destructiveAction = true
            }
            this.confirmationMessage = `Are you sure you want to change the band <span style="font-weight: bold">${band.name}</span> to a ${type} band?`
            this.okButtonText ='Change';
            this.okButtonClass ='md-raised md-success';

            const confirmed = await this.$refs.modal.confirm();
            if(confirmed) {
                this.$emit('update-type', band.id, type)
            }
        },
        async handleDelete(band) {
            if (this.canEdit === false) {
                return
            }
            this.destructiveAction = true
            this.confirmationMessage = `Are you sure you want to delete the band <span style="font-weight: bold">${band.name}</span>?`
            this.okButtonText ='Delete';
            this.okButtonClass ='md-raised md-danger';

            const confirmed = await this.$refs.modal.confirm()
            if (confirmed) {
                this.$emit('update', band.id, {display: false})
            }
        },
        bandSaving(bandId) {
            return this.formState.state === 'saving' && this.formState.data.bandName === bandId
        },
        bandHasErrored(bandId) {
            return this.formState.state === 'errored' && this.formState.data.bandName === bandId
        },
        rowClass(band) {
            return {
                errored: this.bandHasErrored(band.id)
            }
        },
        updateBand(band, field, value) {
            if (this.canEdit === false) {
                return
            }
            this.$emit('update', band.id, {[field]: value})
        },
        updateThematicBand(bandId, classes) {
            if (this.canEdit === false) {
                return
            }
            this.$emit('update', bandId, {classes})
        },
        updateFieldBeingEdited(field) {
            this.$emit('editing-ebx-field', field);
        },
    }
}
</script>