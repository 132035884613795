import {SAVED_WORKFLOW_ID_SEPERATOR} from "../../constants/nextGenConstants"

class AbstractAbility {
    constructor(state) {
        this.workflowState = state
        this.stateKey = null
        this.stateIndex = -1
        this.definitionIndexes = ['default']
        this.currentDefinitionKey = 'default'
        this.previousCurrentDefinition = 'default'
        this.associatedBlockId = state.registry.getCurrentBlockId()
    }

    commit(data) {
        this.definitionIndexes.forEach(defIndex => {
            if(this.stateIndex < 0) {
                if(this.stateKey === null) {
                    throw new Error('state key must be set on ability')
                }
                if(this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex] === undefined) {
                    this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex] = {
                        user_defined_name: null
                    }
                }
                if(this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey] === undefined) {
                    this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey] = []
                }
                this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey].push(data)
                this.stateIndex = this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey].length - 1
            } else {
                if(this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey] === undefined) {
                    this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey] = []
                }
                this.workflowState.state[this.workflowState.getDefinitionKey()][defIndex][this.stateKey][this.stateIndex] = data
            }
        })
        
        return this
    }

    setIndex(index) {
        this.stateIndex = index
        return this
    }
    hasDefinition(defintionKey) {
        return this.definitionIndexes.indexOf(defintionKey) >= 0
    }

    setCurrentDefinition(currentDefintionKey = 'default') {
        if(this.hasDefinition(currentDefintionKey) === false) {
            throw new Error(`${currentDefintionKey} definition does not exist on the state`)
        }
        this.previousCurrentDefinition = this.currentDefinitionKey;
        this.currentDefinitionKey = currentDefintionKey
        return this
    }

    resetCurrentDefinition() {
        this.currentDefinitionKey = this.previousCurrentDefinition
        return this
    }

    setDefinitionIndexes(indexes) {
        this.definitionIndexes = indexes
        return this
    }
    appendDefinitionIndex(index) {
        this.definitionIndexes.push(index)
        return this
    }

    getCurrentDefinitionKey() {
        return this.currentDefinitionKey
    }
    
    getIndex() {
        return this.stateIndex;
    }

    getDefinitionState() {
        if(this.workflowState.state[this.workflowState.getDefinitionKey()] === undefined) {
            return undefined
        }
        if(this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey] === undefined) {
            return undefined
        }
        return this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey]
    }
    getState() {
        if(this.workflowState.state[this.workflowState.getDefinitionKey()] === undefined) {
            return undefined
        }
        if(this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey] === undefined) {
            return undefined
        }
        if(this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey][this.stateKey] === undefined) {
            return undefined
        }
        return this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey][this.stateKey][this.stateIndex];
    }

    isSaved() {
        if(this.workflowState.state[this.workflowState.getDefinitionKey()] === undefined) {
            return false
        }
        if(this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey] === undefined) {
            return false
        }
        return [null, undefined].indexOf( this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey].user_defined_name) === -1
    }

    getUserDefinedName() {
        if(this.workflowState.state[this.workflowState.getDefinitionKey()] === undefined) {
            return false
        }
        if(this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey] === undefined) {
            return false
        }
        return this.workflowState.state[this.workflowState.getDefinitionKey()][this.currentDefinitionKey].user_defined_name
    }

    updateState(data) {
        const newState = Object.assign(this.getState(this.currentDefinitionKey) || {}, data)
        return this.commit(newState)
    }

    getDefinitionKeys() {
        return this.definitionIndexes 
    }
    getStateDefinitionKeys() {
        return Object.keys(this.workflowState.state[this.workflowState.getDefinitionKey()] )
    }

    getDefinitions() {
        return this
            .getDefinitionKeys()
            .map(k => {
                const newAbility = (new this.constructor(this.workflowState))
                Object.keys(this).forEach(k => {
                    if(k !== 'definitionIndexes') {
                        newAbility[k] = this[k]
                    }
                })
                return newAbility.appendDefinitionIndex(k).setCurrentDefinition(k)
            })
    }

    getStateDefinitions() {
        return this
            .getStateDefinitionKeys()
            .map(k => {
                const newAbility = (new this.constructor(this.workflowState))
                Object.keys(this).forEach(k => {
                    if(k !== 'definitionIndexes') {
                        newAbility[k] = this[k]
                    }
                })
                return newAbility.appendDefinitionIndex(k).setCurrentDefinition(k)
            })
    }
    
    getWorkflowStateId() {
        return this.workflowState.id
    }
    getWorkflowStateIdWithDefinition() {
        return this.workflowState.id + SAVED_WORKFLOW_ID_SEPERATOR + this.currentDefinitionKey
    }

    createdBeforeCurrentBlockId(includeCurrentBlock = false) {
        return (
            this.workflowState.registry.hasProcessedBlockId(this.associatedBlockId) && 
            (includeCurrentBlock === true || this.associatedBlockId !== this.workflowState.registry.getCurrentBlockId())
        )
    }

    newCollection(abilites) {
        return abilites
    }

    updateAssociatedBlockId(id) {
        this.associatedBlockId = id
        return this
    }

}

export {AbstractAbility}